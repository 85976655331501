import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import { accountTypes } from '../../config/constants';
import Constants from './constants';

const AssignQuestionnaireWeightage = () => {
    const [initialState, setInitialState] = useState({});
    const [type, setType] = useState('');
    const { eventId, responseID } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [isOpportunityLive, setIsOpportunityLive] = useState(false);

    const fetchProject = async (id) => {
        const response = await projectManagementAPIs.getProject(id);
        if (response.status === 200) {
            let publishEvent = {};
            response?.data?.workflow?.stages?.forEach((stage) => {
                stage?.events?.forEach((evnt) => {
                    if (evnt.eventID === eventId) {
                        publishEvent = {
                            ...evnt,
                        };
                    }
                    if (publishEvent.submissionStartDate && publishEvent.submissionEndDate
                        && (helperFunctions.isBetween(new Date(),
                            parseISO(publishEvent.submissionStartDate),
                            parseISO(publishEvent.submissionEndDate)))) {
                        setIsOpportunityLive(true);
                    }
                });
            });
        } else {
            Toast.fire({
                icon: Constants.error,
                titleText: Constants.loadError,
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authContext.user.accountType !== accountTypes.supplier) {
                const response = await projectManagementAPIs.getResponse(responseID);
                if (response.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState(
                            Constants.questionnaire, response.data, responseID,
                        );
                    setInitialState(preparedData);
                    setType(preparedData.details.type);
                    fetchProject(response.data.projectID);
                } else {
                    Toast.fire({
                        icon: Constants.error,
                        titleText: Constants.retrieveInfoError,
                    });
                }
            } else {
                Toast.fire({
                    icon: Constants.error,
                    titleText: Constants.permissionError,
                });
                history.push(Constants.dashboard);
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (responseID) {
            fetchData();
        }
    }, [responseID]);

    const getSumOfSectionWeightages = (data) => (data.sections ? data.sections
        .filter((section) => section.weightage)
        .reduce((sum, elem) => sum + parseInt(elem.weightage, 10), 0) : 0);

    const handleSubmit = async (data, confirmAction) => {
        const sectionsInDocument = [];
        data.sections?.forEach((section) => {
            const questionsInSection = [];
            section.sectionItems?.forEach((item) => {
                const questionToAdd = {
                    ...item,
                    weightage: parseInt(item.weightage, 10) || undefined,
                };
                if (item.attachments) {
                    questionToAdd.attachments = item.attachments;
                }
                if (item.scoring === Constants.pseudoPass) {
                    questionToAdd.pseudoPassReason = item.pseudoPassReason;
                }
                questionsInSection.push(questionToAdd);
            });

            const sectionDataToAdd = {
                id: section.id,
                title: section.title,
                description: section.description,
                questions: questionsInSection,
                weightage: parseInt(section.weightage, 10) || undefined,
            };

            sectionsInDocument.push(sectionDataToAdd);
        });

        const payload = {
            ...data.details,
            responseType: data.details.templateType,
            weightage: getSumOfSectionWeightages(data),
            showWeightings: !!data.details.showWeightings,
            sectionsWeighted: !!data.details.sectionsWeighted,
            sections: sectionsInDocument,
            versionReason: (confirmAction !== Constants.overwrite && confirmAction !== Constants.create && typeof confirmAction === 'string') ? confirmAction : undefined,
        };

        const response = await projectManagementAPIs.editAssignedQuestionnaire(responseID, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: Constants.success,
                titleText: Constants.weightingSuccess,
            });
            history.goBack();
        } else {
            Toast.fire({
                icon: Constants.error,
                titleText: Constants.weightingFail,
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'weightage'}
        context='Questionnaire'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
        handlePrimaryToolbarSubmitButton={handleSubmit}
        isALiveQuestionnaire={isOpportunityLive}
    /> : <></>;
};

export default AssignQuestionnaireWeightage;
