export const accountTypes = {
    supplier: 'supplier',
    nepo: 'nepo',
    localAuthority: 'la',
};

export const projectTypes = {
    prf: 'PRF',
    project: 'project',
    callOff: 'calloff',
};

export const projectStatus = {
    cancelled: 'cancelled',
    awarded: 'awarded',
    active: 'active',
};

export const projectStatusStyle = {
    cancelled: { label: 'Cancelled', pillStyle: 'cancelled' },
    awarded: { label: 'Awarded', pillStyle: 'awarded' },
    active: { label: 'Active', pillStyle: 'active' },
};

export const procurementReqStatues = {
    awaitingApproval: 'awaitingApproval',
    approved: 'approved',
    rejected: 'rejected',
    cancelled: 'cancelled',
};

export const userStatus = {
    awaitingApproval: 'awaitingApproval',
    approved: 'approved',
    rejected: 'rejected',
    cancelled: 'cancelled',
};

export const userRoles = {
    admin: 'admin',
    advanced: 'advanced',
    intermediate: 'intermediate',
    basic: 'basic',
};

export const stageCategories = {
    preProcurement: 'pre-procurement',
    eTendering: 'e-tendering',
    contractManagement: 'contract-management',
};

export const multipleDownloadsFilename = 'documents';

export const workflowEvents = {
    calendar: 'calendar',
    document: 'document',
    publish: 'publish',
    notice: 'notice',
    evaluation: 'evaluation',
    issueAwardUnsuccessfulNotices: 'issueAwardUnsuccessfulNotices',
    supplierDocumentation: 'supplierDocumentation',
    marketUnderstanding: 'marketUnderstanding',
    demandManagement: 'demandManagement',
    costManagement: 'costManagement',
    performanceMonitoringSupplierRelations: 'performanceMonitoringSupplierRelations',
    riskManagement: 'riskManagement',
    updateContractManagementPlan: 'updateContractManagementPlan',
    exitStrategy: 'exitStrategy',
    extension: 'extension',
    variation: 'variation',
    renewContract: 'renewContract',
};

export const workflowRestrictionConstants = {
    publishwithMarket: 'publish-marketConsultation',
    routeToMarket_directAward: 'directAward',
    establishedSolution: 'establishedSolution',
};

export const eventStatuses = {
    pending: 'pending',
    started: 'started',
    awaitingApproval: 'awaitingApproval',
    stageFirstApproved: 'stageFirstApproved',
    stageSecondApproved: 'stageSecondApproved',
    completed: 'completed',
    awaitingPublish: 'awaitingPublish',
    published: 'published',
};

export const eventActions = {
    started: 'started',
    awaitingApproval: 'awaitingApproval',
    approveStage1: 'approveStage1',
    approveStage2: 'approveStage2',
    rejectStage1: 'rejectStage1',
    rejectStage2: 'rejectStage2',
    complete: 'complete',
    reopen: 'reopen',
    escalate: 'escalate',
    publish: 'publish',
    unpublish: 'unpublish',
};

export const registerInterestStatuses = {
    approved: 'approved',
    withdraw: 'withdraw',
    pending: 'pending',
    rejected: 'rejected',
};

export const submissionStatues = {
    drafted: 'drafted',
    submitted: 'submitted',
};

export const evaluationStatuses = {
    sealed: 'sealed',
    released: 'released',
    notStarted: 'notStarted',
    underEvaluation: 'draft',
    evaluationComplete: 'evalComplete',
    awaitingApproval: 'awaitingApproval',
    stage1Approved: 's1a',
    stage2Approved: 's2a',
    approved: 'approved',
    rejected: 'rejected',
    disqualified: 'disqualified',
    legalNotApplicable: 'notapplicable',
};

export const evaluationStatusesLabels = {
    sealed: 'Sealed',
    released: 'Released',
    notStarted: 'Released',
    underEvaluation: 'Under Evaluation',
    evaluationComplete: 'Evaluation Complete',
    awaitingApproval: 'Stage 1 Approval Pending',
    stage1Approved: 'Stage 2 Approval Pending',
    approved: 'Approved',
    legalNotApplicable: 'Not applicable',
    disqualified: 'Disqualified',
};

export const approvalStatuses = {
    stage1ApprovalPending: 's1a',
    stage2ApprovalPending: 's2a',
    approved: 'approved',
    rejected: 'rejected',
    notStarted: 'notStarted',
};

export const awardStatuses = {
    pending: 'pending',
    successful: 'successful',
    unsuccessful: 'unsuccessful',
};

export const intentStatuses = {
    pending: 'pending',
    successful: 'successful',
    unsuccessful: 'unsuccessful',
};

export const intentLetterStatuses = {
    notStarted: 'notStarted',
    stage1ApprovalPending: 's1a',
    stage2ApprovalPending: 's2a',
    approved: 'approved',
};

export const noticeTypes = {
    CF: {
        preprocurement: 'Preprocurement (Early Engagement)',
        liveOpportunity: 'Live Opportunity (Contract)',
        award: 'Award (Contract)',
        futureOpportunity: 'Future Opportunity (Pipeline)',
    },
    FTS: {
        preprocurement: 'Prior information Notice (F01)',
        liveOpportunity: 'Contract Live Opportunity (F02)',
        award: 'Contract Award (F03)',
    },
};

export const workflowEventTypes = [{
    label: 'Supplier Documentation',
    value: 'supplierDocumentation',
    id: 'eventTypeSupplierDocumentation',
}, {
    label: 'Market Understanding',
    value: 'marketUnderstanding',
    id: 'eventTypeMarketUnderstanding',
}, {
    label: 'Demand Management',
    value: 'demandManagement',
    id: 'eventTypeDemandManagement',
}, {
    label: 'Cost Management',
    value: 'costManagement',
    id: 'eventTypeCostManagement',
}, {
    label: 'Performance Monitoring & Supplier Relations',
    value: 'performanceMonitoringSupplierRelations',
    id: 'eventTypePerformanceMonitoringSupplierRelations',
}, {
    label: 'Risk Management',
    value: 'riskManagement',
    id: 'eventTypeRiskManagement',
}, {
    label: 'Update Contract Management Plan',
    value: 'updateContractManagementPlan',
    id: 'eventTypeUpdateContractManagementPlan',
}, {
    label: 'Exit Strategy',
    value: 'exitStrategy',
    id: 'eventTypeExitStrategy',
}, {
    label: 'Extension Event',
    value: 'extension',
    id: 'eventTypeExtension',
}, {
    label: 'Variation Event',
    value: 'variation',
    id: 'eventTypeVariation',
}, {
    label: 'Renew Contract Event',
    value: 'renewContract',
    id: 'eventTypeRenewContract',
}, {
    label: 'Calendar Event',
    value: 'calendar',
    id: 'eventTypeCalendar',
}, {
    label: 'Document Event',
    value: 'document',
    id: 'eventTypeDocument',
}, {
    label: 'Publish Event',
    value: 'publish',
    id: 'eventTypePublish',
},
// {
//     label: 'Notice Event',
//     value: 'notice',
//     id: 'eventTypeNotice',
//  },
{
    label: 'Evaluation Event',
    value: 'evaluation',
    id: 'eventTypeEvaluation',
}, {
    label: 'Issue Award/Unsuccessful Notices Event',
    value: 'issueAwardUnsuccessfulNotices',
    id: 'eventTypeIssueAwardUnsuccessfulNotices',
}];

export const specialAccountPrivileges = [
    // {
    //     name: 'Organisation Directors',
    //     id: 'organisation_directors',
    // },
    {
        name: 'Organisation Rebate Officers',
        value: 'rebate',
        id: 'rebate',
    },
    // {
    //     name: 'Organisation Management Approvers',
    //     id: 'organisation_management_approvers',
    // },
    // {
    //     name: 'Organisation Communications Team',
    //     id: 'organisation_communications_team',
    // },
    {
        name: 'Organisation Procurement Reviewers',
        value: 'reviewer',
        id: 'reviewer',
    },
];

export const routeToMarket = [{
    name: 'Not Selected',
    id: '',
}, {
    name: 'Open Procedure',
    id: 'openProcedure',
}, {
    name: 'Restricted Procedure',
    id: 'restrictedProcedure',
}, {
    name: 'Competitive Procedure with Negotiation',
    id: 'CPN',
}, {
    name: 'Competitive Procedure with Dialogue',
    id: 'CPD',
}, {
    name: 'Innovation Partnership',
    id: 'innovationPartnership',
}, {
    name: 'Request for Quotation',
    id: 'requestQuotation',
}, {
    name: 'Request for Quotation - Clever Quotes',
    id: 'requestQuotationCleverQuotes',
}, {
    name: 'Under Threshold',
    id: 'underThreshold',
}, {
    name: 'Direct Award',
    id: 'directAward',
}, {
    name: 'Established Solution',
    id: 'establishedSolution',
}, {
    name: 'Manual Creation',
    id: 'manualCreation',
}];

export const routeToMarketToProd = [{
    name: 'Not Selected',
    id: '',
}, {
    name: 'Open Procedure',
    id: 'openProcedure',
}, {
    name: 'Restricted Procedure',
    id: 'restrictedProcedure',
}, {
    name: 'Competitive Procedure with Negotiation',
    id: 'CPN',
}, {
    name: 'Competitive Procedure with Dialogue',
    id: 'CPD',
}, {
    name: 'Innovation Partnership',
    id: 'innovationPartnership',
}, {
    name: 'Request for Quotation',
    id: 'requestQuotation',
}, {
    name: 'Request for Quotation - Clever Quotes',
    id: 'requestQuotationCleverQuotes',
}, {
    name: 'Under Threshold',
    id: 'underThreshold',
}, {
    name: 'Direct Award',
    id: 'directAward',
}, {
    name: 'Established Solution',
    id: 'establishedSolution',
}];

export const questionnaireTypes = [{
    label: 'Selection Questionnaire',
    value: 'selection',
}, {
    label: 'Selection Questionnaire',
    value: 'defaultSelectionQuestionnaire',
}, {
    label: 'Tender Questionnaire',
    value: 'tender',
}, {
    label: 'Award Questionnaire',
    value: 'award',
}, {
    label: 'Market Consultation Questionnaire',
    value: 'marketConsultation',
}, {
    label: 'Additional Questionnaire',
    value: 'additional',
}];

export const allSteeringRoles = [
    {
        id: 'procurementOfficer', name: 'Procurement Officer(s)', optional: false, role: 'po',
    },
    {
        id: 'procurementManager', name: 'Procurement Manager(s)', optional: true, role: 'pm',
    },
    {
        id: 'contractManager', name: 'Contract Manager(s)', optional: true, role: 'cm',
    },
    {
        id: 'categorySpecialist', name: 'Category Specialist(s)', optional: true, role: 'cs',
    },
    {
        id: 'stage1Approver', name: 'Stage 1 Approver(s)', optional: true, role: 's1a',
    },
    {
        id: 'stage2Approver', name: 'Stage 2 Approver(s)', optional: true, role: 's2a',
    },
    {
        id: 'evaluatorManager', name: 'Evaluator(s)', optional: true, role: 'eval',
    },
    /*
   TODO: Enable for Individual Evaluations
   {
        id: 'evaluatorManager', name: 'Evaluator Manager(s)', optional: true, role: 'eval',
    }
   {
        id: 'individualEvaluator', name: 'Evaluator(s)', optional: true, role: 'individual_eval',
    }, */
    {
        id: 'legalRepresentative', name: 'Legal Representatives(s)', optional: true, role: 'legal',
    },
    {
        id: 'collaborativeUser', name: 'CollaborativeUser(s)', optional: true, role: 'collaborative',
    },
    // {
    //     id: 'supplierUser', name: 'Supplier User(s)', optional: true, role: 'supplier',
    // },
];

export const contractSteeringRoles = [
    {
        id: 'procurementOfficer', name: 'Procurement Officer(s)', optional: false, role: 'po',
    },
    {
        id: 'procurementManager', name: 'Procurement Manager(s)', optional: true, role: 'pm',
    },
    {
        id: 'contractManager', name: 'Contract Manager(s)', optional: true, role: 'cm',
    },
    {
        id: 'categorySpecialist', name: 'Category Specialist(s)', optional: true, role: 'cs',
    },
];

export const eventTypes = {
    eventTypeCurrent: 'Current',
    eventTypeUpcoming: 'Upcoming',
    eventTypePast: 'Past',
};

export const responseStatusColour = {
    red: 'red',
    green: 'green',
    amber: 'amber',
};

export const responseStatus = {
    notStarted: 'notStarted',
    inProgress: 'inProgress',
    submitted: 'submitted',
    bidSubmitted: 'bidSubmitted',
    bidWithdrawn: 'bidWithdrawn',
    updated: 'Updated',
    cancelled: 'cancelled',
    complete: 'complete',
    approved: 'approved',
};

export const responseStatuses = [{
    value: responseStatus.notStarted,
    label: 'Not Started',
    colour: responseStatusColour.red,
}, {
    value: responseStatus.inProgress,
    label: 'In Progress',
    colour: responseStatusColour.red,
}, {
    value: responseStatus.complete,
    label: 'Complete',
    colour: responseStatusColour.green,
}, {
    value: responseStatus.submitted,
    label: 'Submitted',
}, {
    value: responseStatus.bidSubmitted,
    label: 'Bid Submitted',
}, {
    value: responseStatus.bidWithdrawn,
    label: 'Bid Withdrawn',
}];

export const awardStatusColour = {
    red: 'red',
    green: 'green',
    amber: 'amber',
};

export const awardStatus = [{
    value: 'successful',
    label: 'Successful',
    colour: awardStatusColour.green,
}, {
    value: 'unsuccessful',
    label: 'Unsuccessful',
    colour: awardStatusColour.red,
}, {
    value: 'pending',
    label: 'Pending',
    colour: awardStatusColour.amber,
}];


export const evaluationStatus = {
    sealed: 'sealed',
    released: 'released',
    notStarted: 'notStarted',
    underEvaluation: 'draft',
    evaluationComplete: 'evalComplete',
    awaitingApproval: 'awaitingApproval',
    stage1Approved: 's1a',
    approved: 'approved',
    legalNotApplicable: 'legalNotApplicable',
};

export const evaluationStatusesColor = {
    red: 'red',
    green: 'green',
    amber: 'amber',
};


export const maxUploadSize = 100000000; // 100MB

export const rigour = [{
    value: 'gold',
    label: 'Gold',
}, {
    value: 'silver',
    label: 'Silver',
}, {
    value: 'platinum',
    label: 'Platinum',
}];

export const callOffMechanisms = [
    {
        value: '',
        label: 'Not Selected',
    },
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: 'directAward',
        label: 'Direct Award',
    }, {
        value: 'furtherComp',
        label: 'Further Competition',
    }];

export const callOffMechanismValues = {
    any: 'any',
    directAward: 'directAward',
    furtherComp: 'furtherComp',
};

export const projectDescribes = [{
    value: 'dps',
    label: 'DPS',
}, {
    value: 'framework',
    label: 'Framework',
}, {
    value: 'contract',
    label: 'Contract',
}];


export const eventStatusesLabel = [{
    value: 'pending',
    label: 'Not started',
}, {
    value: 'started',
    label: 'Started',
}, {
    value: 'awaitingApproval',
    label: 'Awaiting Stage 1 Approval',
}, {
    value: 'stageFirstApproved',
    label: 'Stage 1 Approved',
}, {
    value: 'stageSecondApproved',
    label: 'Stage 1 & 2 Approved',
}, {
    value: 'completed',
    label: 'Completed',
}, {
    value: 'awaitingPublish',
    label: 'Awaiting Publish',
}, {
    value: 'published',
    label: 'Published',
}, {
    value: 'closed',
    label: 'Opportunity closed',
}];

export const companyTypes = [{
    name: 'Limited Liability Partnership',
    companySearch: true,
}, {
    name: 'Partnership',
    companySearch: true,
}, {
    name: 'Private Limited Company',
    companySearch: true,
}, {
    name: 'Public Limited Company',
    companySearch: true,
}, {
    name: 'Sole Trader',
    companySearch: false,
}, {
    name: 'Charity',
    companySearch: true,
}, {
    name: 'Community Interest Company',
    companySearch: true,
}, {
    name: 'Co-operative',
    companySearch: true,
}, {
    name: 'Other',
    companySearch: true,
}];

export const CorrespondenceVisibility = {
    allRegisteredSuppliers: 'allRegisteredSuppliers',
    specificSuppliers: 'specificSuppliers',
    publicCorrespondence: 'publicCorrespondence',
};

export const CorrespondenceVisibilityLabel = {
    title: 'Who can see this correspondence?',
    allRegisteredSuppliers: 'All Suppliers in Current Stage',
    specificSuppliers: 'Specific Supplier(s)',
    publicCorrespondence: 'Public',
};

export const CorrespondenceTypeName = 'correspondenceType';

export const resourceTypes = {
    contract: 'contract',
    project: 'project',
};

export const QUESTIONNAIRE = 'questionnaire';
export const ERROR_RETRIEVING_DIFF_INFO = 'Unable to retrieve diff versions information.';
export const ERROR_RETRIEVING_INFO = 'Unable to retrieve information.';
export const noDocumentsToDownload = 'There are no documents to download.';

export const view = 'View';
export const edit = 'Edit';
export const dpsLabels = {
    projectLabel: 'Project',
    lotLabel: 'Lot',
};
export const publishAtLevel = {
    project: 'project',
    lot: 'lot',
};
export const submission = 'Submission';
export const previousSubmissions = 'Previous Submissions';
export const intentStatusColor = {
    red: 'red',
    green: 'green',
};
export const shared = 'shared';
export const viewQuestionnaireUrl = '/questionnaires/answer/view/';
export const editQuestionnaireUrl = '/questionnaires/answer/';
export const opportunityAwardSummaryUrl = '/opportunity/award/summary';
export const viewResason = 'View Reason';
export const viewOutcome = 'View Outcome';

export const ID = 'id';
export const COMPANY_NAME = 'companyName';
export const DISQUALIFY_REASON = 'disqualifyReason';
export const RESPONSE_ID = 'responseID';
export const ACCOUNT_ID = 'accountID';
export const LOT_ID = 'lotID';

export const SCORE = 'score';
export const SCORE_TITLE = 'Score';
export const TOTAL_SCORE = 'totalScore';
export const TOTAL_SCORE_TITLE = 'Total Score';
export const PASS_FAIL_RESULT = 'passFailResult';
export const TOTAL_PASS_FAIL_RESULT = 'totalPassFailResult';
export const PASS_FAIL_RESULT_TITLE = 'Pass/Fail Result';
export const SMALL = 'small';
export const IS_SUPPLIER_DISQUALIFIED = 'isSupplierDisqualified';
export const CURRENT = 'current';

export const HANDLE_DISQUALIFY = 'handleDisqualify';
export const HANDLE_EVALUATE = 'handleEvaluate';
export const HANDLE_STATUS_UPDATE = 'handleStatusUpdate';
export const HANDLE_SHOW_REASON_MODAL = 'handleShowReasonModal';

export const QUESTIONNAIRE_VIEW_EVALUATE_URL = '/questionnaires/evaluate/view/';
export const QUESTIONNAIRE_EVALUATE_URL = '/questionnaires/evaluate/';
export const FAIL = 'fail';
export const FAIL_TITLE = 'Fail';
export const PASS_TITLE = 'Pass';

export const HANDLE_UPDATE_INTENT_STATUS = 'handleUpdateIntentStatus';

export const sendAwardLetterModalMessage = {
    draftContract: 'In preparation for the contract award, you will be directed to create a contract record for this project. Please note, if this is an ongoing procurement the contract won’t be visible until the final award.',
    pleaseClick: 'Please click the link below to draft a contract.',
    areYouSure: 'Are you sure you want to send the award letters and evaluation scores (only if applicable) to all suppliers?',
    publicRecord: 'A public record will be created.',
    reviewAwardLetter: 'Please review the contract before sending the award letters.',
};

export const publishRound = {
    first: 'first',
    notapplicable: 'notapplicable',
};
export const passFailLabels = {
    pass: 'Pass',
    fail: 'Fail',
    scored: 'Scored',
    unscored: 'Unscored',
    provisional: 'Provisional Pass',
    passOrFail: 'Pass / Fail',
};
export const passFailValues = {
    pass: 'pass',
    fail: 'fail',
    passFailPseudo: 'pass/Fail/Pseudo-Pass',
    pseudo: 'Pseudo Pass',
    scored: 'scored',
    unscored: 'unscored',
};
export const attatchedToTypes = {
    RESPONSE: 'resp',
    PROJECT: 'proj',
    AWARD: 'award',
    INTENT: 'intent',
    CONTRACT: 'cont',
};
export const documentTypes = {
    AWARD: 'award',
    INTENT: 'intent',
};
export const awardSummaryLabels = {
    INTENT_LETTER_DOWNLOAD: 'Download Intent Letter',
    AWARD_LETTER_DOWNLOAD: 'Download Award Letter',
};

export const selection = 'selection';
export const SUBMITTED_AT = 'Submitted at';

export const activityLogRoute = 'activity';

export const RICH_TEXT = {
    QUESTIONNAIRE_DESCRIPTION: 'questionnaire-description',
};
export const evaluationPanel = 'Evaluation Panel';
export const evaluationPanelTypes = {
    individual: {
        label: 'Individual',
        value: 'individual',
        id: 'evaluationPanelIndividual',
    },
    consensus: {
        label: 'Group with Consensus',
        value: 'consensus',
        id: 'evaluationPanelConsensus',
    },
};
export const individualEvaluatorRole = 'individual_eval';
export const VIEW_ANSWER_MODE = 'viewanswer';
export const LOAD_ERROR = 'Unable to load project.';
export const LABEL_BUTTON_ADD = 'Add';
export const MAX_CHAR_COUNT_1000 = 1000;
export const MAX_CHAR_COUNT_5000 = 5000;
export const MAX_CHAR_COUNT_30000 = 30000;
export const RESPONSE_STATUS_TYPE_AUTOSAVE = 'autosave';

export const PROJECT_DESCRIBES_DPS = 'dps';

export const ToastErrorMessages = {
    UNABLE_TO_DOWNLOAD_DOC: 'Unable to download document.',
    UNABLE_TO_RETRIEVE_INFO: 'Unable to retrieve information.',
    SUCCESSFUL_WITHDRAW_SUPPLIER: 'Successfully withdraw supplier.',
    SUPPLIER_ALREADY_REGISTERED: 'Supplier already registered.',
    FAILED_TO_WITHDRAW_SUPPLIER: 'Failed to withdraw supplier.',
    SESSION_EXPIRED: 'You have been logged out as your session has expired.',
};

export const exportCsvText = 'export.csv';
export const responseHeaders = {
    CONTENT_DISPOSITION: 'content-disposition',
    CONTENT_TYPE: 'content-type',
};

export const dashboard = {
    CARD_PROPERTIES: {
        estimatedValue: 'Estimated value',
        stage: 'Current Stage',
        originalSolution: 'Solution',
    },
};

export const filterAttributes = {
    name: 'name',
    description: 'description',
    title: 'title',
    subject: 'subject',
    keywords: 'keywords',
    message: 'message',
};

export const fileDownloadLabels = {
    btn_success: 'Download',
    btn_pending: 'Pending',
    btn_failed: 'Unavailable',
    btn_delete: 'Delete',
    btn_delete_failed: 'Ok',
    description_success: '',
    description_pending: 'Pending antivirus checks. Please wait',
    description_failed: 'Antivirus scan failed. A potential threat was detected in the file',
};


export const modalSizes = {
    modal_sm: 'small',
    modal_smw: 'small-long',
    modal_temp: 'temp-size', // WHY
    modal_md: 'medium',
    modal_lg: 'large',
    modal_lgw: 'large-long',
    modal_xl: 'x-large',
    modal_xxl: 'xxl',
};

export const RETURN_TO_URL = 'returnTo';
export const LOGIN_PATH = '/login';
export const PUBLIC_URL_PATH = '/public';
export const QUERY_URL_PARAM = 'queryUrl';
export const DATA_THEME = 'data-theme';

export const DATA_THEME_STYLES = {
    defaultStyle: 'default-style',
    newStyle: 'new-style',
};
export const LOGIN = 'Login';

export const breadcrumbPaths = {
    HOME: { name: 'Home', path: '/dashboard' },
    PROJECTS: { name: 'Browse Projects', path: '/projects' },
    PROJECT_ID_UC: { name: 'Project Overview', path: '/projects/overview/:projectID' },
    PROJECT_ID_LC: { name: 'Project Overview', path: '/projects/overview/:projectId' },
    OPPORTUNITIES: { name: 'Browse Opportunities', path: '/opportunities' },
    OPPOTUNITY_ID: { name: 'Opportunity Overview', path: '/opportunities/overview/:opportunityId' },
    SUPPLIERS: { name: 'Browse Suppliers', path: '/suppliers' },
    SUPPLIER_ID: { name: 'Supplier Overview', path: '/supplier/overview/:supplierId' },
    MANAGE_SUPPLIER: { name: 'Manage Suppliers', path: '/suppliers/manage/:projectID/:eventID' },
    CONTRACTS: { name: 'Browse Contracts', path: '/contracts' },
    CONTRACT_ID: { name: 'Contract Management', path: '/contracts/management/:contractID' },
    CONTRACT_ID_LC: { name: 'Contract Management', path: '/contracts/management/:contractId' },
    QUESTIONNAIRES: { name: 'Browse Questionnaires', path: '/questionnaires' },
    VIEW_QUESTIONNAIRE: { name: 'View Questionnaire', path: '/questionnaires/view/:templateId' },
    VIEW_ANSWER_QUESTIONNAIRE: { name: 'View Questionnaire', path: '/questionnaires/answer/view/:responseId' },
    MANAGE_QUESTIONNAIRES_UC: { name: 'Manage Questionnaires', path: '/questionnaires/manage/:projectID/:eventID' },
    MANAGE_QUESTIONNAIRES_LC: { name: 'Manage Questionnaires', path: '/questionnaires/manage/:projectId/:eventId' },
    VIEW_SECTION_QUESTIONNAIRE: { name: 'View Questionnaire Section', path: '/sections/view/:sectionID' },
    SECTIONS: { name: 'Browse Questionnaire Sections', path: '/sections' },
    STAGES: { name: 'Browse Stages', path: '/stages' },
    VIEW_STAGE: { name: 'View Stage', path: '/stages/view/:stageId' },
    SOLUTIONS: { name: 'Browse Solutions', path: '/solutions' },
    SOLUTION_ID: { name: 'Solution Overview', path: '/solutions/overview/:solutionId' },
    CALLOFFS: { name: 'Browse CallOffs', path: '/callOffs' },
    CALLOFFS_ID: { name: 'Call Off Overview', path: '/calloff/overview/:projectId' },
    CONTRACT_REGISTER: { name: 'Contracts Register', path: '/contracts-register' },
    WORKFLOWS: { name: 'Browse Workflows', path: '/workflows/browse' },
    WORKFLOW_ID: { name: 'View Workflow', path: '/workflows/view/:workflowID' },
    SELECT_WORKFLOW: { name: 'Select Workflow', path: '/workflows/browse/project/:projectId' },
    EVALUATION_SUMMARY: { name: 'Evaluation Summary', path: '/evaluation/summary/:projectId/:eventId' },
    CORRESPONDENCES: { name: 'Correspondences', path: '/projects/correspondence/:resourceId' },
};

export const RESOURCE_KEYS = {
    OPPORTUNITY: 'opportunity',
    PROJECT: 'project',
    CALLOFF: 'calloff',
    CONTRACT: 'contract',
};

export const STATUS_LABEL_VALUES = {
    ACTION_NEEDED: { value: 'ACTION_NEEDED', label: 'Action needed', color: 'red' },
};
