import React from 'react';
import Email from '../../../../styles/images/emailIcon.svg';
// import Phone from '../../../../styles/images/phoneIcon.svg';
import Location from '../../../../styles/images/mapPointIcon.svg';
import Constants from '../../constants';

const ContactDrawer = () => (
    <section id='cpFormDrawer'>
        <p className='title-xLarge'>{Constants.title}</p>
        <p className='cpDescription caption'>{Constants.completeForm}</p>
        <div id='cpList'>
            <div className='cpListItems'>
                <div className='cpInnerIcons'>
                    <img src={Email} alt='Mail' />
                </div>
                <div className='cpListInnerText'>
                    <a href={`mailto:${Constants.supportEmail}`} className='links'>{Constants.supportEmail}</a>
                </div>
            </div>
            <div className='cpListItems'>
                <div className='cpInnerIcons'>
                    <img src={Location} alt='location' />
                </div>
                <div className='cpListInnerText'>
                    <p className='body'>{Constants.supportAddress}</p>
                </div>
            </div>
            {/* <div className='cpListItems'>
                <div className='cpInnerIcons'>
                    <img src={Phone} alt='Call' />
                </div>
                <div className='cpListInnerText'>
                    <a href={`tel:${Constants.supportPhone}`} className='links'>
                        {Constants.supportPhone}
                    </a>
                </div>
            </div> */}
        </div>
        <p className='cpDescription caption'>
            {Constants.businessHours}<br></br>
            {Constants.businessHoursFirstLine}<br></br>
            {Constants.businessHoursSecondLine}
        </p>
        <p className='cpDescription caption'>
            {Constants.checkWebTraining}<br></br>
            <a href={Constants.webTraining} title={Constants.webTrainingTitle} target='_blank' rel='noreferrer'>
                {Constants.webTrainingLabel}
            </a>
        </p>
    </section>
);


export default ContactDrawer;
