import React, { useEffect, useState, useContext } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';
import { useHistory, useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import Button from '../../components/Button';
import DashboardCard from '../../components/DashboardCard';
import LabelledSelect from '../../components/LabelledSelect';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import LotForm from '../../features/LotForm';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import Modal from '../../components/Modal';
import StatusLabel from '../../components/StatusLabel';
import { accountTypes, callOffMechanisms } from '../../config/constants';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import Constants from '../SolutionOverview/constants';


const Lot = () => {
    const [mode, setMode] = useState('');
    const [lotDetails, setLotDetails] = useState({});
    const [suppliers, setSuppliers] = useState({});
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [calloffModal, setCalloffModal] = useState(false);
    const [values, setValues] = useState(false);
    const history = useHistory();
    const { projectId, lotId, solutionId } = useParams();
    const authContext = useContext(AuthContext);
    const [solutionAccessRequestStatus, setSolutionAccessRequestStatus] = useState(
        Constants.SOLUTION_ACCESS_REQUEST_STATUSES.UNKNOWN,
    );

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`bwFilterOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`bwFilterOption-${key}`} value='' hidden selected='selected'>{'Select Call-Off Mechanism'}</option>);

    const deleteLot = async () => {
        const response = await projectManagementAPIs.deleteLot(projectId, lotId);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Lot deleted successfully.',
            });
            history.push(`/projects/overview/${projectId}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete lot.',
            });
        }
    };

    const setAwardedSuppliers = (awardedSuppliers) => {
        setSuppliers(awardedSuppliers?.filter((supplier) => supplier.supplierStatus === 'active')
            .map((fsupplier) => ({
                name: `${fsupplier?.account?.companyName || fsupplier.userID}`,
                value: fsupplier.accountID,
                ...fsupplier,
            })));
    };

    async function fetchSolutionAccessRequest(solutionID, departmentID) {
        const solutionAccessRequest = await projectManagementAPIs.getSolutionAccessRequest(
            solutionID,
            departmentID,
        );

        setSolutionAccessRequestStatus(solutionAccessRequest.data.requestStatus);
    }

    function isSolutionAccessRequestApproved() {
        return solutionAccessRequestStatus === Constants.SOLUTION_ACCESS_REQUEST_STATUSES.APPROVED;
    }

    const getLotDetails = async () => {
        try {
            let response;
            if (authContext.authenticated === true) {
                response = await projectManagementAPIs.getLotDetails(lotId);
            } else {
                response = await projectManagementAPIs.getPublicLotDetails(lotId);
            }
            if (response.status === 200) {
                setLotDetails(response.data);
                setAwardedSuppliers(response.data?.awardedSuppliers);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchLotForCallOff = async () => {
        try {
            const responseData = await projectManagementAPIs.getLotViaSolution(solutionId, lotId);
            if (responseData.status === 200) {
                setLotDetails(responseData.data);
                setAwardedSuppliers(responseData.data.awardedSuppliers);
                if (responseData?.data?.callOffMechanism) {
                    setValues({
                        ...values,
                        callOffMechanism: responseData?.data?.callOffMechanism,
                        competitionRules: responseData?.data?.competitionRules,
                    });
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const handleSubmit = () => {
        let selectSuppliers = [];
        if (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any') {
            selectSuppliers = selectedSuppliers.map((supplier) => ({
                accountID: supplier.accountID,
                departmentID: supplier.departmentID,
                supplierStatus: supplier.supplierStatus,
                userID: supplier.userID,
                lotID: lotDetails.lotID,
                lotTitle: lotDetails.title,
            }));
        } else if (values.callOffMechanism === 'directAward') {
            selectSuppliers.push({
                accountID: selectedSuppliers.accountID,
                departmentID: selectedSuppliers.departmentID,
                supplierStatus: selectedSuppliers.supplierStatus,
                userID: selectedSuppliers.userID,
                lotID: lotDetails.lotID,
                lotTitle: lotDetails.title,
            });
        }
        history.push(`/project/calloff/${projectId}`, {
            callOffForLotId: lotId,
            awardedSuppliers: selectSuppliers,
            callOffMechanism: values.callOffMechanism,
            competitionRules: values.competitionRules,
            solutionAccountId: lotDetails.solutionAccountId,
            loggedUserStatusInSolution: solutionAccessRequestStatus,

        });
    };

    useEffect(() => {
        const queryString = window.location.pathname;
        const res = queryString.split('/');
        setMode(res[5]);
        if (solutionId) {
            if (authContext?.user?.details?.departmentID) {
                fetchSolutionAccessRequest(
                    solutionId,
                    authContext.user.details.departmentID,
                );
            }
            fetchLotForCallOff();
        } else if (lotId) {
            getLotDetails();
        }
    }, [lotId]);

    const getPrettyCallOffMechanism = (callOffMechanism) => callOffMechanisms.filter(
        (eventType) => eventType.value === callOffMechanism,
    )[0]?.label;

    const getCalloffBody = () => <div className='lfModal'>
        {lotDetails?.callOffMechanism === 'any' ? <LabelledSelect
            id='selectCallOff'
            label='Select Call-Off Mechanism'
            breakColumn={true}
            onChange={(e) => {
                setValues({
                    ...values,
                    callOffMechanism: e.target.value,
                });
                setSelectedSuppliers([]);
            }}
            value={values.callOffMechanism || ''}
            options={callOffMechanisms.map((option, idx) => generateDropdownOptions(idx, option))}
        />
            : <p className='title'>{`Call-off Mechanism: ${getPrettyCallOffMechanism(values?.callOffMechanism)}`}</p>}
        {values?.callOffMechanism === 'furtherComp' && <LabelledRadioButton id={'furtherCompetitionRule'}
            label={'Further Competition Rules'}
            breakColumn={true}
            options={[{
                id: 'all-supplier',
                value: 'all',
                label: 'All Suppliers',
                checked: values.competitionRules ? values.competitionRules === 'all' : false,
                name: 'competitionRules',
            }, {
                id: 'any-supplier',
                value: 'any',
                label: 'Selected Suppliers',
                checked: values.competitionRules ? values.competitionRules === 'any' : false,
                name: 'competitionRules',
            }]}
            onChange={(e) => setValues({
                ...values,
                competitionRules: e.target.value,
            })}
            renderAsRow={true}
        />}
        {values.callOffMechanism !== 'any'
            && <>
                {(values.callOffMechanism === 'directAward' && authContext.user?.defaultWorkflow?.dAWorkflow?.workflowID)
        || (values.callOffMechanism === 'furtherComp' && authContext.user?.defaultWorkflow?.fCWorkflow?.workflowID)
                    ? <>
                        {suppliers && suppliers.length > 0
                            ? <>
                                {(values.callOffMechanism === 'directAward'
                        || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any'))
                                    ? <>
                                        <p className='title selectSearchTitle'>Please select which supplier(s) will be added to the Call-off.</p>
                                        <SelectSearch
                                            id='addSuppliersToProject-SelectSearch'
                                            options={suppliers || []}
                                            closeOnSelect={false}
                                            printOptions='on-focus'
                                            multiple={values.competitionRules === 'any' && true}
                                            search
                                            placeholder='Select Suppliers'
                                            onChange={(_event, data) => setSelectedSuppliers(data)}
                                            filterOptions={fuzzySearch}
                                            z-index='40'
                                            value={selectedSuppliers}
                                        />
                                    </>
                                    : <>
                                        <p className='title'>All Suppliers</p>
                                    </>}
                                <div className='soDefaultWorkflow'>
                                    <p className='title lf-margin-top'>Default workflow:</p>
                                    <div className='soWorkflowItem'>
                                        <p className='title'>{values.callOffMechanism === 'directAward'
                                            ? `${authContext?.user?.defaultWorkflow?.dAWorkflow?.workflowName}`
                                            : `${authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowName}`
                                        }</p>
                                        <Button
                                            id='view-workflow-btn'
                                            label='View'
                                            size='small'
                                            handleClick={values.callOffMechanism === 'directAward'
                                                ? () => history.push(`/workflows/view/${authContext?.user?.defaultWorkflow?.dAWorkflow?.workflowID}`)
                                                : () => history.push(`/workflows/view/${authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID}`)
                                            }
                                        />
                                    </div>
                                </div></>
                            : <p className='title'>{'No suppliers are active for this solution. You will be unable to create a Call-off unless you have one active supplier.'}</p>
                        }
                    </>
                    : <div className='soWorkflowItem'>
                        <p className='title'>No default workflow is set. You will be unable to create this Call-off until you have set a default workflow. Click <a href='/my-profile'>here</a> to set your default workflow.</p>
                    </div>
                }
            </>
        }
    </div>;

    const getDepartmentName = (supplier) => supplier?.account?.departments?.filter(
        (dept) => dept.departmentID === supplier.departmentID,
    )[0]?.departmentName;

    const generateAwardedSuppliersContent = () => suppliers?.map((supp, idx) => <div className='supplierItem' key={`supplier-${idx}`}>
        <div className='supplierDetails'>
            <p className='title'>{supp?.user?.contactDetails?.username}</p>
            <p className='caption'>{getDepartmentName(supp)}, {supp.name}</p>
        </div>
        <div className='buttonContainer'>
            <Button
                id={`vSupplier-${idx}`}
                type={'button'}
                variant={'primary'}
                size='medium'
                label='View'
                handleClick={() => history.push(`/supplier/overview/${supp.value}`)}
            />
        </div>
    </div>);
    return <>
        {lotDetails.solutionStatus === 'cancelled'
        && <div id='lotOverviewContainer'>
            <div className={'dashboardCard large'} id={'prfActions'}>
                <main className='dashboardCardMain'>
                    {lotDetails.statusReasons.map((status, index) => (
                        <div key={`procumentDetails-approvalHistory${index}`}>
                            <p className='title-xLarge' id='error'>Cancelled</p>
                            <p className='caption'><span className='title' id='labelText'>Reason :</span> {status.externalReason}</p>
                        </div>
                    ))}
                </main>
            </div>
        </div>}
        <section id='lotSection'>
            <div className='headerContainer'>
                <p className='title-xLarge screenTitleHeading' id='sectionTitle'>
                    {mode === 'create' && 'Enter Lot Details'}
                    {mode === 'view' && 'Lot Details'}
                    {mode === 'edit' && 'Update Lot Details'}
                </p>
                {mode === 'view'
                && <div id='headerButtonContainer'>
                    {!solutionId
                    && <>
                        <Button id='actionBtnBack'
                            className = ''
                            variant='secondary'
                            additionalVariant=''
                            label={'Back'}
                            handleClick={() => (authContext.authenticated === false
                                || authContext.user?.accountType?.toLowerCase()
                                === accountTypes.supplier
                                ? history.goBack()
                                : history.push(`/projects/overview/${projectId}`))} />
                        {authContext.authenticated === true
                        && authContext.user?.accountType?.toLowerCase() !== accountTypes.supplier
                        && lotDetails.projectStatus !== 'cancelled'
                        && lotDetails.solutionStatus !== 'cancelled'
                        && <>
                            <Button id='actionBtn'
                                className = 'cancelRequestButton'
                                variant='secondary'
                                additionalVariant='cancel'
                                label={'Delete'}
                                handleClick={() => {
                                    Confirm(deleteLot, {
                                        subTitle: `Are you sure you want to delete lot ${lotDetails.title}?`,
                                    });
                                }} />
                            <Button id='actionBtn'
                                type='submit'
                                label={'Edit'}
                                handleClick={() => history.push(`/projects/overview/${projectId}/lot/edit/${lotId}`)}
                            />
                        </>}
                    </>}
                    {authContext.authenticated === true
                    && solutionId
                    && isSolutionAccessRequestApproved()
                    && lotDetails.solutionStatus !== 'cancelled'
                    && <Button
                        id='calloff-action-btn'
                        label={'Create call off'}
                        handleClick={() => setCalloffModal(true)}
                    />}
                    {solutionId
                    && lotDetails.solutionStatus === 'cancelled'
                    && <StatusLabel
                        id={'lotCancelLabel'}
                        color={'red'}
                        labelTxt={'Cancelled'}
                    />}
                </div>}
            </div>
            <LotForm
                mode={mode}
                projectID={projectId}
                lotID={lotId}
                solutionID={solutionId}
            />
            {calloffModal
        && <Modal
            id={'calloffModal'}
            open={true}
            closeModal={() => setCalloffModal(false)}
            size='medium'
            headerTitle='Create Call-Off'
            handleMainActionBtnClick={() => handleSubmit()}
            mainActionButtonTxt='OK'
            closeButton={true}
            body={getCalloffBody()}
            helpOption={false}
            mainActionButtonDisable={(values.callOffMechanism === 'directAward' && (selectedSuppliers.length === 0 || !authContext.user?.defaultWorkflow?.dAWorkflow?.workflowID) && true)
            || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any' && (selectedSuppliers.length === 0 || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
            || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'all' && (lotDetails?.awardedSuppliers?.length === 0 || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
            || (values.callOffMechanism === 'furtherComp' && (!values.competitionRules || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
            || (values.callOffMechanism === 'any')}
        />}
        </section>
        {mode === 'view'
            && suppliers?.length > 0
            && <div id='lotOverviewContainer'>
                <DashboardCard
                    id='awardedSuppliers'
                    size='small'
                    header={true}
                    headerTitle={'Supplier(s)'}
                    caption={''}
                    content={<div id='awardedSuppliers'>
                        {generateAwardedSuppliersContent()}
                    </div>}
                    footer={false}
                />
            </div>}
    </>;
};

export default Lot;
