const GeneralConstants = {
    actions: {
    },
    strings: {
        callingCodes: [
            '+44',
            '+1',
            '+20',
            '+211',
            '+212',
            '+213',
            '+216',
            '+218',
            '+220',
            '+221',
            '+222',
            '+223',
            '+224',
            '+225',
            '+226',
            '+227',
            '+228',
            '+229',
            '+230',
            '+231',
            '+232',
            '+233',
            '+234',
            '+235',
            '+236',
            '+237',
            '+238',
            '+239',
            '+249',
            '+241',
            '+243',
            '+244',
            '+245',
            '+246',
            '+247',
            '+248',
            '+249',
            '+250',
            '+251',
            '+252',
            '+253',
            '+254',
            '+255',
            '+256',
            '+257',
            '+258',
            '+260',
            '+261',
            '+262',
            '+263',
            '+264',
            '+265',
            '+266',
            '+267',
            '+268',
            '+269',
            '+27',
            '+290',
            '+291',
            '+297',
            '+298',
            '+299',
            '+30',
            '+31',
            '+32',
            '+33',
            '+34',
            '+350',
            '+351',
            '+352',
            '+353',
            '+354',
            '+355',
            '+356',
            '+357',
            '+358',
            '+359',
            '+36',
            '+370',
            '+371',
            '+372',
            '+373',
            '+374',
            '+375',
            '+376',
            '+377',
            '+378',
            '+379',
            '+380',
            '+381',
            '+382',
            '+383',
            '+385',
            '+386',
            '+387',
            '+389',
            '+39',
            '+40',
            '+41',
            '+420',
            '+421',
            '+423',
            '+43',
            '+45',
            '+48',
            '+49',
            '+500',
            '+501',
            '+502',
            '+503',
            '+504',
            '+505',
            '+506',
            '+507',
            '+508',
            '+509',
            '+51',
            '+52',
            '+53',
            '+54',
            '+55',
            '+56',
            '+57',
            '+58',
            '+590',
            '+591',
            '+592',
            '+593',
            '+594',
            '+595',
            '+596',
            '+597',
            '+598',
            '+599',
            '+60',
            '+61',
            '+62',
            '+63',
            '+64',
            '+65',
            '+66',
            '+670',
            '+671',
            '+672',
            '+673',
            '+674',
            '+675',
            '+676',
            '+677',
            '+678',
            '+679',
            '+680',
            '+681',
            '+682',
            '+683',
            '+685',
            '+686',
            '+687',
            '+688',
            '+689',
            '+690',
            '+691',
            '+692',
            '+7',
            '+81',
            '+82',
            '+84',
            '+850',
            '+851',
            '+852',
            '+853',
            '+855',
            '+856',
            '+86',
            '+880',
            '+886',
            '+90',
            '+91',
            '+92',
            '+93',
            '+94',
            '+95',
            '+960',
            '+961',
            '+962',
            '+963',
            '+964',
            '+965',
            '+966',
            '+967',
            '+968',
            '+970',
            '+971',
            '+972',
            '+973',
            '+974',
            '+975',
            '+976',
            '+977',
            '+98',
            '+992',
            '+993',
            '+994',
            '+995',
            '+996',
            '+997',
            '+998',
        ],
        countries: [
            'United Kingdom',
            'ROI',
        ],
    },
    authorities: [null, 'Nepo', 'City of Newcastle Council', 'Durham County Council', 'Northumberland County Council', 'North Tyneside Council', 'Hartlepool Council', 'Redcar and Cleveland', 'City of Sunderland Council', 'Gateshead Council', 'Darlington Council', 'South Tyneside Council', 'Stockton-on-Tees Council', 'Middlesbrough Council'],
    guidanceBanner: `What does registering interest mean to you? 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac blandit lectus. Morbi nec dictum risus. Aenean gravida metus lorem, 
                    eu tincidunt neque accumsan eu. Pellentesque ullamcorper, quam ullamcorper rhoncus posuere, tellus ante bibendum tellus, 
                    vel sollicitudin risus nisl nec enim, Sed auctor at leo vulputate tristique. Maecenas sed porttitor mauris, at pretium risus. 
                    Fusce tincidunt dignissim libero. 
                    Step-1 - Registering interest is the key to bidding on an opportunity!
                    Step-2 - Sometimes, a buyer may need to approve your interest when the project contains sensitive information.
                    Step-3 - When your interest is approved, you'll be able to start submitting your bid.
                    `,
    PHONE_NUMBER: 'Phone Number',
    NUMBERS_PATTERN: '^[0-9]+$',
    FIEL_WITH_NUMBERS_ONLY: 'Field has to be numbers only',
    ORGANIZATION_DETAILS: 'organisationDetails',
    USER_DETAILS: 'userDetails',
    CONTACT_DETAILS: 'contactDetails',
    ADDRESS: 'address',
    NO_OF_EMPLOYESS: 'noOfEmployees',
    TYPE_SELECT: 'select',
    DEFAULT: 'default',
    ORDER_TYPE: 'orderType',

};

export default GeneralConstants;
