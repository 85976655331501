const constants = {
    data: [
        {
            responseID: '9abf3b41-5ff0-4a61-a2e5-cee27a91d089',
            sections: [
                {
                    questions: [
                        {
                            responseOptions: [],
                            name: 'second Question',
                        },
                    ],
                },
            ],
            lots: [
                {
                    assigned: true,
                    lotID: 'lot-1',
                    lotTitle: 'TestLot',
                },
                {
                    assigned: false,
                    lotID: 'lot-3',
                    lotTitle: 'TestLot2',
                },
            ],
            publishLevel: 'lot',
            userID: '5704d7f1-698b-4697-bc2c-9170f2fb3be1',
            accountID: '636fe81b-227d-46bc-8927-89bef75822b4',
            dateModified: '2022-05-18T20:04:16.994Z',
            createdDate: '12-05-2022',
            projectID: '117bfc0f-863a-4baf-b1b0-01666337fdef',
            responseStatus: 'submitted',
            departmentID: 'r1233-12sq233e-dcv34gv-23ed3',
            response: false,
            systemTimestamp: '2022-05-12T14:38:15.577Z',
            eventID: '176c8fda-0b45-4eae-b8e6-fff21211fcfe',
        },
    ],
    deliveryArea: [

    ],
    lots: [
        {
            lotID: 1,
            title: 'lot3',
            description: 'lot3 description',
            callOffMechanism: 'directAward',
            estimatedValue: '800',
            noOfSuppliers: 3,
            question: [
                {
                    questionId: 1,
                    questionTitle: 'Question 1',
                },
                {
                    questionId: 2,
                    questionTitle: 'Question 2',
                },
            ],
            industryInfo: [
                {
                    classificationID: '324595e4-9c39-11eb-924b-acde48001122',
                    level: 'Vehicle Management',
                    category: '380000',
                },
                {
                    classificationID: '324595e4-9c39-11eb-924b-acde48001122',
                    level: 'Vehicle Management',
                    category: '380000',
                },
            ],
            deliveryAreaInfo: [
                {
                    code: 'UKE',
                    description: 'Yorkshire and the Humber',
                },
                {
                    code: 'UKE',
                    description: 'Yorkshire and the Humber',
                },
            ],
        },
        {
            lotID: 2,
            title: 'lot4',
            description: 'lot4 description',
            callOffMechanism: 'directAward',
            estimatedValue: '800',
            noOfSuppliers: 3,
            question: [
                {
                    questionId: 1,
                    questionTitle: 'Question 1',
                },
                {
                    questionId: 2,
                    questionTitle: 'Question 2',
                },
                {
                    questionId: 3,
                    questionTitle: 'Question 2',
                },
            ],
            industryInfo: [
                {
                    classificationID: '324595e4-9c39-11eb-924b-acde48001122',
                    level: 'Vehicle Management',
                    category: '380000',
                },
                {
                    classificationID: '324595e4-9c39-11eb-924b-acde48001122',
                    level: 'Vehicle Management',
                    category: '380000',
                },
            ],
            deliveryAreaInfo: [
                {
                    code: 'UKE',
                    description: 'Yorkshire and the Humber',
                },
                {
                    code: 'UKE',
                    description: 'Yorkshire and the Humber',
                },
            ],
        },
    ],

    refreshHeaderText: 'Some questionnaires have changed!',
    refreshBannerText1: 'You now have the chance to review your response. ',
    refreshBannerText2: 'If you have already submitted your bid, clicking the ',
    refreshBannerText2b: '‘review’ button will withdraw your response and you will need to resubmit.',
    refreshBannerText3: 'If you haven\'t started your response or it is in progress, you will need to review these changes. ',
    refreshBannerText4: 'Please click ',
    refreshBannerText4b: '\'Review\'',
    refreshBannerText4c: ' to see the updates.',
    refreshBannerText5: 'The following questionnaires have changed:',

    amendmentReason: 'Amendment Reason: ',

    review: 'Review',

    green: 'green',
    amber: 'amber',
    red: 'red',

    viewResponse: 'View Response',
    readyToSubmit: 'Ready to Submit',
    readyToSubmitText: 'Ready to submit? Click ‘Submit All’ to submit your response.',
    responseSubmitted: 'Response Submitted',
    responseSubmittedText: 'Response submitted. Need to make changes? Click ‘Withdraw All’, update and resubmit.',
    view: 'View',
    edit: 'Edit',

    selectLots: 'Select Lots',
    editLotsTitle: 'Click on ‘Edit Lots’ to add or remove lots from submission',
    editLotsLabel: 'Edit Lots',

    lotSubmitSuccess: 'Lots selected successfully',
    lotSelectError: 'Unable to select lots.',
    retrieveInfoError: 'Unable to retrieve information.',
    answerSubmitSuccess: 'Answers submitted successfully.',
    answerSubmitError: 'Unable to submit answers',
    answerWithdrawSuccess: 'Answers withdrawn successfully.',
    answerWithdrawError: 'Unable to withdraw answers.',
    answerRefreshSuccess: 'Answers refreshed successfully.',
    answerRefreshError: 'Unable to refresh answers.',

    complete: 'Complete',
};

export default constants;
