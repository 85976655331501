import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';
import DashboardCard from '../../components/DashboardCard';
import projectManagementAPIs from '../../services/project-management.service';
import generateSolutionContent from './utils';
import LotCard from '../ProjectOverview/components/LotCard';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Toast from '../../components/Alerts/Toast/Toast';
import LabelledSelect from '../../components/LabelledSelect';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import helperFunctions from '../../utils/helperFunctions';
import AuthContext from '../../context/AuthContext';
import { callOffMechanisms } from '../../config/constants';
import publishIcon from '../../styles/images/publishIcon.svg';
import GuidanceBanner from '../../components/GuidanceBanner';
import StatusLabel from '../../components/StatusLabel';
import ShareSolutionCard from './ShareSolutionCard';
import Constants from './constants';
import SolutionAdminPanel from './SolutionAdminPanel';


const SolutionOverview = () => {
    const [solutionDetails, setSolutionDetails] = useState({});
    const [suppliers, setSuppliers] = useState({});
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [calloffModal, setCalloffModal] = useState(false);
    const [values, setValues] = useState(false);
    const history = useHistory();
    const { solutionId, projectId } = useParams();
    const authContext = useContext(AuthContext);
    const [solutionAccessRequestStatus, setSolutionAccessRequestStatus] = useState(
        Constants.SOLUTION_ACCESS_REQUEST_STATUSES.UNKNOWN,
    );


    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`bwFilterOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`bwFilterOption-${key}`} value='' hidden selected='selected'>{'Select Call-Off Mechanism'}</option>);

    const setAwardedSuppliers = (awardedSuppliers) => {
        setSuppliers(awardedSuppliers?.filter((supplier) => supplier.supplierStatus === 'active').map((supplier) => ({
            name: `${supplier?.account?.companyName || supplier.userID}`,
            value: supplier.accountID,
            ...supplier,
        })));
    };

    function canCreateSolutionAccessRequest() {
        return [
            Constants.SOLUTION_ACCESS_REQUEST_STATUSES.NOT_REQUESTED,
            Constants.SOLUTION_ACCESS_REQUEST_STATUSES.REJECTED,
        ].includes(solutionAccessRequestStatus);
    }

    function accessHasBeenRequested() {
        return solutionAccessRequestStatus
            === Constants.SOLUTION_ACCESS_REQUEST_STATUSES.ACCESS_REQUESTED;
    }

    const createSolutionAccessRequest = async () => {
        const solution = {
            solutionID: solutionDetails.solutionID,
            solutionTitle: solutionDetails.contractTitle,
            companyName: solutionDetails.account.companyName,
        };

        if (!canCreateSolutionAccessRequest()) {
            return;
        }

        const response = await projectManagementAPIs.createSolutionAccessRequest(solution);

        if (response.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: Constants.ACCESS_REQUESTED_SUCCESSFULLY,
            });
            setSolutionAccessRequestStatus(
                Constants.SOLUTION_ACCESS_REQUEST_STATUSES.ACCESS_REQUESTED,
            );
        } else {
            Toast.fire({
                icon: 'error',
                titleText: Constants.ACCESS_REQUEST_ERROR_MESSAGE,
            });
            setSolutionAccessRequestStatus(Constants.SOLUTION_ACCESS_REQUEST_STATUSES.UNKNOWN);
        }
    };

    function getSolutionAccessRequestButtonText() {
        if (canCreateSolutionAccessRequest()) {
            return 'Request Access';
        }

        if (accessHasBeenRequested()) {
            return 'Access Requested';
        }

        return '';
    }

    function isSolutionAccessRequestApproved() {
        return solutionAccessRequestStatus === Constants.SOLUTION_ACCESS_REQUEST_STATUSES.APPROVED;
    }

    function isSolutionAccessRequestButtonVisible() {
        return [
            Constants.SOLUTION_ACCESS_REQUEST_STATUSES.NOT_REQUESTED,
            Constants.SOLUTION_ACCESS_REQUEST_STATUSES.ACCESS_REQUESTED,
            Constants.SOLUTION_ACCESS_REQUEST_STATUSES.REJECTED,
        ].includes(solutionAccessRequestStatus);
    }

    async function fetchSolutionAccessRequest(solutionID, departmentID) {
        const solutionAccessRequest = await projectManagementAPIs.getSolutionAccessRequest(
            solutionID,
            departmentID,
        );

        setSolutionAccessRequestStatus(solutionAccessRequest.data.requestStatus);
    }

    const fetchSolution = async () => {
        const responseData = await projectManagementAPIs.getSolution(solutionId);
        if (responseData.status === 200) {
            setSolutionDetails(responseData.data);
            setAwardedSuppliers(responseData.data.awardedSuppliers);

            if (responseData?.data?.callOffMechanism) {
                setValues({
                    ...values,
                    callOffMechanism: responseData?.data?.callOffMechanism,
                    competitionRules: responseData?.data?.competitionRules,
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchSolution();
        fetchSolutionAccessRequest(
            solutionId,
            authContext.user.details.departmentID,
        );
    }, [solutionId]);

    const getDepartmentName = (supplier) => supplier?.account?.departments?.filter(
        (dept) => dept.departmentID === supplier.departmentID,
    )[0]?.departmentName;

    const handleSubmit = () => {
        let awardedSuppliers = [];
        if (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any') {
            awardedSuppliers = selectedSuppliers.map((supplier) => ({
                accountID: supplier.accountID,
                departmentID: supplier.departmentID,
                supplierStatus: supplier.supplierStatus,
                userID: supplier.userID,
            }));
        } else if (values.callOffMechanism === 'directAward') {
            awardedSuppliers.push({
                accountID: selectedSuppliers.accountID,
                departmentID: selectedSuppliers.departmentID,
                supplierStatus: selectedSuppliers.supplierStatus,
                userID: selectedSuppliers.userID,
            });
        }
        history.push(`/project/calloff/${solutionDetails.projectID}`, {
            awardedSuppliers,
            callOffMechanism: values.callOffMechanism,
            competitionRules: values.competitionRules,
            solutionAccountId: solutionDetails.accountID,
            loggedUserStatusInSolution: solutionAccessRequestStatus,
        });
    };

    const getPrettyCallOffMechanism = (callOffMechanism) => callOffMechanisms.filter(
        (eventType) => eventType.value === callOffMechanism,
    )[0]?.label;

    const getCalloffBody = () => <div className='lfModal'>
        {solutionDetails?.callOffMechanism === 'any' ? <LabelledSelect
            id='selectCallOff'
            label='Select Call-Off Mechanism'
            breakColumn={true}
            onChange={(e) => {
                setValues({
                    ...values,
                    callOffMechanism: e.target.value,
                });
                setSelectedSuppliers([]);
            }}
            value={values.callOffMechanism || ''}
            options={callOffMechanisms.map((option, idx) => generateDropdownOptions(idx, option))}
        />
            : <p className='title'>{`Call-off Mechanism: ${getPrettyCallOffMechanism(values?.callOffMechanism)}`}</p>}
        {values?.callOffMechanism === 'furtherComp' && <LabelledRadioButton id={'furtherCompetitionRule'}
            label={'Further Competition Rules'}
            breakColumn={true}
            options={[{
                id: 'all-supplier',
                value: 'all',
                label: 'All Suppliers',
                checked: values.competitionRules ? values.competitionRules === 'all' : false,
                name: 'competitionRules',
            }, {
                id: 'any-supplier',
                value: 'any',
                label: 'Selected Suppliers',
                checked: values.competitionRules ? values.competitionRules === 'any' : false,
                name: 'competitionRules',
            }]}
            onChange={(e) => setValues({
                ...values,
                competitionRules: e.target.value,
            })}
            renderAsRow={true}
        />}
        {values.callOffMechanism !== 'any'
            && <>
                {(values.callOffMechanism === 'directAward' && authContext.user?.defaultWorkflow?.dAWorkflow?.workflowID)
                    || (values.callOffMechanism === 'furtherComp' && authContext.user?.defaultWorkflow?.fCWorkflow?.workflowID)
                    ? <>
                        {suppliers && suppliers.length > 0
                            ? <>
                                {(values.callOffMechanism === 'directAward'
                                    || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any'))
                                    ? <>
                                        <p className='title selectSearchTitle'>Please select which supplier(s) will be added to the Call-off.</p>
                                        <SelectSearch
                                            id='addSuppliersToProject-SelectSearch'
                                            options={suppliers || []}
                                            closeOnSelect={false}
                                            printOptions='on-focus'
                                            multiple={values.competitionRules === 'any' && true}
                                            search
                                            placeholder='Select Suppliers'
                                            onChange={(_event, data) => setSelectedSuppliers(data)}
                                            filterOptions={fuzzySearch}
                                            z-index='40'
                                            value={selectedSuppliers}
                                        />
                                    </>
                                    : <>
                                        <p className='title'>All Suppliers</p>
                                    </>}
                                <div className='soDefaultWorkflow'>
                                    <p className='title lf-margin-top'>Default workflow:</p>
                                    <div className='soWorkflowItem'>
                                        <p className='title'>{values.callOffMechanism === 'directAward'
                                            ? `${authContext?.user?.defaultWorkflow?.dAWorkflow?.workflowName}`
                                            : `${authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowName}`
                                        }</p>
                                        <Button
                                            id='view-workflow-btn'
                                            label='View'
                                            size='small'
                                            handleClick={values.callOffMechanism === 'directAward'
                                                ? () => history.push(`/workflows/view/${authContext?.user?.defaultWorkflow?.dAWorkflow?.workflowID}`)
                                                : () => history.push(`/workflows/view/${authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID}`)
                                            }
                                        />
                                    </div>
                                </div></>
                            : <p className='title'>{'No suppliers are active for this solution. You will be unable to create a Call-off unless you have one active supplier.'}</p>
                        }
                    </>
                    : <div className='soWorkflowItem'>
                        <p className='title'>No default workflow is set. You will be unable to create this Call-off until you have set a default workflow. Click <a href='/my-profile'>here</a> to set your default workflow.</p>
                    </div>
                }
            </>
        }
    </div>;

    const lotsContent = () => ((solutionDetails?.lotsOverview?.length > 0)
        ? <div id='lotContainer'>
            {solutionDetails.lotsOverview.sort(
                (a, b) => a.lotOrderNo - b.lotOrderNo,
            ).map((lot, idx) => {
                const codesAndCategories = lot?.industryInfo?.map((code) => `${code.category} - ${code.level}`);
                const deliveryAreas = lot?.deliveryAreaInfo?.map((deliveryArea) => `${deliveryArea.code} - ${deliveryArea.description}`);

                return <LotCard
                    key={`lot-${idx}`}
                    id={`lots-id-${idx}`}
                    header
                    actionBtn={true}
                    caption={`Lot #${lot.lotOrderNo}`}
                    headerTitle={lot.title}
                    content={<>
                        <div className='contentItem'>
                            <>
                                <p className='caption-small projectHeader'>Codes and Categories:</p>
                                <p className='title-small projectTitle'>{codesAndCategories?.length > 0
                                    ? codesAndCategories.map((role, i) => {
                                        if (i + 1 === codesAndCategories.length) {
                                            return `${helperFunctions.camel2title(role)}`;
                                        }
                                        return `${helperFunctions.camel2title(role)}, `;
                                    })
                                    : '-'}</p>
                            </>
                            <>
                                <p className='caption-small projectHeader'>Delivery Area:</p>
                                <p className='title-small projectTitle'>{deliveryAreas?.length > 0
                                    ? deliveryAreas.map((role, i) => {
                                        if (i + 1 === deliveryAreas.length) {
                                            return `${helperFunctions.camel2title(role)}`;
                                        }
                                        return `${helperFunctions.camel2title(role)}, `;
                                    })
                                    : '-'}</p>
                            </>
                            <>
                                <p className='caption-small projectHeader'>Estimated Value:</p>
                                <p className='title-small projectTitle'>{lot.estimatedValue
                                    ? helperFunctions.formatCurrency(lot.estimatedValue) : '-'}</p>
                            </>
                        </div>
                    </>
                    }
                    actionBtnText='View Lot'
                    handlerActionBtn={() => { history.push(`/solutions/overview/${solutionDetails.projectID}/lot/view/${lot.lotID}/${solutionDetails.solutionID}`); }}
                />;
            })}
        </div>
        : <div id='noLot'>
            <p className='caption' id='lotText'>No Lots available.</p>
        </div>);

    const generateAwardedSuppliersContent = () => suppliers?.map((supp, idx) => <div className='supplierItem' key={`supplier-${idx}`}>
        <div className='supplierDetails'>
            <p className='title'>{supp?.user?.contactDetails?.username}</p>
            <p className='caption'>{supp.departmentID ? `${getDepartmentName(supp)}, ${supp.name}` : supp.name}</p>
        </div>
        <div className='buttonContainer'>
            <Button
                id={`vSupplier-${idx}`}
                type={'button'}
                variant={'primary'}
                size='medium'
                label='View'
                handleClick={() => history.push(`/supplier/overview/${supp.value}`)}
            />
        </div>
    </div>);

    return (<div id='solutionOverview'>
        {solutionDetails?.lotsOverview?.length > 0
            && <GuidanceBanner
                id='callOffGuidance'
                headerText={<><img src={publishIcon} alt={'publish'} className='publish-icon'></img> Information</>}
                bannerText1='Please view a lot to call-off from this solution.'
            />}
        {solutionDetails.solutionStatus === 'cancelled'
            && <div className='solutionOverviewContainer'>
                <div className={'dashboardCard large'} id={'prfActions'}>
                    <main className='dashboardCardMain'>
                        {solutionDetails.statusReasons.map((status, index) => (
                            <div key={`procumentDetails-approvalHistory${index}`}>
                                <p className='title-xLarge' id='error'>Cancelled</p>
                                <p className='caption'><span className='title' id='labelText'>Reason :</span> {status.externalReason}</p>
                            </div>
                        ))}
                    </main>
                </div>
            </div>}
        <div className='solutionOverviewContainer'>
            <DashboardCard
                id='solutionDetails'
                size='large'
                header={true}
                headerTitle={solutionDetails.contractTitle}
                caption={`Solution # ${solutionDetails.contractReference ? solutionDetails.contractReference : '-'}`}
                variant={'primary'}
                actionBtn={isSolutionAccessRequestApproved()
                    && solutionDetails?.lotsOverview?.length === 0
                    && solutionDetails.solutionStatus !== 'cancelled'}
                actionBtnText={'Create Call-off'}
                handlerActionBtn={() => setCalloffModal(true)}
                secondaryVariant={accessHasBeenRequested() ? 'secondary' : 'primary'}
                secondaryActionBtn={isSolutionAccessRequestButtonVisible()}
                secondaryActionBtnText={getSolutionAccessRequestButtonText()}
                handleSecondaryActionBtn={() => createSolutionAccessRequest()}
                content={solutionDetails && generateSolutionContent(solutionDetails)}
                footer={false}
                headerStatus={solutionDetails.solutionStatus === 'cancelled'}
                headerStatusContent={
                    <StatusLabel
                        id={'cancelSolution'}
                        color={'red'}
                        labelTxt={'Cancelled'}
                    />
                }
            />
        </div>

        {(authContext?.user?.accountId === solutionDetails?.accountID)
            && <div className='solutionOverviewContainer'>
                {solutionDetails.solutionID
                    && <ShareSolutionCard solution={solutionDetails}></ShareSolutionCard>}
            </div>
        }

        {(authContext?.user?.role === 'admin')
            && <div className='solutionOverviewContainer'>
                {solutionDetails.solutionID
                    && <SolutionAdminPanel
                        solutionID={solutionDetails.solutionID}
                        contractReference={solutionDetails.contractReference}
                    />}
            </div>
        }

        <div className='solutionOverviewContainer'>
            <DashboardCard
                id='lots'
                size='large'
                header={true}
                headerTitle={'Lots'}
                caption={''}
                content={<div id='lotContainerMain'>{lotsContent()}</div>}
                footer={false}
                secondaryActionBtn={!solutionDetails?.lotsOverview}
                secondaryActionBtnText={'View Lot(s)'}
                handleSecondaryActionBtn={() => {
                    history.push(`/projects/overview/${projectId}/lot/browse`);
                }}
                secondaryVariant={'secondary'}
                secondaryAdditionalVariant={'skinless'}
            />
        </div>
        {suppliers?.length > 0
            && solutionDetails?.lotsOverview?.length === 0
            && <div className='solutionOverviewContainer'>
                <DashboardCard
                    id='awardedSuppliers'
                    size='small'
                    header={true}
                    headerTitle={'Supplier(s)'}
                    caption={''}
                    content={<div id='awardedSuppliers'>
                        {generateAwardedSuppliersContent()}
                    </div>}
                    footer={false}
                />
            </div>}
        {calloffModal
            && <Modal
                id={'calloffModal'}
                open={true}
                closeModal={() => setCalloffModal(false)}
                size='medium'
                headerTitle='Create Call-Off'
                handleMainActionBtnClick={() => handleSubmit()}
                mainActionButtonTxt='OK'
                closeButton={true}
                body={getCalloffBody()}
                helpOption={false}
                mainActionButtonDisable={(values.callOffMechanism === 'directAward' && (selectedSuppliers.length === 0 || !authContext.user?.defaultWorkflow?.dAWorkflow?.workflowID) && true)
                    || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'any' && (selectedSuppliers.length === 0 || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
                    || (values.callOffMechanism === 'furtherComp' && values.competitionRules === 'all' && (solutionDetails?.awardedSuppliers?.length === 0 || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
                    || (values.callOffMechanism === 'furtherComp' && (!values.competitionRules || !authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID) && true)
                    || (values.callOffMechanism === 'any')}
            />}
    </div>
    );
};

export default SolutionOverview;
