/* eslint-disable max-len */
/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useEffect, useContext, useState } from 'react';
import {
    useHistory, useParams, generatePath, useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch from 'react-select-search';
import { Autosave } from 'react-autosave';
import Button from '../../components/Button';
import Form from '../../components/Form';
import LabelledInput, { LabelledInputTypeNumber, LabelledInputTypeText } from '../../components/LabelledInput';
import LabelledTextArea from '../../components/LabelledTextArea';
import StatusLabel from '../../components/StatusLabel';
import Accordion from '../Accordion';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Constants from './constants';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import SectionDetailsModal from './components/SectionDetailsModal';
import VersionHistoryModal from './components/VersionHistory';
import addIcon from '../../styles/images/addIcon.svg';
import chevronUpWhiteIcon from '../../styles/images/chevronUpWhite.svg';
import chevronDownWhiteIcon from '../../styles/images/chevronDownWhite.svg';
import informationIcon from '../../styles/images/informationIcon.svg';
import QuestionnaireQuestionModal from './components/QuestionModal';
import helperFunctions from '../../utils/helperFunctions';
import editIcon from '../../styles/images/edit.png';
import WorkflowEventModal from './components/EventModal';
import AuthContext from '../../context/AuthContext';
import Upload from '../Upload';
import {
    stageCategories, allSteeringRoles, workflowEvents,
    workflowEventTypes, workflowRestrictionConstants, eventStatuses,
    passFailLabels, passFailValues, RICH_TEXT,
    userRoles,
    accountTypes,
    MAX_CHAR_COUNT_5000,
    evaluationPanel,
    STATUS_LABEL_VALUES,
} from '../../config/constants';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import Alert from '../../components/Alerts/Alert/index';
import Toast from '../../components/Alerts/Toast/Toast';
import actions from '../../screens/EditProjectWorkflow/slice/workflows.actions';
import projectManagementAPIs from '../../services/project-management.service';
import rbac from '../../rbac';
import { cleanupDeletedDocument, mergeUploadedDocuments } from '../../utils/uploadHelper';
import gatewayAPIs from '../../services/gateway.service';
import LabelledSelect from '../../components/LabelledSelect';
import isSupplierRole from '../../utils/isSupplierRole';
import { VersionSelector, Viewer } from '../../components/DiffViewer';
import {
    getVersionsStructure, haveVersions, getDescriptionBlock,
    EvaluationPanelRadioButtonGroup, isBidSubmitted,
    getConfirmationTextPopup,
    EvaluatorDropdownSection,
    IndividualEvaluatorsScores,
    showDiffViewerDropdown,
    generateEmptyContentForIndividualEvaluator,
    haveQuestionnairesAndResponsesPreviousVersions,
    updateQuestionsWithIndividualEvaluatorsFromSection,
    getSectionIndividualEvaluators,
    evaluatorDropdownIsShown,
    showReasonIfChangesInQuestionnaire,
    updateButtonIsDisabledInQuestionnaires,
    getNumberOfAmendedQuestions,
} from './helpers';
import projectService from '../../services/project.service';

const DocumentBuilder = ({
    interest = [],
    documentMode = 'Add',
    context,
    type,
    initialDocumentState,
    sectionLabel,
    sectionItemLabel,
    allowSectionReordering = false,
    allowSectionItemsReordering = false,
    attachedToProject = false,
    sectionDetailsUserList,
    handlePrimaryToolbarSubmitButton,
    handleEnableDisableBtnClick,
    memberAuthorities,
    isALiveQuestionnaire = false,
    showVersionDropdown = false,
    handleShowDiffsInViewer,
    structureToCompare,
    steeringInfo,
    withAutosave = false,
    handleAutoSaveData,
}) => {
    const history = useHistory();
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const [documentState, setDocumentState] = useState(initialDocumentState);
    const [showSectionDetailsModal, setShowSectionDetailsModal] = useState({ show: false });
    const [showChangeHistoryModal, setShowChangeHistoryModal] = useState({ show: false });
    const [showSectionItemModal, setShowSectionItemModal] = useState({ show: false });
    const [sectionItemModal, setSectionItemModal] = useState(<></>);
    const [showDiffViewer, setShowDiffViewer] = useState(false);
    const [valueDropwdown, setValueDropdown] = useState(undefined);
    const {
        projectId, resourceName, templateType, templateId, responseId, importToQuesID, isImport, routeToMarket, workflowID, eventId, accountId, callOffMechanism, contractID,
    } = useParams();
    const dispatch = useDispatch();
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));
    const [numberOfAmendedQuestions, setNumberOfAmendedQuestions] = useState(0);

    const getPrettyEventType = (eType) => workflowEventTypes.filter(
        (eventType) => eventType.value === eType,
    )[0]?.label;

    const handleSecondaryToolbarBtnClick = () => {
        dispatch(actions.setBuilderState({}));
        if (isImport) {
            history.go(-3);
        } else history.goBack();
    };
    useEffect(() => {
        setDocumentState(initialDocumentState);
    }, [initialDocumentState]);


    const throttle = (callbackFn, limit) => {
        let wait = false;
        return () => {
            if (!wait) {
                callbackFn.call();
                wait = true;
                setTimeout(() => {
                    wait = false;
                }, limit);
            }
        };
    };

    const handleScroll = () => {
        const header = document.getElementById('toolbarSection');
        const sticky = header?.offsetTop;
        // FYI Note that the class used is called sticky, but it's using fixed positioning
        if (window.pageYOffset > sticky) {
            header?.classList?.add('sticky');
        } else {
            header?.classList?.remove('sticky');
        }
    };

    const handleSQScroll = () => {
        const header = document.getElementById('toolbarSectionSQ');
        const sticky = header?.offsetTop;
        // FYI Note that the class used is called sticky, but it's using fixed positioning
        if (window.pageYOffset > sticky) {
            header?.classList?.add('sticky');
        } else {
            header?.classList?.remove('sticky');
        }
    };

    useEffect(() => {
        setNumberOfAmendedQuestions(getNumberOfAmendedQuestions(documentState.sections));
        const throttledScrollHandler = throttle(handleScroll, 10);
        const throttledSQScrollHander = throttle(handleSQScroll, 10);
        window.addEventListener('scroll', throttledScrollHandler);
        window.addEventListener('scroll', throttledSQScrollHander);
        return () => {
            window.removeEventListener('scroll', throttledScrollHandler);
            window.removeEventListener('scroll', throttledSQScrollHander);
        };
    }, []);


    const isAddorEditMode = () => documentMode.toLowerCase() === 'add'
        || documentMode.toLowerCase() === 'edit';

    const isViewMode = () => documentMode.toLowerCase() === 'view'
        || documentMode.toLowerCase() === 'answer'
        || documentMode.toLowerCase() === 'viewanswer'
        || documentMode.toLowerCase() === 'viewanswersqmode'
        || documentMode.toLowerCase() === 'answersqmode';

    const isEditMode = () => documentMode.toLowerCase() === 'edit';

    const isAddMode = () => documentMode.toLowerCase() === 'add';

    const isAnswerMode = () => documentMode.toLowerCase() === 'answer'
        || documentMode.toLowerCase() === 'viewanswer'
        || documentMode.toLowerCase() === 'evaluate'
        || documentMode.toLowerCase() === 'viewevaluation'
        || documentMode.toLowerCase() === 'answersqmode'
        || documentMode.toLowerCase() === 'viewanswersqmode';

    const isViewAnswerMode = () => documentMode.toLowerCase() === 'viewanswer'
        || documentMode.toLowerCase() === 'evaluate'
        || documentMode.toLowerCase() === 'viewevaluation'
        || documentMode.toLowerCase() === 'viewanswersqmode';

    const viewAnswerSO = () => documentMode.toLowerCase() === 'viewanswer';

    const isEvaluateMode = () => documentMode.toLowerCase() === 'evaluate';
    const isWeightageMode = () => documentMode.toLowerCase() === 'weightage';
    const isViewEvaluateMode = () => documentMode.toLowerCase() === 'viewevaluation';
    const isViewAnswerSQMode = () => documentMode.toLowerCase() === 'viewanswersqmode';
    const isAnswerSQMode = () => documentMode.toLowerCase() === 'answersqmode';
    const containsAll = (neededComb, toBeChecked) => neededComb.every(Set.prototype.has, new Set(toBeChecked));

    const showEvaluatorsDropdowns = evaluatorDropdownIsShown(isEvaluateMode, isViewEvaluateMode, documentState.details.evaluationPanel, steeringInfo);

    const roleCheck = () => {
        let action = null;
        switch (context.toLowerCase()) {
            case 'questionnaire':
                action = rbac.action.addQuestionnaire;
                break;
            case 'workflow':
                action = rbac.action.addWorkflow;
                break;
            case 'stage':
                action = rbac.action.addStage;
                break;
            case 'section':
                action = rbac.action.addSection;
                break;
            case 'event':
                action = rbac.action.addEvent;
                break;
            default:
                action = null;
        }
        return rbac.can(action,
            [authContext.user.accountType.toLowerCase() + authContext.user.role]);
    };

    const workFlowAndStageValidation = (mode) => {
        for (let section = 0; section < documentState.sections?.length; section++) {
            if (documentState.sections[section]?.category !== stageCategories.contractManagement) {
                const sectionEventWithPublishItems = documentState.sections[section]?.sectionItems?.map((x) => `${x.eventType}-${x.publishType}`);
                const sectionEventItems = documentState.sections[section]?.sectionItems?.map((x) => x.eventType);
                const publishWithMarketConsultationExist = sectionEventWithPublishItems?.filter((eventType) => eventType === workflowRestrictionConstants.publishwithMarket);
                const publishExist = sectionEventItems?.filter((eventType) => eventType === workflowEvents.publish);
                const evaluationExist = sectionEventItems?.filter((eventType) => eventType === workflowEvents.evaluation);
                const issueAwardUnsuccessfulNoticesExists = sectionEventItems?.filter((eventType) => eventType === workflowEvents.issueAwardUnsuccessfulNotices);
                if (publishExist?.length > 1) {
                    Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                            is having more than one Publish event.`, null, true);
                    return false;
                }
                if (publishWithMarketConsultationExist?.length > 0) {
                    if (evaluationExist?.length > 0) {
                        Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                            can't have Evaluation event with Publish Market Consultation event.`, null, true);
                        return false;
                    }
                    if (issueAwardUnsuccessfulNoticesExists?.length > 0) {
                        Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                        can't have Issue/Award Unsuccessful Notices event with Publish Market Consultation event.`, null, true);
                        return false;
                    }
                } else {
                    const anyOneOutOfPIEEventExists = publishExist?.length > 0 || evaluationExist?.length > 0 || issueAwardUnsuccessfulNoticesExists?.length > 0;
                    if (evaluationExist?.length > 1) {
                        Alert('Error', `${documentState.sections[section].name || `${section + 1}`}-
                                is having more than one Evaluation event.`, null, true);
                        return false;
                    }
                    if (issueAwardUnsuccessfulNoticesExists?.length > 1) {
                        Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                                is having more than one Issue/Award Unsuccessful Notices event.`, null, true);
                        return false;
                    }
                    if (anyOneOutOfPIEEventExists) {
                        const isDirectAward = mode === 'Create'
                            ? (projectService.isRTMDirectAward(routeToMarket) || projectService.isRTMDirectAward(documentState.details.route))
                            : (projectService.isRTMDirectAward(documentState.details.routeToMarket) || projectService.isRTMDirectAward(documentState.details.route));
                        const PIECombination = isDirectAward
                            ? [workflowEvents.publish,
                                workflowEvents.issueAwardUnsuccessfulNotices] : [workflowEvents.publish,
                                workflowEvents.evaluation, workflowEvents.issueAwardUnsuccessfulNotices];

                        const validateCombination = containsAll(PIECombination, sectionEventItems);
                        if (!validateCombination) {
                            if (isDirectAward) {
                                Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                                is missing a Publish - Issue/Award Unsuccessful Notices event combination.`, null, true);
                            } else {
                                Alert('Error', `${documentState.sections[section].name || `${section + 1}`}
                                is missing a Publish - Evaluation - Issue/Award Unsuccessful Notices event combination.`, null, true);
                            }
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    const emptySectionStageValidation = () => {
        for (let section = 0; section < documentState.sections?.length; section++) {
            if (!(documentState.sections[section]?.sectionItems?.length > 0)) {
                if (context.toLowerCase() === 'questionnaire' || context.toLowerCase() === 'section') {
                    Alert('Warning', `${documentState.sections[section].title || `${section + 1}`}
                    should have at least one question`, null, true);
                } else {
                    Alert('Warning', `${documentState.sections[section].name || `${section + 1}`}
                should have at least one event`, null, true);
                }
                return false;
            }
        }
        return true;
    };

    /**
     * @description - This function is used to get the options to be displayed in the confirm dialog
     * @param {string} target - The target of the action
     * @returns {object} - The options to be displayed in the confirm dialog
     */
    const getInputOptions = (target) => {
        const options = {
            create: `${Constants.DOCUMENT_CREATE_NEW} ${context.toLowerCase()}`,
        };
        const isWorkflowOrQuestionnaire = context.toLowerCase() === Constants.DOCUMENT_CONTEXT.WORKFLOW
            || context.toLowerCase() === Constants.DOCUMENT_CONTEXT.QUESTIONNAIRE;
        const isUpdateEditMode = isEditMode() && target.toLowerCase() === Constants.DOCUMENT_TARGET.UPDATE;
        const isUserAuthorized = documentState.details.accountIDs === authContext.user.accountId
            || (authContext.user.accountId === accountTypes.nepo && authContext.user.role === userRoles.admin);

        if (isWorkflowOrQuestionnaire && isUpdateEditMode && isUserAuthorized) {
            options.overwrite = `${Constants.DOCUMENT_OVERWRITE} ${context.toLowerCase()}`;
        }
        return options;
    };

    const liveQuestionnaire = initialDocumentState?.isALiveQuestionnaire ? initialDocumentState.isALiveQuestionnaire : isALiveQuestionnaire;


    const handlePrimaryToolbarBtnClick = async (e, data) => {
        let payload = {
            ...documentState,
        };
        let isValid = true;
        let showReason = true;

        if (showEvaluatorsDropdowns) { // check only if questionnaire is type consensus
            showReason = showReasonIfChangesInQuestionnaire(documentState, initialDocumentState);
        }

        if (context.toLowerCase() === 'questionnaire' || context.toLowerCase() === 'section'
            || context.toLowerCase() === 'workflow' || context.toLowerCase() === 'stage') {
            if (e.target.textContent === 'Create' || e.target.textContent === 'Update') {
                isValid = emptySectionStageValidation();
            }
        }

        if (context.toLowerCase() === 'questionnaire') {
            if (e.target.textContent === 'Create' || e.target.textContent === 'Update') {
                if (documentState?.details?.sectionsWeighted
                    && (documentState.details.maxScore < 1 || documentState.details.maxScore === undefined)) {
                    Alert('Warning', 'Please enter max score greater than 0.', null, true);
                    isValid = false;
                }
                if (documentState?.details?.shareQuestionnaire === 'true'
                    && documentState?.details?.shareQuestionnaireWithEveryone === 'false'
                    && !documentState?.details?.sharedWith) {
                    Alert('Warning', Constants.SELECT_AT_LEAST_ONE_MEMBER_AUTHORITY, null, true);
                    isValid = false;
                }
                payload = {
                    ...documentState,
                    details: {
                        ...documentState.details,
                        templateStatus: 'created',
                    },
                };
            }
            if (e.target.textContent === 'Save as Draft') {
                payload = {
                    ...documentState,
                    details: {
                        ...documentState.details,
                        templateStatus: 'draft',
                    },
                };
            }
        }

        if ((context.toLowerCase() === 'workflow' || context.toLowerCase() === 'stage') && isAddorEditMode()
            && (e.target.textContent === 'Create' || e.target.textContent === 'Update')) {
            isValid = isValid && workFlowAndStageValidation(e.target.textContent);
        }

        if (isValid) {
            const isEditQuestionnaire = (context.toLowerCase() === 'questionnaire' || context.toLowerCase() === 'workflow')
                && (!projectId || projectId === '_')
                && isEditMode();

            const userConfirmed = getConfirmationTextPopup(isAddMode(), liveQuestionnaire, context, showReason);

            Confirm((confirmAction) => {
                if (confirmAction === undefined) {
                    handlePrimaryToolbarSubmitButton(payload);
                } else {
                    handlePrimaryToolbarSubmitButton(
                        (data === undefined || data.target) ? payload : data,
                        confirmAction,
                    );
                }
            }, {
                title: isEditQuestionnaire ? 'How do you want to proceed?' : '',
                confirmBtnText: isEditQuestionnaire ? 'Ok' : '',
                subTitle: isEditQuestionnaire ? '' : userConfirmed,
                input: isEditQuestionnaire ? 'radio' : (liveQuestionnaire && showReason ? 'textarea' : null),
                inputOptions: isEditQuestionnaire
                    ? getInputOptions(e.target.textContent)
                    : null,
                inputLabel: liveQuestionnaire ? 'Reason' : null,
                inputPlaceholder: liveQuestionnaire ? 'Type your reason here' : null,
                inputValidator: isEditQuestionnaire || liveQuestionnaire
                    ? (value) => {
                        if (!value) {
                            return liveQuestionnaire ? 'Please enter the reason' : 'You need to choose something!';
                        }
                        return null;
                    }
                    : null,
                icon: isEditQuestionnaire ? 'question' : '',
            });
        }
    };

    const handleResponseSubmit = (e) => {
        e.preventDefault();
        if (context.toLowerCase() === 'questionnaire' && isAnswerMode()) {
            let isInValid = false;
            for (let section = 0; section < documentState.sections?.length && !isInValid; section++) {
                for (let sectionItem = 0; sectionItem < documentState.sections[section]?.sectionItems?.length && !isInValid; sectionItem++) {
                    if (documentState.sections[section].sectionItems[sectionItem].responseType === 'file'
                        && !documentState.sections[section].sectionItems[sectionItem].responseOptional
                        && (documentState.sections[section].sectionItems[sectionItem].supportingAnswerDocuments === undefined
                            || documentState.sections[section].sectionItems[sectionItem].supportingAnswerDocuments?.length === 0)) {
                        isInValid = true;
                        Alert('Warning',
                            `Question -- ${documentState.sections[section].sectionItems[sectionItem]?.questionTitle || `${sectionItem + 1}`} under section -- ${documentState.sections[section].title || `${section + 1}`}
                            is missing supporting document`, null, true);
                        break;
                    }
                }
            }
            if (!isInValid) { handlePrimaryToolbarSubmitButton(documentState, 'submit'); }
        }
    };

    const handleStartAnswer = (e) => {
        e.preventDefault();
        handlePrimaryToolbarSubmitButton();
    };

    const handleSaveAnswer = (e) => {
        e.preventDefault();
        handlePrimaryToolbarSubmitButton(documentState, 'save');
    };

    // const handleSubmitAnswer = () => {
    //    e.preventDefault();
    //     handlePrimaryToolbarSubmitButton(documentState, 'submit');
    // };

    const handleDetailsInput = (event, isBoolean) => {
        const attribute = event.target.name;
        const { value } = event.target;
        setDocumentState({
            ...documentState,
            details: {
                ...documentState.details,
                [attribute]: isBoolean ? value === 'true' : value,
            },
        });
    };

    const handleIndividualEvaluators = (selectedEvaluators, sectionNumber, sectionItemNumber) => {
        setDocumentState((prevState) => {
            const sections = prevState.sections.map((section, secIndex) => {
                if (secIndex !== sectionNumber) return section;
                return {
                    ...section,
                    sectionItems: section.sectionItems.map((item, itemIndex) => {
                        if (itemIndex !== sectionItemNumber) return item;
                        return {
                            ...item,
                            individualEvaluators: selectedEvaluators,
                        };
                    }),
                };
            });
            return { ...prevState, sections };
        });
    };

    const generateDropdownOptions = (key, option, placeholderContext) => ((key > 0)
        ? <option key={`dbDropdownOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`dbDropdownOption-${key}`} value='' hidden>
            {`Select ${placeholderContext}`}
        </option>);

    const generateDetailsAccordionSectionData = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                return <Form
                    id={`${context.toLowerCase()}DetailsForm`}>
                    <LabelledInput
                        id='templateName'
                        type={LabelledInputTypeText}
                        label='Title'
                        placeholder='Enter title'
                        value={documentState.details.templateName || ''}
                        onChange={(event) => handleDetailsInput(event)}
                        breakColumn={false}
                        required={true} />
                    <div className='formElementsDivider'></div>
                    <LabelledTextArea
                        id='description'
                        label='Enter description'
                        onChange={(event) => handleDetailsInput(event)}
                        value={documentState.details.description || ''}
                        placeholder='Enter description'
                        maxCharCount={MAX_CHAR_COUNT_5000}
                        currentCharCount={documentState.details.description
                            ? documentState.details.description.length : 0}
                        breakColumn={false}
                        required={true}
                        richText={true}
                    />
                    <div className='formElementsDivider'></div>
                    <LabelledInput
                        id='templateType'
                        label='Type'
                        type={LabelledInputTypeText}
                        breakColumn={false}
                        value={Constants.questionnaireTypes.filter(
                            (el) => el.value === type || el.value === documentState.details.templateType,
                        ).map((elem) => elem.label).toString()}
                        onChange={() => null()}
                        required={true}
                        readonly={true} />
                    <div className='formElementsDivider'></div>
                    {type !== 'selection' && type !== 'defaultSelectionQuestionnaire' && <LabelledRadioButton
                        id='sectionsWeighted'
                        label='Are sections weighted?'
                        breakColumn={false}
                        onChange={(event) => handleDetailsInput(event, true)}
                        options={[{
                            label: 'Yes',
                            value: 'true',
                            id: 'sectionsWeightedYes',
                            name: 'sectionsWeighted',
                            checked: documentState.details.sectionsWeighted,
                        }, {
                            label: 'No',
                            value: 'false',
                            id: 'sectionsWeightedNo',
                            name: 'sectionsWeighted',
                            checked: !documentState.details.sectionsWeighted,
                        }]}
                        renderAsRow={true} />}
                    <div className='formElementsDivider'></div>
                    {documentState.details.sectionsWeighted
                        && <>
                            <LabelledInput
                                id='minScore'
                                type={LabelledInputTypeNumber}
                                label='Min scoring value'
                                placeholder='Enter value'
                                value={documentState.details?.minScore === 0 ? '0' : documentState.details.minScore || ''}
                                onChange={(event) => handleDetailsInput(event)}
                                breakColumn={false}
                                required={true} />
                            <div className='formElementsDivider'></div>
                            <LabelledInput
                                id='maxScore'
                                type={LabelledInputTypeNumber}
                                label='Max scoring value'
                                placeholder='Enter value'
                                value={documentState.details?.maxScore === 0 ? '0' : documentState.details.maxScore || ''}
                                onChange={(event) => handleDetailsInput(event)}
                                breakColumn={false}
                                required={true} />
                            <div className='formElementsDivider'></div>
                            <LabelledRadioButton
                                id='showWeightings'
                                label='Show section weightings?'
                                breakColumn={false}
                                onChange={(event) => handleDetailsInput(event, true)}
                                options={[{
                                    label: 'Yes',
                                    value: 'true',
                                    id: 'showWeightingsYes',
                                    name: 'showWeightings',
                                    checked: documentState.details.showWeightings,
                                }, {
                                    label: 'No',
                                    value: 'false',
                                    id: 'showWeightingsNo',
                                    name: 'showWeightings',
                                    checked: !documentState.details.showWeightings,
                                }]}
                                renderAsRow={true} />
                            <div className='formElementsDivider'></div>
                        </>}
                    <LabelledRadioButton
                        id='shareQuestionnaire'
                        label='Share this questionnaire externally?'
                        breakColumn={false}
                        onChange={(event) => handleDetailsInput(event)}
                        options={[{
                            label: 'Yes',
                            value: 'true',
                            id: 'shareQuestionnaireYes',
                            name: 'shareQuestionnaire',
                            checked: documentState.details.shareQuestionnaire
                                ? documentState.details.shareQuestionnaire === 'true'
                                : false,
                        }, {
                            label: 'No',
                            value: 'false',
                            id: 'shareQuestionnaireNo',
                            name: 'shareQuestionnaire',
                            checked: documentState.details.shareQuestionnaire
                                ? documentState.details.shareQuestionnaire === 'false'
                                : false,
                        }]}
                        renderAsRow={true} />
                    <div className='formElementsDivider'></div>
                    {documentState.details.shareQuestionnaire
                        && documentState.details.shareQuestionnaire === 'true'
                        && <><LabelledRadioButton
                            id='shareQuestionnaireWithEveryone'
                            label='Share with'
                            breakColumn={false}
                            onChange={(event) => handleDetailsInput(event)}
                            options={[{
                                label: 'All member authorities',
                                value: 'true',
                                id: 'shareQuestionnaireWithEveryoneYes',
                                name: 'shareQuestionnaireWithEveryone',
                                checked: documentState.details.shareQuestionnaireWithEveryone
                                    ? documentState.details.shareQuestionnaireWithEveryone === 'true'
                                    : false,
                            }, {
                                label: 'Specific member authorities',
                                value: 'false',
                                id: 'shareQuestionnaireWithEveryoneNo',
                                name: 'shareQuestionnaireWithEveryone',
                                checked: documentState.details.shareQuestionnaireWithEveryone
                                    ? documentState.details.shareQuestionnaireWithEveryone === 'false'
                                    : false,
                            }]}
                            renderAsRow={true} />
                        <div className='formElementsDivider'></div>
                        </>
                    }

                    {documentState.details.shareQuestionnaire
                        && documentState.details.shareQuestionnaire === 'true'
                        && documentState.details.shareQuestionnaireWithEveryone
                        && documentState.details.shareQuestionnaireWithEveryone === 'false'
                        && memberAuthorities?.length > 0
                        && <><div className='labelled-select'>
                            <label className='form-label title selectSearchTitle'>Select the specific member authorities on Open</label>
                            <SelectSearch
                                id='assigned'
                                multiple={true}
                                printOptions={'on-focus'}
                                closeOnSelect={false}
                                options={memberAuthorities?.map((authority) => ({
                                    name: authority.label,
                                    value: authority.value,
                                }))}
                                value={documentState.details.sharedWith || []}
                                onChange={(selectedValues) => {
                                    setDocumentState({
                                        ...documentState,
                                        details: {
                                            ...documentState.details,
                                            sharedWith: selectedValues,
                                        },
                                    });
                                }}
                                placeholder='Select member authorities'
                                z-index='4'
                                emptyMessage='No member authorities available'
                                disabled={isViewMode()}
                            />
                        </div>
                        <div className='formElementsDivider'></div>
                        </>
                    }
                    {<EvaluationPanelRadioButtonGroup
                        questionnaireDetails = {documentState.details}
                        handleChange={handleDetailsInput}/>}
                </Form>;
            case 'workflow':
                return <Form
                    id={`${context.toLowerCase()}DetailsForm`}>
                    <LabelledSelect
                        id='route'
                        label='Select Route to Market'
                        breakColumn={true}
                        onChange={(event) => handleDetailsInput(event)}
                        value={documentState.details.route || routeToMarket || ''}
                        options={Constants.routeToMarket.map(
                            (option, idx) => generateDropdownOptions(
                                idx, option, 'route to market',
                            ),
                        )}
                        readonly={documentState?.details?.freezeRoute && true}
                        required={false} />
                    <LabelledInput
                        id='name'
                        type={LabelledInputTypeText}
                        label='Title'
                        placeholder='Enter title'
                        value={documentState.details.name || ''}
                        onChange={(event) => handleDetailsInput(event)}
                        breakColumn={false}
                        required={true} />
                    <div className='formElementsDivider'></div>
                    <LabelledTextArea
                        id='description'
                        label='Enter description'
                        onChange={(event) => handleDetailsInput(event)}
                        value={documentState.details.description || ''}
                        placeholder='Enter description'
                        maxCharCount={250}
                        currentCharCount={documentState.details.description
                            ? documentState.details.description.length : 0}
                        breakColumn={false}
                        required={true}
                    />
                    <div className='formElementsDivider'></div>
                    <LabelledRadioButton
                        id='shareWorkflow'
                        label='Share this workflow externally?'
                        breakColumn={false}
                        onChange={(event) => handleDetailsInput(event)}
                        options={[{
                            label: 'Yes',
                            value: 'true',
                            id: 'shareWorkflowYes',
                            name: 'shareWorkflow',
                            checked: documentState.details.shareWorkflow
                                ? documentState.details.shareWorkflow === 'true'
                                : false,
                        }, {
                            label: 'No',
                            value: 'false',
                            id: 'shareWorkflowNo',
                            name: 'shareWorkflow',
                            checked: documentState.details.shareWorkflow
                                ? documentState.details.shareWorkflow === 'false'
                                : false,
                        }]}
                        renderAsRow={true} />
                    <div className='formElementsDivider'></div>
                    {documentState.details.shareWorkflow
                        && documentState.details.shareWorkflow === 'true'
                        && <LabelledRadioButton
                            id='shareWorkflowWithEveryone'
                            label='Share with'
                            breakColumn={false}
                            onChange={(event) => handleDetailsInput(event)}
                            options={[{
                                label: 'All member authorities',
                                value: 'true',
                                id: 'shareWorkflowWithEveryoneYes',
                                name: 'shareWorkflowWithEveryone',
                                checked: documentState.details.shareWorkflowWithEveryone
                                    ? documentState.details.shareWorkflowWithEveryone === 'true'
                                    : false,
                            }, {
                                label: 'Specific member authorities',
                                value: 'false',
                                id: 'shareWorkfloweWithEveryoneNo',
                                name: 'shareWorkflowWithEveryone',
                                checked: documentState.details.shareWorkflowWithEveryone
                                    ? documentState.details.shareWorkflowWithEveryone === 'false'
                                    : false,
                            }]}
                            renderAsRow={true} />}
                    <div className='formElementsDivider'></div>
                    {/** TODO: replace with react-select-search after BE api ready */}
                    {documentState.details.shareWorkflow
                        && documentState.details.shareWorkflow === 'true'
                        && documentState.details.shareWorkflowWithEveryone
                        && documentState.details.shareWorkflowWithEveryone === 'false'
                        && memberAuthorities?.length > 0
                        && <><div id='roleAssignment'>
                            <label className='form-label title selectSearchTitle' id='selectedResponsibleRoles-input-label' htmlFor='selectedResponsibleRoles-input'>Select the specific member authorities on Open</label>
                            <SelectSearch
                                id='assigned'
                                closeOnSelect={false}
                                printOptions='on-focus'
                                options={memberAuthorities?.map((authority) => ({
                                    name: authority.label,
                                    value: authority.value,
                                }))}
                                multiple={true}
                                placeholder='Select member authorities'
                                emptyMessage='No member authorities available'
                                value={documentState.details.sharedWith}
                                onChange={(event, data) => setDocumentState(({
                                    ...documentState,
                                    details: {
                                        ...documentState.details,
                                        sharedWith: data.map((el) => el.value),
                                    },
                                }))}
                                disabled={isViewMode()}
                                z-index='999'
                            />
                        </div></>}
                    <div className='formElementsDivider'></div>
                </Form>;
            default:
                return <></>;
        }
    };

    const prettifyStringBool = (val) => {
        if (typeof (val) === 'string') {
            return val === 'true' ? 'Yes' : 'No';
        }
        return (val === true ? 'Yes' : 'No');
    };

    const getPrettyType = (_type) => {
        if (_type) return helperFunctions.camel2title(_type);
        return '';
    };

    const generateDetailsAccordionSectionDataForView = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                return <div
                    id={`${context.toLowerCase()}DetailsView`}>
                    <p className='title'>Type: <span className='caption'>{Constants.questionnaireTypes.filter(
                        (qType) => qType.value === documentState.details.templateType,
                    )[0]?.label || ''}</span></p>
                    <div className='formElementsDivider'></div>
                    <p className='title'>Are sections weighted?: <span className='caption'>{prettifyStringBool(documentState.details.sectionsWeighted) || ''}</span></p>
                    <div className='formElementsDivider'></div>
                    {documentState.details.sectionsWeighted
                        && <>
                            <p className='title'>Min scoring value: <span className='caption'>{documentState.details.minScore || '0'}</span></p>
                            <div className='formElementsDivider'></div>
                            <p className='title'>Max scoring value: <span className='caption'>{documentState.details.maxScore || '0'}</span></p>
                            <div className='formElementsDivider'></div>
                            <p className='title'>Show section weightings: <span className='caption'>{prettifyStringBool(documentState.details.showWeightings) || ''}</span></p>
                            <div className='formElementsDivider'></div>
                        </>}
                    <p className='title'>Questionnaire shared: <span className='caption'>{prettifyStringBool(documentState.details.shareQuestionnaire) || ''}</span></p>
                    <div className='formElementsDivider'></div>
                    <p className='title'>Questionnaire shared with: <span className='caption'>{(documentState.details.shareQuestionnaireWithEveryone === 'true')
                        ? 'Public' : (documentState.details.sharedWith
                            ? documentState.details.sharedWithName.join(', ') : 'Not shared externally')}</span>
                    </p>
                    <div className='formElementsDivider'></div>
                    {documentState.details.shareQuestionnaire === 'true'
                        && documentState.details.sharedBy
                        && <>
                            <p className='title'>Questionnaire shared by: <span className='caption'>
                                {documentState.details.sharedBy}</span></p>
                            <div className='formElementsDivider'></div>
                        </>}
                    {documentState.details.evaluationPanel
                        && <>
                            <p className='title'>{evaluationPanel}: <span className='caption'>
                                {helperFunctions.getEvaluationPanelLabel(documentState.details.evaluationPanel)}</span></p>
                            <div className='formElementsDivider'></div>
                        </>}
                </div>;
            case 'workflow':
                return <></>;
            default:
                return <></>;
        }
    };

    const getAccordionDetailsDataForDocumentMode = () => {
        switch (documentMode.toLowerCase()) {
            case 'add':
                return generateDetailsAccordionSectionData;
            case 'edit':
                return generateDetailsAccordionSectionData;
            case 'view':
                return generateDetailsAccordionSectionDataForView;
            default:
                // eslint-disable-next-line react/display-name
                return () => null;
        }
    };

    const getHeader = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                if (isAddorEditMode()) return context;
                if (isViewMode()) return 'Additional';
                break;
            case 'workflow':
                if (isAddorEditMode()) return context;
                break;
            default:
                return <></>;
        }
        return null;
    };

    const generateDetailsAccordionData = () => ([{
        sectionId: 'dbAccordionSection-0',
        header: <p className='title-xLarge'>{`${getHeader()} Details`}</p>,
        content: getAccordionDetailsDataForDocumentMode()(),
    }]);

    const handleSectionDetailsModalResult = (data, mode, sectionToUpdate) => {
        if (mode === 'Add') {
            setDocumentState({
                ...documentState,
                sections: [...documentState.sections, data],
            });
        } else {
            const updatedDocumentSections = [...documentState.sections];
            updatedDocumentSections[sectionToUpdate] = data;
            setDocumentState({
                ...documentState,
                sections: updatedDocumentSections,
            });
        }
        setShowSectionDetailsModal({
            show: false,
        });
    };

    const handleSectionItemModalResult = (e, data, mode, sectionToUpdate, sectionItemToUpdate) => {
        const allSections = [...documentState.sections];
        const updatedSection = { ...allSections[sectionToUpdate] };
        const sectionItems = [...(updatedSection.sectionItems ?? [])];

        if (mode === Constants.ADD_MODE) {
            sectionItems.push(data);
        } else {
            sectionItems[sectionItemToUpdate] = data;
        }

        updatedSection.sectionItems = sectionItems;
        allSections[sectionToUpdate] = updatedSection;

        setDocumentState({
            ...documentState,
            sections: allSections,
        });

        setShowSectionItemModal(!showSectionDetailsModal);
    };

    const generateSectionWeighting = (data) => {
        const score = data ? data
            .filter((section) => section.weightage)
            .reduce((sum, elem) => sum + parseInt(elem.weightage, 10), 0) : 0;
        // Originally for coloured warnings. Not currently in design.
        // const jsx = [];
        // switch (true) {
        //     case (score > 100):
        //         jsx.push(<p className='title-large value' id='invalid'>{`${score}/100`}</p>);
        //         break;
        //     case (score === 100):
        //         jsx.push(<p className='title-large value' id='valid'>{`${score}/100`}</p>);
        //         break;
        //     case (score < 100):
        //         jsx.push(<p className='title-large value'>{`${score}/100`}</p>);
        //         break;
        //     default:
        //         break;
        // }
        // return jsx;
        return <p className='title-large value'>{score}</p>;
    };

    const routeToSelectSection = () => {
        const modifiedDocumentState = {
            ...documentState,
            isALiveQuestionnaire: liveQuestionnaire,
        };
        dispatch(actions.setBuilderState(modifiedDocumentState));

        if (responseId) {
            history.push(`/sections/import/_/${templateId}/${projectId}/${eventId}/${responseId}`);
        } else if (templateId) {
            history.push(`/sections/import/_/${templateId}`);
        } else {
            history.push(`/sections/import/${templateType}`);
        }
    };

    const routeToSelectEvents = (stageNumber, stageName) => {
        dispatch(actions.setBuilderState(documentState));
        history.push(generatePath('/workflows/import/events/:stage/:stageName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?/:contractID?', {
            stage: `${stageNumber}`,
            stageName: stageName || '_',
            routeToMarketParam: routeToMarket || '_',
            workflowID: workflowID || '_',
            resourceName: resourceName || '_',
            projectID: projectId || '_',
            contractID: contractID || '_',
        }));
        // /workflows/import/events/:stage/:stageName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?
        // history.push(`/workflows/import/events/${stageNumber}/${stageName}/${routeToMarket || '_'}/${workflowID || '_'}/${resourceName || '_'}/${projectId || '_'}`);
        // if (workflowID) {
        //     history.push(`/workflows/import/events/${stageNumber}/${stageName}/_/${workflowID}`);
        // } else if (projectId) {
        //     history.push(`/workflows/import/events/${stageNumber}/${stageName}/${routeToMarket}/_/${resourceName}/${projectId}`);
        // } else {
        //     history.push(`/workflows/import/events/${stageNumber}/${stageName}/${routeToMarket}`);
        // }
    };

    const routeToSelectStages = () => {
        dispatch(actions.setBuilderState(documentState));
        history.push(generatePath('/workflows/import/stages/:workflowName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?/:contractID?', {
            workflowName: documentState.details.name || '_',
            routeToMarketParam: routeToMarket || '_',
            workflowID: workflowID || '_',
            resourceName: resourceName || '_',
            projectID: projectId || '_',
            contractID: contractID || '_',
        }));
        // '/workflows/import/stages/:workflowName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?'
        // history.push(`/workflows/import/stages/${documentState.details.name || '-'}/${routeToMarket || '_'}/${workflowID || '_'}/${resourceName || '_'}/${projectId || '_'}`);
        // if (id) {
        //     history.push(`/workflows/import/stages/${documentState.details.name || '-'}/_/${id}`);
        // } else if (projectId && routeToMarket) {
        //     history.push(`/workflows/import/stages/${documentState.details.name || '-'}/${routeToMarket}/_/${resourceName}/${projectId}`);
        // } else if (projectId) {
        //     history.push(`/workflows/import/stages/${documentState.details.name || '-'}/_/_/${resourceName}/${projectId}`);
        // } else {
        //     history.push(`/workflows/import/stages/${documentState.details.name || '-'}/${routeToMarket}`);
        // }
    };

    const generateOverviewSectionData = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                return <div id='overviewSectionWrapper'>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Number of Sections:</p>
                        <p className='title-large value'>{(documentState.sections) ? documentState.sections.length : 0}</p>
                    </div>
                    {/* <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Total Section Weightage:</p>
                        {generateSectionWeighting(documentState.sections)}
                    </div> */}
                    <div className='flexGrow'></div>
                    {isAddorEditMode()
                        && <div className='overviewButtonWrapper'>
                            <Button id={`add${sectionLabel}Btn`}
                                label={`Add ${sectionLabel}`}
                                variant='secondary'
                                additionalVariant='skinless'
                                icon={true}
                                iconSrc={addIcon}
                                handleClick={() => setShowSectionDetailsModal({
                                    show: !showSectionDetailsModal.show,
                                    mode: 'Add',
                                })} />
                            <Button id={`add${sectionLabel}Btn`}
                                label={`Import ${sectionLabel}(s)`}
                                variant='secondary'
                                additionalVariant='skinless import-stages-btn'
                                icon={true}
                                iconSrc={addIcon}
                                handleClick={() => routeToSelectSection()} />
                        </div>
                    }
                </div>;
            case 'workflow':
                return <div id='overviewSectionWrapper'>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Number of Stages:</p>
                        <p className='title-large value'>{(documentState.sections) ? documentState.sections.length : 0}</p>
                    </div>
                    {!isAddorEditMode() && <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Shared With:</p>
                        { // eslint-disable-next-line no-nested-ternary
                            <p className='title-large value'>{(documentState.details.shareWorkflowWithEveryone === 'true') ? 'Public' : (documentState.details.sharedWith ? documentState.details.sharedWithName.join(', ') : 'Not shared externally')}</p>
                        }
                    </div>}
                    <div className='flexGrow'></div>
                    {
                        /**
                         * The below render Add Stage button
                         */
                        isAddorEditMode() && <Button id={`add${sectionLabel}Btn`}
                            label={`Add ${sectionLabel}`}
                            variant='secondary'
                            additionalVariant='skinless import-stages-btn'
                            icon={true}
                            iconSrc={addIcon}
                            handleClick={() => setShowSectionDetailsModal({
                                show: !showSectionDetailsModal.show,
                                mode: 'Add',
                            })} />}
                    {
                        /**
                         * The below renders Import Stages button
                            */
                        (isAddorEditMode() && (context.toLowerCase() === 'workflow' || context.toLowerCase() === 'stage')) && <Button id={`add${sectionItemLabel}Btn`}
                            label={'Import Stage(s)'}
                            variant='secondary'
                            additionalVariant='skinless import-stages-btn'
                            icon={true}
                            iconSrc={addIcon}
                            handleClick={() => routeToSelectStages()} />}
                </div>;
            case 'stage':
                return <div id='overviewSectionWrapper'>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Number of Stages:</p>
                        <p className='title-large value'>{(documentState.sections) ? documentState.sections.length : 0}</p>
                    </div>
                    <div className='flexGrow'></div>
                    {isAddorEditMode() && documentState.sections.length === 0 ? <Button id={`add${sectionLabel}Btn`}
                        label={`Add ${sectionLabel}`}
                        variant='secondary'
                        additionalVariant='skinless'
                        icon={true}
                        iconSrc={addIcon}
                        handleClick={() => setShowSectionDetailsModal({
                            show: !showSectionDetailsModal.show,
                            mode: 'Add',
                        })} /> : <></>}
                </div>;
            case 'section':
                return <div id='overviewSectionWrapper'>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Number of Sections:</p>
                        <p className='title-large value'>{(documentState.sections) ? documentState.sections.length : 0}</p>
                    </div>
                    <div className='flexGrow'></div>
                    {isAddorEditMode() && documentState.sections.length === 0
                        ? <div className='overviewButtonWrapper'>
                            <Button id={`add${sectionLabel}Btn`}
                                label={`Add ${sectionLabel}`}
                                variant='secondary'
                                additionalVariant='skinless'
                                icon={true}
                                iconSrc={addIcon}
                                handleClick={() => setShowSectionDetailsModal({
                                    show: !showSectionDetailsModal.show,
                                    mode: 'Add',
                                })} />
                        </div>
                        : <></>}
                </div>;
            case 'event':
                return <div id='overviewSectionWrapper'>
                    <WorkflowEventModal
                        // interest={interest}
                        // accountId={authContext.user.accountId}
                        mode={'Create'}
                        existingData={documentState.sections}
                        initialData={{}}
                        closeModal={() => null}
                        handleResult={(e, eventDetails) => handlePrimaryToolbarBtnClick(
                            e, eventDetails,
                        )}
                    />
                </div>;
            default:
                return <></>;
        }
    };

    const getQuestionsWithAnswer = (sectionData) => {
        let answeredCount = 0;
        sectionData.map((sections) => {
            sections.sectionItems.map((questions) => {
                if (questions.questionAnswer) {
                    answeredCount += 1;
                } else if (questions?.responseType === 'file'
                    && questions?.supportingAnswerDocuments?.length > 0) {
                    answeredCount += 1;
                }
                return null;
            });
            return null;
        });
        return answeredCount;
    };

    const generateViewAnswerOverviewSectionData = () => <>
        <div id='answerOverviewSectionWrapper remove-on-print'>
            <div id='answerStats'>
                <div id='leftSideStat'>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Total number of sections:</p>
                        <p className='title-large value'><span>{(documentState.sections) ? documentState.sections.length : 0}</span></p>
                    </div>
                    <div className='overviewLabelValuePair'>
                        <p className='caption-large label'>Total number of questions:</p>
                        {<p className='title-large value'><span>{`${(documentState.sections)
                            ? documentState.sections
                                .filter((section) => section.sectionItems)
                                .reduce((sum, elem) => sum
                                    + elem.sectionItems.length, 0)
                            : 0}`}</span></p>}
                    </div>
                </div>
            </div>
        </div>
    </>;

    const generateEvaluationOverviewSectionData = () => <>
        <div id='evaluationOverviewSectionWrapper'>
            <div id='evaluationStats'>
                <div className='overviewLabelValuePair'>
                    <p className='caption-large label'>Total number of sections:</p>
                    <p className='title-large value'><span>{(documentState.sections) ? documentState.sections.length : 0}</span></p>
                </div>
                <div className='overviewLabelValuePair'>
                    <p className='caption-large label'>Total number of questions:</p>
                    {<p className='title-large value'><span>{`${(documentState.sections)
                        ? documentState.sections
                            .filter((section) => section.sectionItems)
                            .reduce((sum, elem) => sum
                                + elem.sectionItems.length, 0)
                        : 0}`}</span></p>}
                </div>
                <div className='overviewLabelValuePair'>
                    <p className='caption-large label'>Min scoring value: </p>
                    <p className='title-large value'><span>{documentState.details?.minScore || '0'}</span></p>
                </div>
                <div className='overviewLabelValuePair'>
                    <p className='caption-large label'>Max scoring value: </p>
                    <p className='title-large value'><span>{documentState.details?.maxScore || '0'}</span></p>
                </div>
                {isWeightageMode() && <div className='overviewLabelValuePair'>
                    <p className='caption-large label'>Total Weight:</p>
                    {generateSectionWeighting(documentState.sections)}
                </div>}
            </div>
        </div>
    </>;

    const generateAnswerOverviewSectionData = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                return <>
                    <div id='answerOverviewSectionWrapper'>
                        <div id='answerStats'>
                            <div id='leftSideStat'>
                                <div className='overviewLabelValuePair'>
                                    <p className='caption-large label'>Total number of sections:</p>
                                    <p className='title-large value'><span>{(documentState.sections) ? documentState.sections.length : 0}</span></p>
                                </div>
                                <div className='overviewLabelValuePair'>
                                    <p className='caption-large label'>Total number of questions:</p>
                                    {<p className='title-large value'><span>{`${(documentState.sections)
                                        ? documentState.sections
                                            .filter((section) => section.sectionItems)
                                            .reduce((sum, elem) => sum
                                                + elem.sectionItems.length, 0)
                                        : 0}`}</span>
                                    </p>}
                                </div>
                            </div>
                            <div>
                                <div className='overviewLabelValuePair'>
                                    <p className='caption-large label'>Total number of questions answered:</p>
                                    {<p className='title-large value'><span>{getQuestionsWithAnswer(documentState.sections)}</span></p>}
                                </div>
                                <div className='overviewLabelValuePair'>
                                    <p className='caption-large label'>Total number of questions pending:</p>
                                    <p className='title-large value'>
                                        <span className={(documentState.sections
                                            .filter((section) => section.sectionItems)
                                            .reduce(
                                                (sum, elem) => sum + elem.sectionItems.length, 0,
                                            ) - getQuestionsWithAnswer(documentState.sections) !== 0
                                            ? 'red'
                                            : '')}>
                                            {`${(documentState.sections)
                                                ? documentState.sections
                                                    .filter((section) => section.sectionItems)
                                                    .reduce((sum, elem) => sum
                                                        + elem.sectionItems.length, 0)
                                                - getQuestionsWithAnswer(documentState.sections)
                                                : 0}`}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='overviewLabelValuePair'>
                                <p className='caption-large label'>{Constants.TOTAL_NUMBER_OF_AMENDED_QUESTIONS}</p>
                                {<p className={'title-large value'}>
                                    <span className={numberOfAmendedQuestions > 0 ? 'red' : ''}>
                                        {numberOfAmendedQuestions}
                                    </span>
                                </p>}
                            </div>
                        </div>
                        <div className='flexGrow'></div>
                    </div>
                </>;
            case 'workflow':
                return <></>;
            default:
                return <></>;
        }
    };

    // TODO:: To check if we really need this function anymore.
    const removeReferenceFromLinkedEvents = (currentSectionNo, currentSectionItemNo) => {
        const sectionBeingRemoved = documentState.sections[currentSectionNo];

        const eventBeingRemoved = (sectionBeingRemoved.sectionItems
            && sectionBeingRemoved.sectionItems.length > 0)
            ? sectionBeingRemoved.sectionItems[currentSectionItemNo] : {};
        let updateDocumentState = false;
        for (let i = currentSectionNo; i < documentState.sections.length; i++) {
            const section = documentState.sections[i];
            const sectionEvents = section.sectionItems ? section.sectionItems : [];
            for (let j = 0; j < sectionEvents.length; j++) {
                const event = sectionEvents[j];
                if (eventBeingRemoved.eventType === 'questionnaire' && event && event.eventType === 'evaluation') {
                    event.questionnaires = event.questionnaires?.filter(
                        (questionnaire) => questionnaire !== eventBeingRemoved.name,
                    );
                    if (event.questionnaires?.length === 0) {
                        delete event.questionnaires;
                    }
                    updateDocumentState = true;
                }
                if (eventBeingRemoved.eventType === 'evaluation' && event && event.eventType === 'issueAwardUnsuccessfulNotices') {
                    event.evaluationEvents = event.evaluationEvents?.filter(
                        (evaluationEvent) => evaluationEvent !== eventBeingRemoved.name,
                    );
                    if (event.evaluationEvents?.length === 0) {
                        delete event.evaluationEvents;
                    }
                    updateDocumentState = true;
                }
            }
        }
        if (updateDocumentState) setDocumentState({ ...documentState });
    };

    const handleSectionWeightageInput = (event, sectionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        section.weightage = value;
        setDocumentState({ ...documentStateVal });
    };

    const generateQuestionWeighting = (sectionData) => {
        const score = sectionData ? sectionData.sectionItems
            .filter((question) => question.weightage)
            .reduce((sum, elem) => sum + parseInt(elem.weightage, 10), 0) : 0;
        const jsx = [];
        switch (true) {
            case (score > 100):
                jsx.push(<p className='title-large value' id='invalid'>{`Assigned Weight: ${score}/${sectionData.weightage || '0'}`}</p>);
                break;
            case (score === 100):
                jsx.push(<p className='title-large value' id='valid'>{`Assigned Weight: ${score}/${sectionData.weightage || '0'}`}</p>);
                break;
            case (score < 100):
                jsx.push(<p className='title-large value'>{`Assigned Weight: ${score}/${sectionData.weightage || '0'}`}</p>);
                break;
            default:
                break;
        }
        return jsx;
    };

    const handleChangeIndividualEvaluatorsFromSectionToQuestions = (data, sectionNumber) => {
        setDocumentState({
            ...documentState,
            sections: updateQuestionsWithIndividualEvaluatorsFromSection(data, sectionNumber, documentState),
        });
    };

    const generateContentSectionHeader = (sectionData, sectionNumber) => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
            case 'section':
                return <>
                    {isAddorEditMode() && <div className='headerToolbar'>
                        {showEvaluatorsDropdowns
                            && <EvaluatorDropdownSection
                                steeringInfo={steeringInfo}
                                handleChange={(data) => handleChangeIndividualEvaluatorsFromSectionToQuestions(data, sectionNumber)}
                                selectedEvaluators={getSectionIndividualEvaluators(sectionData)}
                                isViewMode={isViewMode()}
                            />
                        }
                        <Button id={`removeSectionBtn-${sectionNumber}`}
                            variant='primary red'
                            size='medium'
                            label={'Remove'}
                            handleClick={(e) => {
                                Confirm(() => {
                                    const updatedSections = [...documentState.sections];
                                    updatedSections.splice(parseInt(e.target.id.split('-')[1], 10), 1);
                                    setDocumentState({
                                        ...documentState,
                                        sections: updatedSections,
                                    });
                                }, {
                                    subTitle: `Are you sure you want to remove this ${sectionLabel.toLowerCase()}?`,
                                });
                            }} />
                        <Button id={`editSectionBtn-${sectionNumber}`}
                            label={'Edit'}
                            size='medium'
                            handleClick={(event) => setShowSectionDetailsModal({
                                show: !showSectionDetailsModal.show,
                                mode: 'Edit',
                                sectionNumber: event.target.id.split('-')[1],
                            })} />
                    </div>}
                    <div className='headerTextRowMain'>
                        <div className='headerTextRowMainInner' >
                            <div className='headerTextRow'>
                                <div className='headerTextRowWarpper'>
                                    <p className='title-large heading label'>
                                        {`${sectionNumber + 1}. ${sectionData.title}`}
                                    </p>
                                </div>
                                {!isWeightageMode() && sectionData.weightage
                                    && <div className='showSectionWeightage'>
                                        <p className='title label'>Section weighting:</p>
                                        <p className='caption value'>
                                            {`${sectionData.weightage}\u00A0/\u00A0100`}
                                        </p>
                                    </div>}
                            </div>
                            <div className='headerTextRow'>
                                <div className='headerTextRowWarpper'>
                                    {getDescriptionBlock(sectionData.description, 'section-description')}
                                </div>
                            </div>
                        </div>
                        {isWeightageMode()
                            && <div className='awTextRow'>
                                <LabelledInput
                                    id={'weightage'}
                                    type={LabelledInputTypeNumber}
                                    minNumber={1}
                                    readonly={false}
                                    required={true}
                                    label={'Section Weight:'}
                                    placeholder={'Enter Weight'}
                                    value={sectionData.weightage || ''}
                                    onChange={(e) => handleSectionWeightageInput(
                                        e, sectionNumber,
                                    )}
                                    breakColumn={true}
                                />
                            </div>}
                    </div>
                    {isWeightageMode()
                        && generateQuestionWeighting(sectionData)}
                    {(isAnswerMode() && !isViewAnswerMode())
                        && <div className='headerTextRow'>
                            <div className='headerTextRowWarpper'>
                                <p className='title label'>Number of questions answered:</p>
                                <p className='title value'>
                                    <span className={([{ ...sectionData }]
                                        .reduce((sum, elem) => sum
                                            + elem.sectionItems.length, 0) - getQuestionsWithAnswer([{ ...sectionData }]) !== 0 ? 'red' : '')}>
                                        {`${getQuestionsWithAnswer([{ ...sectionData }])}`}
                                    </span> / {[{ ...sectionData }]
                                        .reduce((sum, elem) => sum
                                            + elem.sectionItems.length, 0)}</p>
                            </div>
                        </div>}
                    {/* {!isAnswerMode() && <div className='headerTextRow'>
                        <div className='headerTextRowWarpper'>
                            <p className='title label'>Evaluator(s):</p>
                            {(sectionData.selectedEvaluators)
                                ? sectionData.selectedEvaluators.map(
                                    (selectedEvaluator, idx) => <p key={`selectedEvaluator-${idx}`}
                                    className='caption value'>{(selectedEvaluator
                                        .length > 1 && idx !== selectedEvaluator.length - 1)
                                        ? `${selectedEvaluator},\u00A0`
                                        : selectedEvaluator}
                                </p>)
                                : <p className='caption value'>No evaluator(s) selected</p>}
                        </div>
                    </div>} */}
                </>;
            case 'workflow':
            case 'stage':
                return <>
                    {isAddorEditMode() && <div className='headerToolbar'>
                        <Button id={`removeSectionBtn-${sectionNumber}`}
                            variant='primary red'
                            size='medium'
                            label={'Remove'}
                            handleClick={(e) => {
                                Confirm(() => {
                                    removeReferenceFromLinkedEvents(e.target.id.split('-')[1], 0);
                                    const updatedSections = [...documentState.sections];
                                    updatedSections.splice(parseInt(e.target.id.split('-')[1], 10), 1);
                                    setDocumentState({
                                        ...documentState,
                                        sections: updatedSections,
                                    });
                                }, {
                                    subTitle: `Are you sure you want to remove this ${sectionLabel.toLowerCase()}?`,
                                });
                            }}
                            disabled={sectionData?.sectionItems?.filter((event) => event.eventStatus && event.eventStatus !== 'pending').length > 0}
                        />
                        <Button id={`editSectionBtn-${sectionNumber}`}
                            label={'Edit'}
                            size='medium'
                            handleClick={(event) => setShowSectionDetailsModal({
                                show: !showSectionDetailsModal.show,
                                mode: 'Edit',
                                sectionNumber: event.target.id.split('-')[1],
                            })} />
                    </div>}
                    <div className='headerTextRow'>
                        <div className='headerTextRowWarpper'>
                            <p className='title-large label heading'>{`${sectionNumber + 1}.`}</p>
                            <p className='title-large value heading'>{sectionData.name}</p>
                        </div>
                    </div>
                    <div className='headerTextRow'>
                        <div className='headerTextRowWarpper'>
                            <p className='title label'>Stage category:</p>
                            <p className='caption value'>{helperFunctions.camel2title(sectionData.category)}</p>
                        </div>
                    </div>
                </>;
            default:
                return <></>;
        }
    };

    const orderSectionItems = (direction, sectionNumber, sectionItemNumber) => {
        sectionNumber = parseInt(sectionNumber, 10);
        sectionItemNumber = parseInt(sectionItemNumber, 10);
        const reorderedSectionsItems = [...documentState.sections[sectionNumber].sectionItems];
        const elementToMove = reorderedSectionsItems.splice(sectionItemNumber, 1)[0];
        if (direction === 'up') {
            reorderedSectionsItems.splice(sectionItemNumber - 1, 0, elementToMove);
        } else {
            reorderedSectionsItems.splice(sectionItemNumber + 1, -1, elementToMove);
        }

        const allSections = [...documentState.sections];
        let updatedSection = { ...allSections[sectionNumber] };
        updatedSection = {
            ...documentState.sections[sectionNumber],
            sectionItems: reorderedSectionsItems,
        };
        allSections[sectionNumber] = updatedSection;

        setDocumentState({
            ...documentState,
            sections: allSections,
        });
    };

    const getPrettySteeringGroupRole = (roleid) => allSteeringRoles
        .filter((steeringRole) => (steeringRole.role === roleid))[0]?.name;

    const generateSectionItemModal = (mode, sectionNumber, sectionItemNumber) => {
        const initialData = {};
        switch (context.toLowerCase()) {
            case 'questionnaire':
            case 'section':
                setSectionItemModal(<QuestionnaireQuestionModal
                    mode={mode}
                    sectionNumber={sectionNumber}
                    sectionItemNumber={(sectionItemNumber)
                        ? parseInt(sectionItemNumber, 10)
                        : 0}
                    initialData={(mode === 'Add')
                        ? initialData
                        : documentState.sections[sectionNumber]
                            .sectionItems[sectionItemNumber]}
                    closeModal={() => setShowSectionItemModal({
                        ...showSectionItemModal,
                        show: false,
                    })}
                    handleResult={handleSectionItemModalResult}
                    type={type}
                />);
                setShowSectionItemModal({
                    show: !showSectionItemModal.show,
                    mode,
                    sectionNumber,
                    sectionItemNumber,
                });
                break;
            case 'workflow':
            case 'stage':
                if (documentState.sections[sectionNumber].category
                    === stageCategories.contractManagement) {
                    initialData.stageCategory = stageCategories.contractManagement;
                }
                setSectionItemModal(<WorkflowEventModal
                    interest={interest}
                    accountId={authContext.user.accountId}
                    mode={mode}
                    existingData={documentState.sections}
                    sectionNumber={sectionNumber}
                    sectionItemNumber={(sectionItemNumber)
                        ? parseInt(sectionItemNumber, 10)
                        : 0}
                    initialData={(mode === 'Add')
                        ? initialData
                        : documentState.sections[sectionNumber]
                            .sectionItems[sectionItemNumber]}
                    closeModal={() => setShowSectionItemModal({
                        ...showSectionItemModal,
                        show: false,
                    })}
                    handleResult={handleSectionItemModalResult}
                />);
                setShowSectionItemModal({
                    show: !showSectionItemModal.show,
                    mode,
                    sectionNumber,
                    sectionItemNumber,
                });
                break;
            default:
        }
    };

    const generateWorkflowSectionItemRows = (sectionItemData) => {
        const eventFieldsObj = Constants
            .workflowEventsDisplayFields[sectionItemData.eventType] || {};
        const rowsHTML = [];
        let rowItemsHTML = [];
        Object.keys(eventFieldsObj).forEach((key, idx) => {
            let value;
            if (sectionItemData[key]) {
                if (typeof sectionItemData[key] === 'string') {
                    value = helperFunctions.camel2title(sectionItemData[key]);
                }

                if (typeof sectionItemData[key] === 'number') {
                    value = sectionItemData[key];
                }

                if (sectionItemData[key] === 'true' || sectionItemData[key] === 'false') {
                    value = prettifyStringBool(sectionItemData[key]);
                }

                if (sectionItemData[key] && sectionItemData[key].constructor === ([]).constructor) {
                    if (key === 'assigned' || key === 'steeringGroupAssigned') {
                        value = sectionItemData[key].map((item, index) => ((sectionItemData[key]
                            .length > 1 && index !== sectionItemData[key].length - 1
                            ? `${item.username}, \u00A0`
                            : item.username)));
                    } else {
                        value = sectionItemData[key].map((item, index) => ((sectionItemData[key]
                            .length > 1 && index !== sectionItemData[key].length - 1)
                            ? `${item},\u00A0`
                            : item));
                    }
                }

                if (key === 'rolesResponsible') {
                    value = getPrettySteeringGroupRole(sectionItemData[key]);
                }

                if (sectionItemData[key] && sectionItemData[key].constructor === ({}).constructor) {
                    const valuesToDisplay = [];
                    Object.keys(sectionItemData[key]).forEach((subKey) => {
                        if (sectionItemData[key][subKey]) {
                            valuesToDisplay.push(helperFunctions.camel2title(subKey));
                        }
                    });
                    value = valuesToDisplay.map((item, index) => ((valuesToDisplay
                        .length > 1 && index !== valuesToDisplay.length - 1)
                        ? `${item},\u00A0`
                        : item));
                }
                if (sectionItemData[key].length === 0) {
                    value = eventFieldsObj[key].noDataMessage;
                }
            } else {
                value = eventFieldsObj[key].noDataMessage;
            }

            rowItemsHTML.push(<div className='labelValueContainer'>
                <p className='title label'>{`${eventFieldsObj[key].label}:`}</p>
                <p className='body value'>{value}</p>
            </div>);

            if ((idx + 1) % 2 === 0) {
                rowsHTML.push(<div key={`${idx}-sectionItemTextRow`} className='sectionItemTextRow'>
                    {rowItemsHTML}
                </div>);
                rowItemsHTML = [];
            }
        });

        // Pending items to be added at the end
        if (rowItemsHTML.length > 0) {
            rowsHTML.push(<div key={'pending-sectionItemTextRow'} className='sectionItemTextRow'>
                {rowItemsHTML}
            </div>);
        }

        return rowsHTML;
    };

    const handleAnswerInput = (event, question, sectionNumber, questionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        questions.questionAnswer = value;
        setDocumentState({ ...documentStateVal });
    };

    const handleEvalCommentInput = (event, question, sectionNumber, questionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        questions.comments = value;
        setDocumentState({ ...documentStateVal });
    };

    const handleWeightageInput = (event, sectionNumber, questionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        questions.weightage = value;
        setDocumentState({ ...documentStateVal });
    };

    const handleUploadInput = (data, sectionNumber, questionNumber) => {
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];

        questions.supportingAnswerDocuments = mergeUploadedDocuments(questions.supportingAnswerDocuments, data,
            {}, authContext.user);

        setDocumentState({ ...documentState });
    };

    const handleAnswerCheckboxInput = (event, question, sectionNumber, questionNumber) => {
        const { value, checked } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        let answerArray = questions?.questionAnswer === '' || questions?.questionAnswer === undefined ? []
            : questions?.questionAnswer.split('#!#');
        if (checked) {
            if (!answerArray.includes(value)) {
                answerArray.push(value);
            }
        } else {
            answerArray = answerArray.filter((item) => item !== value);
        }
        questions.questionAnswer = answerArray.join('#!#');
        setDocumentState({ ...documentStateVal });
    };

    const uploadQuestionnaireFiles = async (data, sectionNumber, questionNumber) => {
        handleUploadInput(data, sectionNumber, questionNumber);
    };

    const deleteQuestionaireFiles = async (data, sectionNumber, questionNumber) => {
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];

        questions.supportingAnswerDocuments = cleanupDeletedDocument(questions.supportingAnswerDocuments, data);

        setDocumentState({ ...documentState });
    };
    const generateFilesList = (files, id = '', className = '') => {
        if (!files || files.length === 0) return (<></>);
        return (
            <ul className='files-list'>
                {files?.map((document, index) => (
                    <li key={`${id}-d-${index}`}>
                        <span className={className}>{document.filename}</span>
                    </li>
                ))}
            </ul>
        );
    };

    const displayUploadForQuestion = (question, sectionNumber, questionNumber, isReadOnly) => {
        if (!question?.supportingDocuments) {
            return <></>;
        }
        if (viewAnswerSO() === true && !question.supportingAnswerDocuments) {
            return <p className='body'>Supporting documents requested by buyer</p>;
        }
        return (<>
            <span className='title render-on-print'>{Constants.SUPPLIER_FILES_TITLE}</span>
            {generateFilesList(question.supportingAnswerDocuments, `at-${sectionNumber}-`, 'render-on-print')}
            <Upload
                title={'Attach supporting documents requested by the buyer'}
                className={'remove-on-print'}
                allowMultiple={true}
                uploadIdentifier={'document'}
                uploadCallback={uploadQuestionnaireFiles}
                deleteCallback={deleteQuestionaireFiles}
                sectionNumber={sectionNumber}
                questionNumber={questionNumber}
                questionnaire={true}
                uploadedDocuments={question.supportingAnswerDocuments}
                readOnly={isReadOnly}
            />
        </>
        );
    };

    const getAnswerJSXForQuestion = (question, sectionNumber, questionNumber) => {
        const isReadOnly = isViewAnswerMode();
        switch (question?.responseType?.toLowerCase()) {
            case 'yes/no':
                return <><LabelledRadioButton
                    id={`answerQuestion-${sectionNumber}-${questionNumber}`}
                    label='Response'
                    breakColumn={false}
                    onChange={(event) => handleAnswerInput(event, question, sectionNumber,
                        questionNumber)}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        disabled: isReadOnly,
                        id: `answerOptionYes-${sectionNumber}-${questionNumber}`,
                        name: `answerOption-${sectionNumber}-${questionNumber}`,
                        checked: question.questionAnswer
                            ? question.questionAnswer === 'true'
                            : false,
                        required: !question.responseOptional,
                    }, {
                        label: 'No',
                        value: 'false',
                        disabled: isReadOnly,
                        id: `answerOptionNo-${sectionNumber}-${questionNumber}`,
                        name: `answerOption-${sectionNumber}-${questionNumber}`,
                        checked: question.questionAnswer
                            ? question.questionAnswer === 'false'
                            : false,
                        required: !question.responseOptional,
                    }]}
                    renderAsRow={true}
                    required={!question.responseOptional} />
                {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            case 'radiobutton':
                return <><LabelledRadioButton
                    id={`answerQuestion-${sectionNumber}-${questionNumber}`}
                    label='Response'
                    breakColumn={false}
                    onChange={(event) => handleAnswerInput(event, question, sectionNumber,
                        questionNumber)}
                    options={
                        question.responseOptions.map((option) => ({
                            label: option,
                            value: option,
                            disabled: isReadOnly,
                            id: `answerOption${option}-${sectionNumber}-${questionNumber}`,
                            name: `answerOption-${sectionNumber}-${questionNumber}`,
                            checked: question.questionAnswer
                                ? question.questionAnswer === option
                                : false,
                            required: !question.responseOptional,
                        }))
                    }
                    renderAsRow={false}
                    required={!question.responseOptional} />
                {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            case 'checkbox':
                return <><LabelledCheckBox
                    id={`answerQuestion-${sectionNumber}-${questionNumber}`}
                    label='Response'
                    breakColumn={false}
                    onChange={(event) => handleAnswerCheckboxInput(event, question, sectionNumber,
                        questionNumber)}
                    options={
                        question.responseOptions.map((option) => ({
                            label: option,
                            value: option,
                            disabled: isReadOnly,
                            id: `answerOption${option}-${sectionNumber}-${questionNumber}`,
                            name: `answerOption-${sectionNumber}-${questionNumber}`,
                            checked: question?.questionAnswer && question.questionAnswer.split('#!#').filter((ans) => ans === option).length > 0,
                            required: !question.responseOptional ? question.questionAnswer === undefined || question.questionAnswer === ''
                                || question.questionAnswer.split('#!#').length === 0 : false,
                        }))
                    }
                    renderAsRow={false}
                    required={!question.responseOptional}
                />
                {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            case 'freetext':
                return <><LabelledTextArea
                    id='description'
                    label='Response:'
                    onChange={(event) => handleAnswerInput(event, question, sectionNumber,
                        questionNumber)}
                    value={question.questionAnswer || ''}
                    placeholder='Enter answer'
                    breakColumn={false}
                    readonly={isReadOnly}
                    required={!question.responseOptional}
                    maxCharCount={question.freeTextLength || 3000}
                />
                {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            case 'true/false':
                return <><LabelledRadioButton
                    id={`answerQuestion-${sectionNumber}-${questionNumber}`}
                    label='Response'
                    breakColumn={false}
                    onChange={(event) => handleAnswerInput(event, question, sectionNumber,
                        questionNumber)}
                    options={[{
                        label: 'True',
                        value: 'true',
                        disabled: isReadOnly,
                        id: `answerOptionTrue-${sectionNumber}-${questionNumber}`,
                        name: `answerOption-${sectionNumber}-${questionNumber}`,
                        checked: question.questionAnswer
                            ? question.questionAnswer === 'true'
                            : false,
                        required: !question.responseOptional,
                    }, {
                        label: 'False',
                        value: 'false',
                        disabled: isReadOnly,
                        id: `answerOptionFalse-${sectionNumber}-${questionNumber}`,
                        name: `answerOption-${sectionNumber}-${questionNumber}`,
                        checked: question.questionAnswer
                            ? question.questionAnswer === 'false'
                            : false,
                        required: !question.responseOptional,
                    }]}
                    renderAsRow={true}
                    required={!question.responseOptional} />
                {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            case 'file':
                return <>
                    {displayUploadForQuestion(question, sectionNumber, questionNumber, isReadOnly)}
                </>;
            default:
                return <></>;
        }
    };

    // Need clarification on where this is stored.
    const handleScoringInput = (event, question, sectionNumber, questionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        questions.scoring = value;
        setDocumentState({ ...documentStateVal });
    };

    const handlePseudoPass = (event, question, sectionNumber, questionNumber) => {
        const { value } = event.target;
        const documentStateVal = documentState;
        const section = documentState.sections[sectionNumber];
        const questions = section.sectionItems[questionNumber];
        questions.pseudoPassReason = value;
        setDocumentState({ ...documentStateVal });
    };

    const getScoreText = (score) => {
        if (score === passFailValues.pseudo) {
            return passFailLabels.provisional;
        }
        return helperFunctions.camel2title(score);
    };

    const getScoringJSXForQuestion = (question, sectionNumber,
        questionNumber, isIndividualEvaluator) => {
        // const isReadOnly = isViewEvaluateMode();
        const scoringMechanism = question.scoringMechanism?.toLowerCase();
        switch (true) {
            case (isEvaluateMode() && !isViewEvaluateMode() && scoringMechanism === passFailValues.scored):
                return <>
                    <div className='answerInputWrapper'>
                        <LabelledInput id='scoring'
                            type={LabelledInputTypeNumber}
                            label='Enter score: '
                            breakColumn={false}
                            onChange={(event) => handleScoringInput(
                                event, question, sectionNumber, questionNumber,
                            )}
                            value={question.scoring || ''}
                            placeholder='Enter Score'
                            required={true}
                        />
                    </div>
                    <div className='answerInputWrapper'>
                        <LabelledTextArea id='comments'
                            type={LabelledInputTypeText}
                            label='Evaluation Comment: '
                            breakColumn={true}
                            onChange={(event) => handleEvalCommentInput(
                                event, question, sectionNumber, questionNumber,
                            )}
                            value={question.comments || ''}
                            placeholder='Enter Comment'
                            showCharCount={true}
                            maxCharCount={Constants.COMMENTS_CHAR_LIMIT}
                            required={true}
                        />
                    </div>
                </>;
            case (isViewEvaluateMode()):
                return <div className='viewEvalContainer'>
                    {question.scoring && <div className='evalComments remove-on-print'>
                        <p className='title-large bold' id='viewEvalTitle'>Score: </p>
                        <p className='body evalComment'>{question.scoring ? getScoreText(question.scoring) : ''}</p>
                    </div>}
                    {!isIndividualEvaluator && question.scoringMechanism === passFailValues.scored && <div className='weightedScore remove-on-print'>
                        <p className='title-large bold' id='viewEvalTitle'>Weighted Score: </p>
                        <p className='body evalComment'>{question.weightedScoring ? question.weightedScoring : ''}</p>
                    </div>}
                    {question?.scoring && question?.scoring === passFailValues.pseudo
                        && <div className='evalComments remove-on-print'>
                            <p className='title-large bold' id='viewEvalTitle'>Reason: </p>
                            <p className='body evalComment'>{question.pseudoPassReason ? helperFunctions.camel2title(question.pseudoPassReason) : ''}</p>
                        </div>}
                    {question.comments
                        && <div className='evalComments remove-on-print'>
                            <p className='title-large bold' id='viewEvalTitle'>Comment:</p>
                            <p className='body evalComment'>{question.comments ? question.comments : ''}</p>
                        </div>}
                </div>;
            case (isEvaluateMode() && !isViewEvaluateMode() && scoringMechanism === passFailValues.passFailPseudo.toLowerCase()):
                return <>
                    <div className='answerInputWrapper'>
                        <LabelledRadioButton
                            id='scoring'
                            label='Enter Score:'
                            breakColumn={false}
                            onChange={(event) => handleScoringInput(
                                event, question, sectionNumber, questionNumber,
                            )}
                            options={[{
                                label: passFailLabels.pass,
                                value: passFailValues.pass,
                                required: true,
                                id: `evaluateOptionPass-${sectionNumber}-${questionNumber}`,
                                name: `evaluateOption-${sectionNumber}-${questionNumber}`,
                                checked: question.scoring
                                    ? question.scoring === passFailValues.pass
                                    : false,
                            }, {
                                label: passFailLabels.fail,
                                value: passFailValues.fail,
                                required: true,
                                id: `evaluateOptionFail-${sectionNumber}-${questionNumber}`,
                                name: `evaluateOption-${sectionNumber}-${questionNumber}`,
                                checked: question.scoring
                                    ? question.scoring === passFailValues.fail
                                    : false,
                            }, {
                                label: passFailLabels.provisional,
                                value: passFailValues.pseudo,
                                required: true,
                                id: `evaluateOptionPseudo-${sectionNumber}-${questionNumber}`,
                                name: `evaluateOption-${sectionNumber}-${questionNumber}`,
                                checked: question.scoring
                                    ? question.scoring === passFailValues.pseudo
                                    : false,
                            }]}
                            renderAsRow={true}
                            required={true}
                        />
                    </div>
                    {question.scoring === passFailValues.pseudo
                        && <div className='answerInputWrapper'>
                            <LabelledTextArea
                                id='pseudoPassReason'
                                label='Reason'
                                breakColumn={true}
                                onChange={(event) => handlePseudoPass(
                                    event, question, sectionNumber, questionNumber,
                                )}
                                value={question.pseudoPassReason}
                                placeholder='Enter Reason'
                                showCharCount={false}
                                required={true}
                            />
                        </div>
                    }
                    <div className='answerInputWrapper'>
                        <LabelledTextArea id='comments'
                            type={LabelledInputTypeText}
                            label='Evaluation Comment:'
                            breakColumn={true}
                            onChange={(event) => handleEvalCommentInput(
                                event, question, sectionNumber, questionNumber,
                            )}
                            value={question.comments || ''}
                            placeholder='Enter Comment'
                            showCharCount={true}
                            maxCharCount={Constants.COMMENTS_CHAR_LIMIT}
                            required={true}
                        />
                    </div>
                </>;
            case (isViewEvaluateMode() && scoringMechanism === passFailValues.passFailPseudo):
                return <>
                    <div className='answerInputWrapper'> <p className='title-large'>Score: {question.scoring ? getScoreText(question.scoring) : ''}</p> </div>
                    {question.scoring === passFailValues.pseudo
                        && <div className='answerInputWrapper'> <p className='caption'>Reason: {question.pseudoPassReason ? question.pseudoPassReason : ''}</p> </div>
                    }
                </>;
            case passFailValues.unscored:
                return <p className='title-large sectionItemHeading'>Scoring not needed.</p>;
            default:
                return <></>;
        }
    };

    const validateEditableEvent = (data) => {
        if (data?.eventType === workflowEvents.publish && data.eventStatus) {
            switch (data?.eventStatus) {
                case eventStatuses.published:
                case eventStatuses.completed:
                    return false;
                default:
                    return true;
            }
        }
        return true;
    };

    const getScoringMechanismText = (mechanism) => {
        if (mechanism === passFailValues.passFailPseudo) {
            return passFailLabels.passOrFail;
        }
        return helperFunctions.slashCamel2title(mechanism);
    };

    const generateSectionItems = (sectionItemData, sectionNumber, sectionItemNumber) => {
        const { sectionItems } = documentState.sections[sectionNumber];
        switch (context.toLowerCase()) {
            case 'questionnaire':
            case 'section':
                return <div
                    className={`sectionItemMainContentRow ${isAnswerMode() ? 'answerContent' : ''}`}
                    key={`sectionItemMainContentRow-${sectionItemNumber}`}>
                    {allowSectionItemsReordering && <div className='sectionItemReorderBtnsCol'>
                        <button className={`orderBtn up ${(sectionItemNumber === 0 || sectionItems.length === 1) ? 'disabled' : ''}`}
                            id={`orderUpBtn-${sectionNumber}-${sectionItemNumber}`}
                            disabled={(sectionItemNumber === 0 || sectionItems.length === 1)}
                            onClick={(event) => orderSectionItems('up', event.target.id.split('-')[1], event.target.id.split('-')[2])}>
                            <img src={chevronUpWhiteIcon} alt='move-up'></img>
                        </button>
                        <button className={`orderBtn down ${(sectionItemNumber + 1 === sectionItems.length || sectionItems.length === 1) ? 'disabled' : ''}`}
                            id={`orderDownBtn-${sectionNumber}-${sectionItemNumber}`}
                            disabled={(sectionItemNumber + 1 === sectionItems
                                .length || sectionItems.length === 1)}
                            onClick={(event) => orderSectionItems('down', event.target.id.split('-')[1], event.target.id.split('-')[2])}>
                            <img src={chevronDownWhiteIcon} alt='move-down'></img>
                        </button>
                    </div>}
                    <div className='sectionItemTextCol'>
                        <p className='title-large sectionItemHeading'>
                            {sectionItemData.questionTitle}
                            {(sectionItemData.responseOptional && sectionItemData.responseType?.toLowerCase() === Constants.RESP_TYPE.FILE)
                                ? <span className='render-on-print'>{` (${Constants.OPTIONAL})`}</span>
                                : null}
                            {(sectionItemData.isNewQuestion || sectionItemData.isUpdatedQuestion)
                                && <div className='question-updated-info'>
                                    <StatusLabel
                                        id={sectionItemData.id}
                                        color={STATUS_LABEL_VALUES.ACTION_NEEDED.color}
                                        labelTxt={STATUS_LABEL_VALUES.ACTION_NEEDED.label}
                                    />
                                    <div className='info-tooltip'>
                                        <img id={`${sectionItemData.id}-info-tooltip`} src={informationIcon}
                                            alt='info-tooltip' />
                                    </div>
                                </div>

                            }
                        </p>
                        {getDescriptionBlock(sectionItemData.description, 'question-description')}
                        {isAnswerMode()
                            && <>
                                {sectionItemData.attachments.length > 0
                                    ? <>
                                        <div className = 'view-attachments-popup remove-on-print'>
                                            <Upload
                                                title={`${Constants.ATTACHMENTS} * `}
                                                uploadedDocuments={sectionItemData.attachments}
                                                allowMultiple={true}
                                                questionnaire={true}
                                                readOnly={true}
                                            />
                                            <p className='additional-note'>{`* ${Constants.VIEW_ATTACHMENTS_TO_HELP_ANSWER_QUESTION}`}</p>
                                        </div>
                                        <span className='caption sectionItemDescription render-on-print'>{Constants.BUYER_FILES_TITLE}</span>
                                        {generateFilesList(sectionItemData.attachments, `at-${sectionNumber}-`, 'render-on-print')}
                                    </>
                                    : null}
                                <div className='answerInputWrapper'>
                                    <div className='answerInput'>
                                        {getAnswerJSXForQuestion(sectionItemData, sectionNumber,
                                            sectionItemNumber)}
                                    </div>
                                    <div className='flexGrow remove-on-print'></div>
                                    {sectionItemData.weightage
                                        ? <div className='labelValueContainer answerSectionWeightage'>
                                            <p className='title label'>Question weighting: <span className='caption value'>{sectionItemData.weightage}</span></p>
                                        </div> : ''}
                                </div>
                                <div>
                                    {getScoringJSXForQuestion(
                                        sectionItemData, sectionNumber, sectionItemNumber, documentState.isIndividualEvaluator,
                                    )}
                                    {rbac.util.isEvaluatorManager(authContext, steeringInfo)
                                        && sectionItemData.individualScorings?.length > 0
                                        && <IndividualEvaluatorsScores
                                            individualScorings={sectionItemData.individualScorings}
                                            questionID={sectionItemData.id}
                                        />
                                    }
                                </div>
                            </>}
                        {isWeightageMode()
                            && <><div className='awLabelContainer'>
                                <p className='title label'>Response type:&nbsp;</p>
                                <p className='body value'>{helperFunctions.camel2title(sectionItemData.responseType)}</p>
                            </div>
                            <div className='awLabelContainer'>
                                {sectionItemData.scoringMechanism === passFailValues.scored
                                    ? <LabelledInput
                                        id='weightage'
                                        label='Question Weight:'
                                        type={LabelledInputTypeNumber}
                                        onChange={(e) => handleWeightageInput(
                                            e, sectionNumber, sectionItemNumber,
                                        )}
                                        value={sectionItemData.weightage || ''}
                                        required={true}
                                        minNumber={1}
                                        placeholder='Enter Weight'
                                        breakColumn={false}
                                        readonly={false}
                                    />
                                    : <p className='body'>{`Scoring mechanism: ${getScoringMechanismText(sectionItemData.scoringMechanism)}`}</p>


                                }
                            </div>
                            </>}
                        {!isAnswerMode() && !isWeightageMode() && <>
                            <div className='sectionItemTextRow'>
                                <div className='labelValueContainer'>
                                    <p className='title label'>Scoring mechanism:</p>
                                    <p className='body value'>{getScoringMechanismText(sectionItemData.scoringMechanism)}</p>
                                </div>
                            </div>
                            {sectionItemData.weightage
                                ? <div className='sectionItemTextRow'>
                                    <div className='labelValueContainer'>
                                        <p className='title label'>Question weighting:</p>
                                        <p className='body value'>{sectionItemData.weightage}</p>
                                    </div>
                                </div>
                                : <></>
                            }
                            <div className='sectionItemTextRow'>
                                <div className='labelValueContainer'>
                                    <p className='title label'>Response type:</p>
                                    <p className='body value'>{helperFunctions.camel2title(sectionItemData.responseType)}</p>
                                </div>
                                {(sectionItemData.responseType === 'freeText')
                                    && <div className='labelValueContainer'>
                                        <p className='title label'>Character limit:</p>
                                        <p className='body value'>{sectionItemData.freeTextLength}</p>
                                    </div>}
                                {(sectionItemData.responseType === 'checkbox' || sectionItemData.responseType === 'radioButton')
                                    && <div className='labelValueContainer'>
                                        <p className='title label'>Response options:</p>
                                        {sectionItemData.responseOptions
                                            .map((responseOption, idx) => <p key={`responseType-${sectionNumber}-${sectionItemNumber}-${idx}`}
                                                className='body value'>{(sectionItemData.responseOptions.length > 1 && idx !== sectionItemData.responseOptions.length - 1)
                                                    ? `${responseOption},\u00A0`
                                                    : responseOption}
                                            </p>)}
                                    </div>}
                            </div>
                            <div className='sectionItemTextRow'>
                                <div className='labelValueContainer'>
                                    <p className='title label'>Uploads:</p>
                                    {documentMode === 'answer' && <p className='body value'>{sectionItemData.uploads.name}</p>}
                                    {documentMode !== 'answer' && <p className='body value'>{sectionItemData.attachments ? sectionItemData.attachments.length : '0'}</p>}
                                </div>
                                <div className='labelValueContainer'>
                                    <p className='title label'>Supporting documents with response:</p>
                                    <p className='body value'>{sectionItemData.supportingDocuments ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                            <div className='sectionItemTextRow'>
                                <div className='labelValueContainer'>
                                    <p className='title label'>Optional question:</p>
                                    <p className='body value'>{sectionItemData.responseOptional ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                        </>
                        }
                        {showEvaluatorsDropdowns
                            && <EvaluatorDropdownSection
                                steeringInfo={steeringInfo}
                                evalPanel={documentState.details.evaluationPanel}
                                handleChange={(data) => handleIndividualEvaluators(data, sectionNumber, sectionItemNumber)}
                                selectedEvaluators={sectionItemData.individualEvaluators}
                                isViewMode={isViewMode()}
                            />
                        }


                    </div>
                    {isAddorEditMode() && <div className='sectionItemBtnCol'>
                        <Button id={`viewSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            label={'View'}
                            size='small'
                            handleClick={(event) => generateSectionItemModal('View',
                                parseInt(event.target.id.split('-')[1], 10), parseInt(event.target.id.split('-')[2], 10))}
                        />
                        <Button id={`editSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            variant='secondary'
                            label={'Edit'}
                            size='small'
                            handleClick={(event) => generateSectionItemModal('Edit',
                                parseInt(event.target.id.split('-')[1], 10), parseInt(event.target.id.split('-')[2], 10))}
                        />
                        <Button id={`removeSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            variant='primary red'
                            size='small'
                            label={'Remove'}
                            handleClick={(e) => {
                                Confirm(() => {
                                    const updatedSectionsItems = [...documentState
                                        .sections[sectionNumber].sectionItems];
                                    updatedSectionsItems.splice(parseInt(e.target.id.split('-')[2], 10), 1);
                                    const allSections = [...documentState.sections];
                                    let updatedSection = { ...allSections[parseInt(e.target.id.split('-')[1], 10)] };
                                    updatedSection = {
                                        ...documentState.sections[sectionNumber],
                                        sectionItems: updatedSectionsItems,
                                    };
                                    allSections[sectionNumber] = updatedSection;

                                    setDocumentState({
                                        ...documentState,
                                        sections: allSections,
                                    });
                                }, {
                                    subTitle: `Are you sure you want to remove this ${sectionItemLabel.toLowerCase()}?`,
                                });
                            }} />
                    </div>}
                </div>;
            case 'workflow':
            case 'stage':
                return <div className='sectionItemMainContentRow'>
                    {allowSectionItemsReordering && <div className='sectionItemReorderBtnsCol'>
                        <button className={`orderBtn up ${(sectionItemNumber === 0 || sectionItems.length === 1) ? 'disabled' : ''}`}
                            id={`orderUpBtn-${sectionNumber}-${sectionItemNumber}`}
                            disabled={(sectionItemNumber === 0 || sectionItems.length === 1)}
                            onClick={(event) => orderSectionItems('up', event.target.id.split('-')[1], event.target.id.split('-')[2])}>
                            <img src={chevronUpWhiteIcon} alt='move-up'></img>
                        </button>
                        <button className={`orderBtn down ${(sectionItemNumber + 1 === sectionItems.length || sectionItems.length === 1) ? 'disabled' : ''}`}
                            id={`orderDownBtn-${sectionNumber}-${sectionItemNumber}`}
                            disabled={(sectionItemNumber + 1 === sectionItems
                                .length || sectionItems.length === 1)}
                            onClick={(event) => orderSectionItems('down', event.target.id.split('-')[1], event.target.id.split('-')[2])}>
                            <img src={chevronDownWhiteIcon} alt='move-down'></img>
                        </button>
                    </div>}
                    <div className='sectionItemTextCol'>
                        <p className='title-large sectionItemHeading'>{sectionItemData.eventName}</p>
                        <p className='caption sectionItemDescription'>{helperFunctions.simpleFormat(sectionItemData.description ? sectionItemData.description : '-', 'p', 'caption', true)}</p>
                        <p className='caption sectionItemDescription'>{getPrettyEventType(sectionItemData.eventType)}</p>
                        {generateWorkflowSectionItemRows(sectionItemData)}
                    </div>
                    {isAddorEditMode() && <div className='sectionItemBtnCol'>
                        <Button id={`viewSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            label={'View'}
                            size='small'
                            handleClick={(event) => generateSectionItemModal('View',
                                parseInt(event.target.id.split('-')[1], 10), parseInt(event.target.id.split('-')[2], 10))}
                        />
                        <Button id={`editSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            variant='secondary'
                            label={'Edit'}
                            size='small'
                            handleClick={(event) => generateSectionItemModal('Edit',
                                parseInt(event.target.id.split('-')[1], 10), parseInt(event.target.id.split('-')[2], 10))}
                            disabled={validateEditableEvent(sectionItemData) === false}
                        />
                        <Button id={`removeSectionItemBtn-${sectionNumber}-${sectionItemNumber}`}
                            variant='primary red'
                            size='small'
                            label={'Remove'}
                            handleClick={(e) => {
                                Confirm(() => {
                                    removeReferenceFromLinkedEvents(e.target.id.split('-')[1], e.target.id.split('-')[2]);
                                    const updatedSectionsItems = [...documentState
                                        .sections[sectionNumber].sectionItems];
                                    updatedSectionsItems.splice(parseInt(e.target.id.split('-')[2], 10), 1);
                                    const allSections = [...documentState.sections];
                                    let updatedSection = { ...allSections[parseInt(e.target.id.split('-')[1], 10)] };
                                    updatedSection = {
                                        ...documentState.sections[sectionNumber],
                                        sectionItems: updatedSectionsItems,
                                    };
                                    allSections[sectionNumber] = updatedSection;

                                    setDocumentState({
                                        ...documentState,
                                        sections: allSections,
                                    });
                                }, {
                                    subTitle: `Are you sure you want to remove this ${sectionItemLabel.toLowerCase()}?`,
                                });
                            }}
                            disabled={sectionItemData.eventStatus && sectionItemData.eventStatus !== 'pending'}
                        />
                    </div>}
                </div>;

            default:
                return <></>;
        }
    };

    const generateContentSectionMain = (sectionNumber) => {
        const elements = [];
        elements.push(<div className='setionItemMainToolbar' key={`setionItemMainToolbar-${sectionNumber}`}>
            <div className='setionItemMainToolbarValuePair'>
                {(!isAnswerMode && !isViewAnswerMode && context.toLowerCase() === 'questionnaire') && <>
                    <p className='title label'>Questions weighting:</p>
                    <p className='caption value'>{`${(documentState.sections[sectionNumber].sectionItems)
                        ? documentState.sections[sectionNumber].sectionItems
                            .filter((sectionItem) => sectionItem.questionWeightage)
                            .reduce((sum, elem) => sum + parseInt(elem.questionWeightage, 10), 0)
                        : 0} / 100`}</p>
                </>}
            </div>

            {
                /**
                 * The below render Add Events button
                 */
                isAddorEditMode() && <Button id={`add${sectionItemLabel}Btn`}
                    label={`Add ${sectionItemLabel}`}
                    variant='secondary'
                    additionalVariant='skinless'
                    icon={true}
                    iconSrc={addIcon}
                    handleClick={() => generateSectionItemModal('Add', sectionNumber, 0)} />}
            {
                /**
                 * The below renders Import Events button
                 */
                (isAddorEditMode() && (context.toLowerCase() === 'workflow')) && <Button id={`add${sectionItemLabel}Btn`}
                    label={'Import Event(s)'}
                    variant='secondary'
                    additionalVariant='skinless'
                    icon={true}
                    iconSrc={addIcon}
                    handleClick={() => routeToSelectEvents(sectionNumber, documentState
                        .sections[sectionNumber].name)} />}
        </div>);
        elements.push(
            <div
                className='sectionItemMainContent'
                key={`sectionMainContent-${sectionNumber}`}>
                {documentState.sections[sectionNumber].sectionItems
                    && documentState
                        .sections[sectionNumber].sectionItems
                        .map((item, idx) => generateSectionItems(item, sectionNumber, idx))}
                {/* {(isAnswerMode() && !isViewAnswerMode())
                && <Button id={`saveAnswerBtn-${sectionNumber}`}
                additionalVariant='saveSectionBtn'
                label={'Save Section'}
                size='medium'
                form='answerQuestionnaireForm'
                type='submit'
                handleClick={() => handlePrimaryToolbarBtnClick} />} */}
            </div>,
        );
        return elements;
    };

    const orderSections = (direction, sectionNumber) => {
        sectionNumber = parseInt(sectionNumber, 10);
        const reorderedSections = [...documentState.sections];
        const elementToMove = reorderedSections.splice(sectionNumber, 1)[0];
        if (direction === 'up') {
            reorderedSections.splice(sectionNumber - 1, 0, elementToMove);
        } else {
            reorderedSections.splice(sectionNumber + 1, -1, elementToMove);
        }

        setDocumentState({
            ...documentState,
            sections: reorderedSections,
        });
    };

    const validateWeightingScore = (data, filterItem, total, result = 'equal') => {
        const score = data ? data[filterItem]
            .filter((el) => el.weightage)
            .reduce((sum, elem) => sum + parseInt(elem.weightage, 10), 0) : 0;
        if (result === 'equal' && score === total) {
            return true;
        }
        if (result === 'limit' && score <= total) {
            return true;
        }
        return false;
    };

    const validateQuestions = (data, filterItem) => {
        let errorCheck = false;
        data[filterItem].forEach((el) => {
            if (el.scoringMechanism && el.scoringMechanism.toLowerCase() === passFailValues.scored) {
                if (!el.weightage || el.weightage === '' || el.weightage <= 0) {
                    Alert('Error', `${el.name} does not have a weighting assigned. Please assign a weighting`);
                    errorCheck = true;
                }
                return null;
            }
            return null;
        });
        return errorCheck;
    };

    const handleWeightingSubmit = async (e) => {
        e.preventDefault();
        Confirm((confirmAction) => {
            let errorCheck = false;
            for (let sec = 0; sec < documentState.sections?.length && !errorCheck; sec++) {
                // documentState.sections.forEach((section) => {
                const section = documentState.sections[sec];
                errorCheck = validateQuestions(section, Constants.sectionItems);
                const result = validateWeightingScore(section, Constants.sectionItems, parseInt(section.weightage, 10), Constants.equal);
                if (result === false) {
                    Alert(Constants.error, `${section.title} ${Constants.weightingTotalMisMatch}`);
                    errorCheck = true;
                    break;
                }
                // });
            }
            if (validateWeightingScore(documentState, Constants.sections, 100, Constants.limit) === false) {
                Alert(Constants.error, Constants.weightingOver100Error);
                errorCheck = true;
            }
            if (errorCheck) {
                return false;
            }
            if (confirmAction === undefined) {
                handlePrimaryToolbarSubmitButton(documentState);
            } else {
                handlePrimaryToolbarSubmitButton(
                    documentState,
                    confirmAction,
                );
            }
            return true;
        },
        isALiveQuestionnaire
            ? {
                confirmBtnText: Constants.YES,
                subTitle: Constants.liveQuestionnaireModel.subtitle,
                input: Constants.liveQuestionnaireModel.textarea,
                inputLabel: Constants.liveQuestionnaireModel.reason,
                inputPlaceholder: Constants.liveQuestionnaireModel.inputPlaceholder,
                inputValidator:
                        (value) => {
                            if (!value) {
                                return Constants.liveQuestionnaireModel.pleaseEnterReason;
                            }
                            return null;
                        },
                icon: Constants.liveQuestionnaireModel.question,
            }
            : { subTitle: Constants.liveQuestionnaireModel.weightingSubtitle });
    };

    const handleEvaluateSubmit = (e, submissionType) => {
        e.preventDefault();
        Confirm(() => {
            let checkScoringValue = false;

            if (submissionType === 'save' || submissionType === 'submit') {
                if (documentState && documentState.sections) {
                    documentState.sections.map((section) => {
                        if (section.sectionItems && !checkScoringValue) {
                            section.sectionItems.map((sectionItem) => {
                                if ((sectionItem.scoring > documentState.details.maxScore
                                    || sectionItem.scoring < documentState.details.minScore)
                                    && !checkScoringValue) {
                                    checkScoringValue = true;
                                    Alert('Error', 'Score value should be between Max scoring and Min scoring values.');
                                }
                                return null;
                            });
                        }
                        return null;
                    });
                }
            }
            if (checkScoringValue === false) {
                handlePrimaryToolbarSubmitButton(documentState, submissionType);
            }
        }, {
            subTitle: submissionType === 'save'
                ? 'Are you sure you want to save the evaluation of this questionnaire as a draft?'
                : 'Are you sure you want to complete and submit the evaluation of this questionnaire?',
        });
    };

    const handleWeightingCancel = (e) => {
        e.preventDefault();
        Confirm(() => {
            history.goBack();
        }, {
            subTitle: 'Are you sure you want to cancel assigning weighting to this questionnaire? All changes will be lost.',
        });
    };

    const handleEvaluateCancel = (e) => {
        e.preventDefault();
        Confirm(() => {
            history.goBack();
        }, {
            subTitle: 'Are you sure you want to cancel evaluation of this questionnaire? All changes will be lost.',
        });
    };

    const generateContentSections = () => {
        const { sections } = documentState;
        const sectionsHTML = [];
        if (sections?.length === 0 && rbac.util.isIndividualEvaluator(authContext, steeringInfo)) {
            return generateEmptyContentForIndividualEvaluator();
        }
        sections?.forEach((section, idx) => {
            const sectionHTML = [];
            if (allowSectionReordering) {
                sectionHTML.push(<div key={`sectionOrderBtnsContainer-${idx}`}
                    className='sectionOrderBtnsContainer'>
                    <button className={`orderBtn up ${(idx === 0 || sections.length === 1) ? 'disabled' : ''}`}
                        id={`orderUpBtn-${idx}`}
                        disabled={(idx === 0 || sections.length === 1)}
                        onClick={(event) => orderSections('up', event.target.id.split('-')[1])}>
                        <img src={chevronUpWhiteIcon} alt='move-up'></img>
                    </button>
                    <button className={`orderBtn down ${(idx + 1 === sections.length || sections.length === 1) ? 'disabled' : ''}`}
                        id={`orderDownBtn-${idx}`}
                        disabled={(idx + 1 === sections.length || sections.length === 1)}
                        onClick={(event) => orderSections('down', event.target.id.split('-')[1])}>
                        <img src={chevronDownWhiteIcon} alt='move-down'></img>
                    </button>
                </div>);
            }

            sectionHTML.push(<div key={`${context.toLowerCase()}-${sectionLabel}-${idx}`}
                className='contentSection'
                id={`${context.toLowerCase()}-${sectionLabel}-${idx}`}>
                <header className='contentSectionHeader'>
                    {generateContentSectionHeader(section, idx)}
                </header>
                <main className='contentSectionMain'>
                    {generateContentSectionMain(idx)}
                </main>
            </div>);

            sectionsHTML.push(<div className='contentSectionRow' key={`${context.toLowerCase()}-${sectionLabel}-main-${idx}`}>{sectionHTML}</div>);
        });

        return sectionsHTML;
    };

    const handleEditBtnClick = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                if (documentState.responseId) {
                    history.push(`/questionnaires/edit/${documentState.templateId}/${documentState.projectId}/${documentState.eventId}/false/${documentState.responseId}`);
                } else {
                    history.push(`/questionnaires/edit/${documentState.templateId}`);
                }
                break;
            case 'section':
                history.push(`/sections/edit/${documentState.sectionID}`);
                break;
            case 'workflow':
                history.push(`/workflows/edit/${documentState.templateId}`);
                break;
            case 'stage':
                history.push(`/stages/edit/${documentState.templateId}`);
                break;
            default:
                break;
        }
    };

    const generateDetailsAndOverviewSections = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
                return !isAnswerMode() && <>
                    {!isWeightageMode() && <>
                        <section className='documentSection' id='detailsSection'>
                            <Accordion id='documentDetailsAccordion'
                                data={generateDetailsAccordionData()}
                                open={true} />
                        </section>
                        <section className='documentSection remove-on-print' id='overviewSection'>
                            {generateOverviewSectionData()}
                        </section>
                    </>}
                </>;
            case 'workflow':
                return isViewMode()
                    ? <><section className='documentSection remove-on-print' id='overviewSection'>
                        {generateOverviewSectionData()}
                    </section></>
                    : <>
                        {attachedToProject === false && <section className='documentSection remove-on-print' id='detailsSection'>
                            <Accordion id='documentDetailsAccordion'
                                data={generateDetailsAccordionData()}
                                open={true} />
                        </section>}
                        <section className='documentSection remove-on-print' id='overviewSection'>
                            {generateOverviewSectionData()}
                        </section>
                    </>;
            case 'stage':
                return isViewMode()
                    ? <><section className='documentSection remove-on-print' id='overviewSection'>
                        {generateOverviewSectionData()}
                    </section></>
                    : <>
                        <section className='documentSection remove-on-print' id='overviewSection'>
                            {generateOverviewSectionData()}
                        </section>
                    </>;
            case 'section':
                return !isAnswerMode() && <>
                    <section className='documentSection remove-on-print' id='overviewSection'>
                        {generateOverviewSectionData()}
                    </section>
                </>;
            case 'event':
                return <>
                    <section className='documentSection remove-on-print' id='overviewSection'>
                        {generateOverviewSectionData()}
                    </section>
                </>;
            default:
                return <></>;
        }
    };

    const selectSection = () => {
        const currentState = documentBuilderState;
        if (!currentState.sections) {
            currentState.sections = [];
        }
        currentState.sections.push(documentState.sections[0]);
        dispatch(actions.setBuilderState(currentState));
        // history.go(-2);
        if (responseId) {
            // This is to prevent problems with history.go() on the next page.
            history.replace(`/questionnaires/edit/${importToQuesID}/${projectId}/${eventId}/imported/${responseId}`);
        } else if (importToQuesID && importToQuesID.length > 1) {
            history.replace(`/questionnaires/edit/${importToQuesID}/_/_/imported`);
        } else {
            history.replace(`/questionnaires/draft/${type}/_/_/imported`);
        }
    };

    const selectWorkflow = async () => {
        let responseData;
        if (accountId) {
            const payload = {
                workflowID,
                callOffMechanism,
            };
            responseData = await gatewayAPIs.assignWorkflowToAccount(payload);
        } else {
            responseData = await projectManagementAPIs.assignWorkflowToProject(
                projectId, workflowID,
            );
        }
        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Workflow selected successfully.',
            });
            if (accountId) {
                history.go(-2);
            } else {
                history.push(`/projects/overview/${projectId}`);
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to select workflow.',
            });
        }
    };

    const handleImport = () => {
        switch (context.toLowerCase()) {
            case ('section'):
                selectSection();
                break;
            case ('workflow'):
                selectWorkflow();
                break;
            default:
                break;
        }
    };

    const showChangeHistory = () => {
        setShowChangeHistoryModal({
            show: !showChangeHistoryModal.show,
        });
    };

    const handleHideViewer = () => {
        setShowDiffViewer(false);
        setValueDropdown(true);
    };

    useEffect(() => {
        if (structureToCompare) { setShowDiffViewer(true); }
    }, [structureToCompare]);

    return <>
        {isAddMode()
            && <section className='documentSection' id='toolbarSection'>
                <Button
                    id='secondaryToolbarBtn'
                    variant='secondary'
                    label={'Cancel'}
                    handleClick={handleSecondaryToolbarBtnClick}
                />
                {context.toLowerCase() === 'event'
                    ? <Button id='primaryToolbarBtn'
                        label={'Create'}
                        handleClick={() => null}
                        form={'workflowEventForm'}
                    />
                    : <>
                        {context.toLowerCase() === 'questionnaire'
                            && <Button
                                id='draftToolbarBtn'
                                label={'Save as Draft'}
                                handleClick={(e) => handlePrimaryToolbarBtnClick(e)}
                            />}
                        <Button
                            id='primaryToolbarBtn'
                            label={'Create'}
                            handleClick={(e) => handlePrimaryToolbarBtnClick(e)}
                            disabled={
                                (documentState.sections.length === 0
                                    || documentState.sections.filter((section) => (
                                        section.sectionItems
                                            ? section.sectionItems.length !== 0
                                            : false)).length === 0)
                                || (context.toLowerCase() === 'questionnaire' && (documentState?.details?.templateName === undefined || documentState?.details?.templateName?.length === 0))
                                || (context.toLowerCase() === 'workflow' && (documentState.details.name === undefined || documentState.details.name?.length === 0))}
                        />
                    </>
                }
            </section>}

        {isEditMode()
            && <section className='documentSection' id='toolbarSection'>
                {(context.toLowerCase() === 'workflow' && (!projectId || projectId === '_'))
                    && <div id='disableSection'>
                        <Button
                            id={'disableToolbarBtn'}
                            variant={`primary ${documentState.decision === 'enabled' ? 'red' : ''}`}
                            label={documentState.decision === 'enabled' ? 'Disable' : 'Enable'}
                            handleClick={() => handleEnableDisableBtnClick(documentState.decision !== 'enabled')}
                        />
                    </div>}
                {(context.toLowerCase() === 'questionnaire' && !responseId)
                    && <div id='disableSection'>
                        <Button
                            id={'disableToolbarBtn'}
                            variant={`primary ${documentState.details.isDisabled ? '' : 'red'}`}
                            label={documentState.details.isDisabled ? 'Enable' : 'Disable'}
                            handleClick={() => handleEnableDisableBtnClick(
                                !documentState.details.isDisabled,
                            )}
                        />
                    </div>}
                {context.toLowerCase() === 'questionnaire'
                    && documentState.details.templateStatus === 'draft'
                    && <StatusLabel
                        id='draftedQuestionnaire'
                        color='red'
                        labelTxt='Draft'
                    />
                }
                <Button
                    id='secondaryToolbarBtn'
                    variant='secondary'
                    label={'Cancel'}
                    handleClick={() => (isImport && isImport.length > 1
                        ? history.go(-3)
                        : history.goBack())}
                />
                {context.toLowerCase() === 'questionnaire'
                    && !responseId && (!projectId || projectId === '_')
                    && <Button
                        id='draftToolbarBtn'
                        label={'Save as Draft'}
                        handleClick={(e) => handlePrimaryToolbarBtnClick(e)}
                    />}
                <Button id='primaryToolbarBtn'
                    label={'Update'}
                    disabled = {updateButtonIsDisabledInQuestionnaires(context, initialDocumentState, documentState, documentBuilderState)}
                    handleClick={(e) => handlePrimaryToolbarBtnClick(e)}
                />
            </section>}

        {isViewMode() && !isAnswerMode()
            && <>


                <section className='view' id='viewHeaderSection'>
                    <div id='viewHeaderSectionDetails'>
                        <p className='title-xLarge viewHeading'>{documentState.details.templateName || documentState.details.name}</p>
                        <p className='caption'>{getPrettyType(documentState.details.templateType)}</p>
                        {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                        {(documentState.versionOf || documentState?.versions?.length > 0)
                            && <p className='title'>Version: {documentState.versionsNo ? documentState.versionsNo + 1 : 1}</p>}
                        {(documentState.versionOf && documentState.versionReason)
                            && <p className='title'>Change description: {documentState.versionReason}</p>}
                        {documentState.versions && !documentState.versionOf
                            && <Button
                                id='changeHistoryBtn'
                                variant='secondary'
                                additionalVariant='skinless'
                                icon={true}
                                iconSrc={editIcon}
                                label={'Change history'}
                                handleClick={() => showChangeHistory()} />
                        }
                    </div>
                    <div className='flexGrow'></div>
                    {type === 'import'
                        ? <Button id='Import'
                            variant='primary'
                            // additionalVariant='skinless'
                            // icon={true}
                            // iconSrc={editIcon}
                            label={context.toLowerCase() === 'section' ? 'Import' : 'Select Workflow'}
                            handleClick={() => handleImport()} />
                        : <div id='viewButtonContainer'>
                            <Button id={'backBtn'}
                                variant='secondary'
                                size='medium'
                                label={'Back'}
                                handleClick={() => history.goBack()}
                            />
                            {!isSupplierRole(authContext.user?.accountType)
                                && roleCheck() && !documentState.versionOf
                                && type !== 'defaultSelectionQuestionnaire'
                                && !location?.state?.deadlinePassed
                                && <Button id='editDocumentBtn'
                                    variant='secondary'
                                    additionalVariant='skinless'
                                    icon={true}
                                    iconSrc={editIcon}
                                    label={`Edit ${context}`}
                                    handleClick={() => handleEditBtnClick()}
                                />}
                            {!isSupplierRole(authContext.user?.accountType)
                                && showVersionDropdown
                                && haveVersions(documentState?.details?.previousVersions, Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE)
                                && <div className='versions-selector'>
                                    <VersionSelector
                                        id={`${templateId}-version-selector`}
                                        options={getVersionsStructure(documentState.details.previousVersions)}
                                        handleShowDiffs={handleShowDiffsInViewer}
                                        value={valueDropwdown}
                                        placeholder={Constants.COMPARE_WITH_PREVIOUS_VERSION}
                                        clearDropdown={valueDropwdown}
                                        type={Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE}
                                    />
                                </div>
                            }
                        </div>
                    }
                </section>

                {showDiffViewer
                    && <Viewer
                        structure={structureToCompare}
                        handleHideViewer={handleHideViewer}
                    />

                }

            </>
        }

        {(isAnswerMode() && !isViewAnswerMode())
            && <>
                <section className='documentSection' id='toolbarSectionSQ'>
                    <div className={haveQuestionnairesAndResponsesPreviousVersions(
                        authContext.user?.accountType,
                        documentState?.details?.previousVersions,
                    ) ? 'bigHeight' : 'smallHeight'}>
                        <div className='questionnaireChangesBtns'>
                            {showDiffViewerDropdown(
                                authContext.user?.accountType,
                                showVersionDropdown,
                                documentState?.details?.previousVersions,
                                Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE,
                            )
                                && <Button
                                    id={'viewSubmissionBtn'}
                                    type='button'
                                    size='x-large'
                                    variant='primary'
                                    additionalVariant='btnSize'
                                    label={Constants.VIEW_BUYER_CHANGES}
                                    handleClick={() => history.push(`/questionnaires/answer/view/${responseId}`)}
                                />
                            }

                            {showDiffViewerDropdown(
                                authContext.user?.accountType,
                                showVersionDropdown,
                                documentState?.details?.previousVersions,
                            ) && <div className='versions-selector'>
                                <VersionSelector
                                    id={`${responseId}-version-selector`}
                                    options={getVersionsStructure(documentState.details.previousVersions)}
                                    handleShowDiffs={handleShowDiffsInViewer}
                                    value={valueDropwdown}
                                    placeholder={Constants.COMPARE_WITH_PREVIOUS_VERSION}
                                    clearDropdown={valueDropwdown}
                                    type={Constants.PREVIOUS_VERSIONS_RESPONSE_TYPE}
                                />
                            </div>
                            }
                        </div>
                        <div className='buttonsSQ'>
                            <Button id={'cancelAnswerBtn'}
                                variant='primary skinless white'
                                size='medium'
                                label={'Cancel'}
                                // Go back to answerSummary Page.
                                handleClick={() => history.goBack()} />

                            {!isAnswerSQMode()
                                && <Button id={'saveBtn'}
                                    label={'Save'}
                                    size='medium'
                                    form='answerQuestionnaireForm'
                                    type='submit'
                                    handleClick={(e) => handleSaveAnswer(e)} />
                            }
                            <Button id={'submitBtn'}
                                label={'Complete'}
                                size='medium'
                                form='answerQuestionnaireForm'
                                type='submit'
                                handleClick={() => null} />
                        </div>
                    </div>

                </section>
                <section className='view' id='viewHeaderSectionSQ'>
                    <p className='title-xLarge viewHeading'>{documentState.details.templateName}</p>
                    <p className='caption'>{getPrettyType(documentState.details.templateType)}</p>
                    <div id='viewHeaderSectionDetails'>
                        {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                        {documentState.responseversions && !documentState.versionOf
                            && <Button id='editDocumentBtn'
                                variant='secondary'
                                additionalVariant='skinless'
                                icon={true}
                                iconSrc={editIcon}
                                label={'Refresh history'}
                                handleClick={() => showChangeHistory()} />
                        }
                    </div>
                </section>

                {showDiffViewer
                    && <Viewer
                        structure={structureToCompare}
                        handleHideViewer={handleHideViewer}
                    />
                }
            </>
        }

        {isViewAnswerMode()
            && !isEvaluateMode()
            && !isViewEvaluateMode()
            && <>
                <section className='documentSection' id='toolbarSectionSQ'>
                    <div></div>
                    <div className='buttonsSQ'>
                        {showDiffViewerDropdown(
                            authContext.user?.accountType,
                            showVersionDropdown,
                            documentState?.details?.previousVersions,
                            Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE,
                        ) && <div className='versions-selector'>
                            <VersionSelector
                                id={`${responseId}-version-selector`}
                                options={getVersionsStructure(documentState.details.previousVersions)}
                                handleShowDiffs={handleShowDiffsInViewer}
                                value={valueDropwdown}
                                placeholder={Constants.COMPARE_WITH_PREVIOUS_VERSION}
                                clearDropdown={valueDropwdown}
                                type={Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE}
                            />
                        </div>
                        }
                        <Button
                            id={'backBtn'}
                            label={'Back'}
                            variant={'primary skinless white'}
                            size='medium'
                            handleClick={() => history.goBack()}
                        />
                        {!documentState.versionOf
                                && !documentState.isPreviousSubmission
                                && (isAnswerSQMode()
                                    && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                        [authContext.user.accountType.toLowerCase() + authContext.user.role]))
                                && <Button
                                    id={'answerBtn'}
                                    label={'Answer'}
                                    size='medium'
                                    handleClick={(e) => handleStartAnswer(e)}
                                />}
                        {!documentState.versionOf
                                && !documentState.isPreviousSubmission
                                && !isAnswerSQMode()
                                && !location?.state?.deadlinePassed
                                && !isViewAnswerSQMode()
                                && !isBidSubmitted(documentMode, documentState)
                                && <Button
                                    id={'answerBtn'}
                                    label={'Answer'}
                                    size='medium'
                                    handleClick={(e) => handleStartAnswer(e)}
                                />}
                        {!documentState.versionOf
                                && !documentState.isPreviousSubmission
                                && !isAnswerSQMode()
                                && !location?.state?.deadlinePassed
                                && isViewAnswerSQMode()
                                && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                    [authContext.user.accountType.toLowerCase() + authContext.user.role])
                                && <Button
                                    id={'answerBtn'}
                                    label={'Answer'}
                                    size='medium'
                                    handleClick={(e) => handleStartAnswer(e)}
                                />}
                    </div>
                </section>
                <section className='view' id='viewHeaderSectionSQ'>
                    <div id='viewHeaderSectionDetailsSQ' >
                        <p className='title-xLarge viewHeading'>{documentState.details.templateName}</p>
                    </div>
                    <p className='caption'>{getPrettyType(documentState.details.templateType)}</p>
                    <div>
                        {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                        {(documentState.versionOf && documentState.versionReason)
                            && <p className='title'>Change description: {documentState.versionReason}</p>}
                    </div>

                </section>
                {showDiffViewer
                    && <Viewer
                        structure={structureToCompare}
                        handleHideViewer={handleHideViewer}
                    />
                }
            </>
        }
        {isEvaluateMode()
            && <>
                <section className='documentSection' id='toolbarSectionSQ'>
                    <div id='evaluationStatusLabel'>
                        <StatusLabel
                            id='underEvaluationStatus'
                            color='red'
                            labelTxt='Under Evaluation'
                        />
                    </div>
                    <div className='buttonsSQ'>


                        <Button id={'cancelEvaluationBtn'}
                            variant='primary skinless white'
                            label={'Cancel'}
                            size='medium'
                            handleClick={(e) => handleEvaluateCancel(e)} />
                        <Button id={'saveBtn'}
                            label={'Save'}
                            size='medium'
                            variant='primary skinless white'
                            form='answerQuestionnaireForm'
                            handleClick={(e) => handleEvaluateSubmit(e, 'save')} />
                        <Button id={'submitBtn'}
                            label={'Submit'}
                            size='medium'
                            form='answerQuestionnaireForm'
                            type='submit'
                            handleClick={() => null} />
                    </div>
                </section>
                <section className='view' id='viewHeaderSection'>
                    <div id='viewHeaderSectionDetails'>
                        <p className='title-xLarge viewHeading'>{documentState.details.templateName}</p>
                        <p className='caption'>{getPrettyType(documentState.details.templateType)}</p>
                        {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                    </div>
                    <div className='flexGrow'></div>
                </section>
            </>}

        {isWeightageMode()
            && <section className='view' id='viewHeaderSection'>
                <div id='viewHeaderSectionDetails'>
                    <p className='title-xLarge viewHeading'>{documentState.details.templateName}</p>
                    <p className='caption'>{getPrettyType(documentState.details.templateType)}</p>
                    {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                </div>
                <div className='flexGrow'></div>
                <Button id={'cancelWeightageBtn'}
                    variant='secondary'
                    label={'Cancel'}
                    size='medium'
                    handleClick={(e) => handleWeightingCancel(e)} />
                <Button id={'completeWeightageBtn'}
                    label={'Submit'}
                    size='medium'
                    form='answerQuestionnaireForm'
                    type='submit'
                    handleClick={(e) => handleWeightingSubmit(e, 'submit')} />
            </section>}

        {isViewEvaluateMode()
            && <section className='view' id='viewHeaderSection'>
                <div id='viewHeaderSectionDetails'>
                    {documentState?.projectInfo?.title
                        ? <p className='title title-xLarge viewHeading title-information render-on-print'>{documentState.projectInfo.title} ({documentState.projectInfo.reference})</p>
                        : ''
                    }
                    {documentState?.supplierInfo?.companyName
                        ? <p className='title title-xLarge viewHeading title-information render-on-print'>{documentState.supplierInfo.companyName}</p>
                        : ''
                    }
                    <p className='title-xLarge viewHeading'>
                        <span>{documentState.details.templateName}</span>
                    </p>

                    <p className='caption remove-on-print'>{getPrettyType(documentState.details.templateType)}</p>
                    {getDescriptionBlock(documentState.details.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION)}
                </div>
                <div className='flexGrow'></div>
                <Button
                    id={'backBtn'}
                    label={'Back'}
                    variant={'primary skinless white'}
                    size='medium'
                    handleClick={() => history.goBack()}
                    className='remove-on-print'
                />
            </section>}
        {withAutosave
        && <Autosave data={documentState} onSave={handleAutoSaveData} saveOnUnmount={false} interval={Constants.THIRTY_SECONDS_INTERVAL} />}

        {/* { !isAnswerMode() && <><section className='documentSection' id='detailsSection'>
            <Accordion id='documentDetailsAccordion'
                data={generateDetailsAccordionData()} />
        </section>

        <section className='documentSection' id='overviewSection'>
            {generateOverviewSectionData()}
        </section></>
        }

        {(context.toLowerCase() === 'workflow' && isViewMode())
            ? <></>
            : <section className='documentSection' id='detailsSection'>
                <Accordion id='documentDetailsAccordion'
                    data={generateDetailsAccordionData()} />
            </section>
        } */}

        {!showDiffViewer
            && generateDetailsAndOverviewSections()}

        {((isEvaluateMode() || isViewEvaluateMode() || isWeightageMode()) && !showDiffViewer) && <>
            <section className='documentSection remove-on-print' id='overviewSection'>
                {generateEvaluationOverviewSectionData()}
            </section>
        </>}

        {(isAnswerMode()
            && !isViewAnswerMode()
            && !isEvaluateMode()
            && !isViewEvaluateMode()
            && !isAnswerSQMode()
            && !showDiffViewer)
            && <>
                <section className='documentSection remove-on-print' id='overviewSection'>
                    {generateAnswerOverviewSectionData()}
                </section></>}

        {(isViewAnswerMode()
            && !isEvaluateMode()
            && !isViewEvaluateMode()
            && !isViewAnswerSQMode()
            && !showDiffViewer)
            && <>
                <section className='documentSection remove-on-print' id='overviewSection'>
                    {generateViewAnswerOverviewSectionData()}
                </section></>}

        <section className='documentSection' id='contentSections'>
            {isAnswerMode() && !showDiffViewer
                ? <Form id={'answerQuestionnaireForm'} onSubmit={(e) => (isEvaluateMode()
                    ? handleEvaluateSubmit(e, 'submit')
                    : handleResponseSubmit(e))} >
                    {generateContentSections()}
                </Form>
                : !showDiffViewer && generateContentSections()}
        </section>

        {showSectionDetailsModal.show && <SectionDetailsModal
            mode={showSectionDetailsModal.mode}
            context={context}
            sectionLabel={sectionLabel}
            sectionNumber={(showSectionDetailsModal.sectionNumber)
                ? parseInt(showSectionDetailsModal.sectionNumber, 10)
                : 0}
            initialData={(showSectionDetailsModal.mode === 'Add')
                ? {}
                : documentState.sections[showSectionDetailsModal.sectionNumber]}
            closeModal={() => setShowSectionDetailsModal({
                ...showSectionDetailsModal,
                show: false,
            })}
            evaluators={sectionDetailsUserList}
            handleResult={handleSectionDetailsModalResult}
        />}

        {showChangeHistoryModal.show && <VersionHistoryModal
            versions={documentState.versions}
            responseversions={documentState.responseversions}
            accountType={authContext.user.accountType}
            closeModal={() => setShowChangeHistoryModal({
                ...showChangeHistoryModal,
                show: false,
            })}
        />}
        {showSectionItemModal.show && sectionItemModal}
    </>;
};

DocumentBuilder.propTypes = {
    interest: PropTypes.array,
    documentMode: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    type: PropTypes.string,
    initialDocumentState: PropTypes.object.isRequired,
    sectionLabel: PropTypes.string.isRequired,
    sectionItemLabel: PropTypes.string.isRequired,
    allowSectionReordering: PropTypes.bool,
    allowSectionItemsReordering: PropTypes.bool,
    attachedToProject: PropTypes.bool,
    sectionDetailsUserList: PropTypes.array,
    handlePrimaryToolbarSubmitButton: PropTypes.func,
    handleEnableDisableBtnClick: PropTypes.func,
    memberAuthorities: PropTypes.array,
    isALiveQuestionnaire: PropTypes.bool,
    showVersionDropdown: PropTypes.bool,
    versionsList: PropTypes.array,
    handleShowDiffsInViewer: PropTypes.func,
    structureToCompare: PropTypes.object,
    steeringInfo: PropTypes.array,
    withAutosave: PropTypes.bool,
    handleAutoSaveData: PropTypes.func,
};

export default DocumentBuilder;
