import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import projectManagementAPIs from '../../services/project-management.service';

const getResponseIDsWithDocs = async (responseIdWithDocs) => {
    try {
        const response = await projectManagementAPIs
            .getAllDocumentsByResponseIDs(responseIdWithDocs);
        if (response.status === 200) {
            return response.data;
        } else {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ToastConstants.unableToRetrieveInfo,
            });
        }
    } catch (error) {
        Toast.fire({
            icon: ToastConstants.error,
            titleText: ToastConstants.unableToRetrieveInfo,
        });
    }
};

const getResponseDocuments = (response) => response.supportingAnswerDocuments.map((doc) => doc);

const getLotsResponseDocuments = (response, docList) => {
    const list = { ...docList };
    const lotsResponseDocs = {};
    response.lots
        .forEach((lot) => {
            if (list.withLots[lot.lotTitle]) {
                lotsResponseDocs[lot.lotTitle] = [
                    ...list.withLots[lot.lotTitle],
                    ...getResponseDocuments(response),
                ];
                lotsResponseDocs[lot.lotTitle] = [...new Set(lotsResponseDocs[lot.lotTitle])];
            } else {
                lotsResponseDocs[lot.lotTitle] = getResponseDocuments(response);
            }
        });
    return lotsResponseDocs;
};

const getDocListStructure = (response, docList) => {
    const docListClone = { ...docList };
    if (response.lots.length) {
        const lotsResponseDocs = getLotsResponseDocuments(response, docListClone);

        docListClone.withLots = {
            ...docListClone.withLots,
            ...lotsResponseDocs,
        };
    } else {
        const docs = getResponseDocuments(response);
        docListClone.noLots = [
            ...docListClone.noLots,
            ...docs,
        ];
    }
    return { ...docListClone };
};

const getDocumentsIds = async (flattenedResponses) => {
    let newDocList = {
        withLots: {},
        noLots: [],
    };

    const responseIDs = flattenedResponses.map((response) => response.responseID);
    if (!responseIDs || responseIDs.length === 0) {
        return newDocList;
    }
    const responseIDWithDocsList = await getResponseIDsWithDocs(responseIDs);
    const responsesWithDocs = responseIDWithDocsList?.map((responseIDWithDocs) => {
        const flatReponse = flattenedResponses
            .find((resp) => responseIDWithDocs.responseID === resp.responseID);
        return { ...flatReponse, ...responseIDWithDocs };
    });

    newDocList = responsesWithDocs?.reduce((acc, response) => {
        const list = getDocListStructure(response, acc);
        return {
            ...acc,
            ...list,
        };
    }, newDocList);

    return newDocList;
};

export default getDocumentsIds;