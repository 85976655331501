import React from 'react';
import { useLocation } from 'react-router-dom';
import CreateProcurementForm from '../../features/CreateProcurementForm';

const CallOff = () => {
    const location = useLocation();
    return <section id='cPPSection'>
        <p className='title-xLarge screenTitleHeading' id='sectionTitle'>Enter Call-off Project Details</p>
        <CreateProcurementForm
            type={'calloff'}
            lotID={location?.state?.callOffForLotId}
            awardedSuppliers={location?.state?.awardedSuppliers}
            callOffMechanism={location?.state?.callOffMechanism}
            competitionRules={location?.state?.competitionRules}

            solutionAccountId={location?.state?.solutionAccountId}
            loggedUserStatusInSolution={location?.state?.loggedUserStatusInSolution}
        />
    </section>;
};

export default CallOff;
