import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseISO, isPast } from 'date-fns';
import BrowseData from '../../BrowseData';
import {
    browseActivitiesId,
    browseDataContext,
    browseDataContextSingular,
    searchPlaceholder,
    ToastConstants,
    activityLogPostDeadline,
    sortOptions,
    DESC,
} from './constants';
import { workflowEvents, resourceTypes } from '../../../config/constants';
import notificationsManagementAPIs from '../../../services/notifications.service';
import Toast from '../../../components/Alerts/Toast/Toast';
import projectManagementAPIs from '../../../services/project-management.service';


const BrowseActivity = () => {
    const { resourceId, resourceType } = useParams();
    const [activityLog, setActivityLog] = useState([]);

    const getPublishedEvents = (workflow) => {
        const allEvents = workflow?.stages
            ?.reduce((acc, stage) => acc.concat(stage.events), []);
        return allEvents?.filter(
            (event) => event.eventType === workflowEvents.publish && event.isPublished === true,
        );
    };

    const matchesWithActivityLogPreDeadline = (message) => {
        const findObject = Object.values(activityLogPostDeadline)
            .find((regExp) => regExp.test(message));
        return !findObject;
    };

    const eventIsActive = (event) => event && isPast(parseISO(event.submissionStartDate))
    && !isPast(parseISO(event.submissionEndDate));

    const filterEvents = (activityLogs, publishEvents) => {
        const activityLogList = activityLogs.filter((log) => {
            const relatedEvent = publishEvents
                ?.find((event) => log?.systemTimestamp < event?.submissionEndDate);
            if (eventIsActive(relatedEvent)) {
                return matchesWithActivityLogPreDeadline(log.message);
            }
            return true;
        });
        return activityLogList;
    };

    const getWorkflow = async () => {
        let response;
        if (resourceType === resourceTypes.project) {
            response = projectManagementAPIs.getWorkflowByProjectID(resourceId);
        } if (resourceType === resourceTypes.contract) {
            response = projectManagementAPIs.getWorkflowByContractID(resourceId);
        }
        return response || undefined;
    };

    const fetchActivityLog = async () => {
        try {
            const workflow = await getWorkflow();
            const response = await notificationsManagementAPIs
                .getActivityLogsByResourceID(resourceId, DESC);
            if (response.status === 200) {
                const publishEvents = getPublishedEvents(workflow?.data);
                const activityLogs = response.data.items;
                setActivityLog(filterEvents(activityLogs, publishEvents));
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.icon,
                titleText: ToastConstants.titleText,
            });
        }
    };

    useEffect(() => {
        fetchActivityLog();
    }, [resourceId, resourceType]);

    return <BrowseData
        id={browseActivitiesId}
        context={browseDataContext}
        contextSingular={browseDataContextSingular}
        dataSet={activityLog}
        searchPlaceholder={searchPlaceholder}
        searchFilterAttribute={''}
        sortOptions={sortOptions}
    />;
};

export default BrowseActivity;
