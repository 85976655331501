import React from 'react';
import PropTypes from 'prop-types';

const ScreenOverlay = ({ handleClick, isStackTop = false, classes = '' }) => <div className={ `screen-overlay${isStackTop ? ' stack-top' : ''} ${classes}` } onClick={handleClick}></div>;

ScreenOverlay.propTypes = {
    handleClick: PropTypes.func,
    isStackTop: PropTypes.bool,
    classes: PropTypes.string,
};

export default ScreenOverlay;
