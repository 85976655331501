const notifications = {
    readAllByRecipient: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/read/all`,
    readAllByNotificationID: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/read`,
    getMessages: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/all`,
    getProjectMessages: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/getAll/project`,
    getSupplierMessages: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/get/supplier`,
    createMessage: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/create`,
    contactUs: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/notifications/contactus`,
    getActivityLogsByResourceID: `${process.env.MS_NOTIFICATION_HOST || process.env.MS_GATEWAY_HOST}/activityLog/all/resource`,
};

export default notifications;
